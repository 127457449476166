import * as ol from 'ol';
import { MVT } from "ol/format";
import { VectorTile as ol_layer_VectorTile } from 'ol/layer';
import { take } from "rxjs";
import { AuthenticationService } from "Services/AuthenticationService";

export abstract class AuthenticatedVectorTileLayerBase {
    private _VectorLayer: ol_layer_VectorTile;
    public get Layer(): ol_layer_VectorTile {
        return this._VectorLayer;
    }

    constructor(protected Map: ol.Map, tileURL: string, protected AuthenticationService: AuthenticationService) {
        this._VectorLayer = this.CreateLayer(tileURL);
        Map.addLayer(this._VectorLayer);
    }

    protected abstract CreateLayer(tileURL: string): ol_layer_VectorTile;

    /**
     * Override this to specify the post data content when sending a tile request.
     */
    protected GetRequestBody(): Document | XMLHttpRequestBodyInit | null {
        return null;
    }

    protected OnTileReceived(features: ol.Feature<any>[]): void {
    }

    protected AuthenticatedTileLoadFile(tile: ol.VectorTile, url: string): void {
        const me = this;

        //  https://stackoverflow.com/questions/50471595/openlayers-4-load-wms-image-layer-require-authentication/50477911

        //  This changed in OpenLayers 6.  See these links:
        //      main issue: https://github.com/openlayers/openlayers/issues/9293
        //      code fix issue: https://github.com/openlayers/openlayers/pull/9308
        //      merge: https://github.com/openlayers/openlayers/pull/9308/commits/b2722542feef255d4718c60be72dbe4535b73e63
        tile.setLoader(function (extent, resolution, projection) {
            me.AuthenticationService.getAuthorizationHeaderValueObservable().pipe(take(1))
                .subscribe(authVal => {
                    //  Using XMLHttpRequest here instead of the Angular HttpClient because the response is binary.
                    //  To make that work in Angular, need to set "responseType: 'arraybuffer'" option on the
                    //  http.get call (in the options param).  But that shows as an error even though it works
                    //  and the intellisense shows it's correct!
                    const xhr = new XMLHttpRequest();
                    xhr.open('POST', url);
                    xhr.withCredentials = true;     //  This is needed to send our cookie - not doing this will cause the api to look up our session info on EVERY call!
                    xhr.setRequestHeader("Authorization", authVal);
                    xhr.responseType = 'arraybuffer';       //  This is key - does not work without this!
                    xhr.onload = function () {
                        const data = xhr.response as ArrayBuffer;
                        if ((xhr.status === 200) && data && (data.byteLength > 0)) {
                            const format = tile.getFormat() as MVT;
                            const features = format.readFeatures(data, {
                                extent: extent,
                                featureProjection: projection
                            }) as ol.Feature<any>[];

                            tile.setFeatures(features);
                            me.OnTileReceived(features);
                        } else {
                            //  Must always call setFeatures or the tile seems to get stuck in a pending state and the map stops refreshing!
                            //  Was happening on the ticket dashboard map if you zoom in/out a few times.
                            tile.setFeatures([]);
                        }
                    }
                    xhr.send(me.GetRequestBody());
                });
        });
    }
}
