import { EntityBase } from "@iqModels/Base/EntityBase.model";
import { Address } from "@iqModels/Common/Address.model";
import { Email } from "@iqModels/Common/Email.model";
import { Phone } from "@iqModels/Common/Phone.model";
import { PersonDestination } from "@iqModels/People/PersonDestination.model";
import { PersonMember } from "@iqModels/People/PersonMember.model";
import { PersonServiceArea } from "@iqModels/People/PersonServiceArea.model";
import { PersonServiceProvider } from "@iqModels/People/PersonServiceProvider.model";
import { SearchResponse } from "@iqModels/Searching/SearchResponse.model";
import { ExcavatorContact } from "@iqModels/Excavators/ExcavatorContact.model";
import { PersonToRole } from "./PersonToRole.model";
import { PersonLogin } from "./PersonLogin.model";
import { BrowserInfo } from "Models/Misc/BrowserInfo.model";

export class PersonContactOf {
    public ExcavatorContact: ExcavatorContact;
    public Destinations: PersonDestination[];
    public Members: PersonMember[];
    public ServiceAreas: PersonServiceArea[];
    public ServiceProviders: PersonServiceProvider[];
}

export class PersonListMore {
    public Members: SearchResponse;
    public Destinations: SearchResponse;
    public ServiceAreas: SearchResponse;
    public ServiceProviders: SearchResponse;
    public ExcavatorContact: ExcavatorContact;
}

export class Person extends EntityBase {
    public FirstName: string;
    public LastName: string;
    public LastLoginDate: Date;

    public LastBrowserInfo: BrowserInfo;

    public TermsAndConditionsAcceptedDate: Date;

    /**
     * Prevent the person from being able to login
     */
    public LoginDisabled: boolean;

    /**
     * Shortcut to know if the person has any logins
     */
    public HasLogin: boolean;

    /**
     *  Username(s) for Exactix login provider.  Comma separated if there are multiple.
     */
    public Login: string;

    /**
     *  Comma separated list of role names.
     */
    public RoleNames: string;

    public Logins: PersonLogin[];

    public Addresses: Address[];
    public Emails: Email[];
    public Phones: Phone[];

    public Roles: PersonToRole[];

    public ContactOf: PersonContactOf;

    public ListMoreData: PersonListMore;

    constructor(public ID: string = null) {
        super(ID);
    }
}

export class PersonInsertRequest extends EntityBase {
    public OneCallCenterCode: string;

    public FirstName: string;
    public LastName: string;
    public EmailAddress: string;
    public PhoneNumber: string;

    public Address: Address;

    public ExcavatorCompanyID: string;
    public ExcavatorOfficeID: string;

    constructor(public ID: string = null) {
        super(ID);
    }
}
