import { Component, Output, EventEmitter } from '@angular/core';
import { SearchFilter, SearchFilterValue } from '@iqModels/Searching/SearchFilter.model';
import { EntityEnum } from 'Enums/EntityType.enum';
import { MessageStatusEnum } from 'Enums/MessageStatus.enum';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { ReceiverTypeEnum } from 'Enums/ReceiverType.enum';
import { SearchOrderBy } from 'Models/Searching/SearchOrderBy.model';

@Component({
    selector: 'iq-manual-callout-list',
    templateUrl: './ManualCalloutList.component.html',
    styleUrls: ['./ManualCalloutList.component.scss']
})
export class ManualCalloutListComponent {
    @Output() ResultCountChange: EventEmitter<number> = new EventEmitter();

    StoreFilterKeyValue = EntityEnum.Communication.toString() + EntityEnum.Ticket.toString();

    public SearchFilter = [
        new SearchFilter("SendToReceiverType", SearchFilterOperatorEnum.Contains, [new SearchFilterValue(ReceiverTypeEnum.ManualCall, "Manual Call")]),
        new SearchFilter("Status", SearchFilterOperatorEnum.Contains, [new SearchFilterValue(MessageStatusEnum.Queued, "Queued")])
    ];

    public SearchColumns = [
        "ServiceAreaCodeAndName",
        "Ticket.TicketNumber",
        "Ticket.TicketType.Name",
        "NumAttempts",
        "Queue.NextAttemptDate",
        "ManualCallLockedByPersonName",
    ];

    public SearchOrderBy = [new SearchOrderBy("Queue.NextAttemptDate", false)];

    constructor() { }

    resultCountChanged(val) {
        this.ResultCountChange.emit(val);
    }
}
