import { ExcavatorCompanyType } from "Models/Configuration/ExcavatorCompanyType.model";
import { IndustryType } from "Models/Configuration/IndustryType.model";
import { IActive } from "Models/Interfaces/IActive.interface";
import { IDisplayEntity } from "Models/Interfaces/IDisplayEntity.interface";
import { Address } from "@iqModels/Common/Address.model";
import { Email } from "@iqModels/Common/Email.model";
import { Phone } from "@iqModels/Common/Phone.model";
import { EntityBase } from "@iqModels/Base/EntityBase.model";
import { ExcavatorOffice } from "@iqModels/Excavators/ExcavatorOffice.model";
import { ExcavatorContactWithOfficeNames } from "@iqModels/Excavators/ExcavatorContactWithOfficeNames.model";

export class ExcavatorCompany extends EntityBase implements IDisplayEntity, IActive {

    CompanyID: number;
    
    IsActive: boolean;

    CompanyTypeID: string;
    CompanyType: ExcavatorCompanyType;

    IndustryTypeID?: string;
    IndustryType?: IndustryType;

    ExcavatorOffices: ExcavatorOffice[];

    //  This contains a distinct list of contacts along with a comma separated list of Office Names.
    //  This is necessary in case there are lots of offices and contacts are connected to lots of them (i.e. SC).
    //  If we returned the regular ExcavatorContactToExcavatorOffice, the list could potentially be MASSIVE and cause
    //  the browser to lock up.  Before cleaning up the SC data, there was one with over 2,000,000 permutations
    //  while changing to this list reduced it to 500 or so!
    ExcavatorContacts: ExcavatorContactWithOfficeNames[];

    Addresses: Address[];
    Emails: Email[];
    Phones: Phone[];

    constructor(public ID: string = null, public Name: string = null) {
        super(ID);
        this.IsActive = true;
    }
}
