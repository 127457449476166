import { Directive, Input, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { TicketEntryFormGroup } from 'Pages/Tickets/Details/Components/InputControls/TicketEntryFormGroup';
import { TicketEntryPageEventService } from 'Pages/Tickets/Services/TicketEntryPageEvent.service';
import { TicketEntrySectionEventService } from 'Pages/Tickets/Services/TicketEntrySectionEvent.service';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from 'Services/CommonService';
import { EntrySectionBase } from "Shared/EntryFields/Forms/EntrySectionBase.component";
import { TicketSidePanelEnum } from '../Components/SideContent/TicketSidePanel.enum';

@Directive()
export abstract class TicketEntrySectionBase extends EntrySectionBase<TicketEntryFormGroup, TicketEntryPageEventService, TicketEntrySectionEventService> implements OnDestroy {

    //  TODO: change all references to the base class property: EntryFormGroup
    @Input()
    get TicketEntryForm(): TicketEntryFormGroup { return this.EntryFormGroup; }
    set TicketEntryForm(val: TicketEntryFormGroup) { this.EntryFormGroup = val; }

    //  TODO: Change all references to the base class property: IsEditing
    public get EditingTicket(): BehaviorSubject<boolean> { return this.IsEditing; }

    //  For this QueryList to work against a base class, need to add a line like this:
    //    { provide: TicketEntrySectionBase, useExisting: forwardRef(() => FL811_WorkInformationSectionComponent) }
    //  to the "Providers" section of each derived class.
    @ViewChildren(TicketEntrySectionBase)
    protected ChildEntrySections: QueryList<TicketEntrySectionBase>;

    constructor(commonService: CommonService, sectionEventService: TicketEntrySectionEventService) {
        super(commonService, sectionEventService);
    }

    public ngOnDestroy(): void {
        this.ChildEntrySections = null;
        super.ngOnDestroy();
    }

    //  TODO: Change all references to the base class method: OnEntryFormGroupSet()
    //  Derived classes can override this to detect when the TicketEntryForm has been reset.
    //  Needed if the view does not get re-created (which, for some reason, happens to the side panel).
    protected OnTicketEntryFormSet(): void {
    }

    //  Derived classes can override this to detect when the TicketEntryForm has been reset.
    //  Needed if the view does not get re-created (which, for some reason, happens to the side panel).
    protected OnEntryFormGroupSet(): void {
        this.OnTicketEntryFormSet();
    }

    public FocusFirstControlByName(propertyNames: string[]): boolean {
        if (propertyNames && (propertyNames.length > 0) && propertyNames[0].startsWith("Attachments.")) {
            this.SectionEventService.PageEventService.SidePanel.next(TicketSidePanelEnum.Attachments);
            return true;
        }

        return super.FocusFirstControlByName(propertyNames);
    }

    //  TODO: If we want this in the base, will need to also put the PageEventService in EntrySectionEventBase.
    //  This handler is called in response to the (FocusChanged) event on the iq-section-group - which is set in the html
    public OnSectionGroupFocusChanged(isFocused: boolean): void {
        if (isFocused)
            this.SectionEventService.PageEventService.ShowHelpForSectionCode.next(this.SectionCode);
    }

    /**
     * Call this to show the disclaimer for the current ticket type.  This can be used in edit or view mode.
     */
    public ShowTicketTypeDisclaimer(): void {
        this.SectionEventService.PageEventService.ShowTicketTypeDisclaimer();
    }

    public ShowMap(onlyIfDesktop: boolean = false): void {
        if (onlyIfDesktop && !this.CommonService.DeviceDetectorService.IsDesktop)
            return;

        this.SectionEventService.PageEventService.SidePanel.next(TicketSidePanelEnum.Map);

        //  If showing the ticket entry page as a single panel (i.e. Tablet-portrait) or phone, trigger the event to show the
        //  side content panel.
        if (this.SectionEventService.PageEventService.ShowSinglePanel)
            this.SectionEventService.PageEventService.ShowSideContentPanel.next(true);
    }

    public ShowHelp(onlyIfDesktop: boolean = false): void {
        if (onlyIfDesktop && !this.CommonService.DeviceDetectorService.IsDesktop)
            return;

        this.SectionEventService.PageEventService.ShowHelpForSectionCode.next(this.SectionCode);
        this.SectionEventService.PageEventService.SidePanel.next(TicketSidePanelEnum.Help);

        //  If showing the ticket entry page as a single panel (i.e. Tablet-portrait), trigger the event to show the
        //  side content panel.
        if (this.SectionEventService.PageEventService.ShowSinglePanel)
            this.SectionEventService.PageEventService.ShowSideContentPanel.next(true);
    }

    public ShowSubcontractors(): void {
        this.SectionEventService.PageEventService.SidePanel.next(TicketSidePanelEnum.Subcontractor);
    }
}
