import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DigSiteRuleActionEnum } from 'Enums/DigSiteRules/DigSiteRuleAction.enum';
import { DigSiteRuleValidationErrorResponse } from 'Models/Errors/DigSiteRuleValidationErrorResponse.model';
import { MapRouteSegment } from "Models/Maps/MapRouteSegment.model";
import { SplitGeocodeItemResponse } from "Models/Maps/SplitGeocodeItemResponse.model";
import { VerifyTicketBeforeSaveResponse } from 'Models/Tickets/VerifyTicketBeforeSaveResponse.model';
import { TicketEntrySectionEventService } from 'Pages/Tickets/Services/TicketEntrySectionEvent.service';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { CommonService } from 'Services/CommonService';
import { ComponentLookupRegistry } from 'Services/ComponentLookup.service';
import { InformationDialogComponent } from 'Shared/Components/Controls/Dialog/Information/InformationDialog.component';
import { DialogModel } from 'Shared/Components/Controls/Dialog/Models/Dialog.model';
import { DynamicContentDirective } from 'Shared/Directives/DynamicContent/DynamicContent.directive';
import { DirectionEnum, DirectionEnumDescription } from 'Enums/Direction.enum';
import { DigSite } from 'Models/DigSites/DigSite.model';
import { TicketEntryFormGroup } from "../InputControls/TicketEntryFormGroup";
import { GeocodeService } from 'Services/GeocodeService';

export interface ICustomCreateMultipleTicketsContentComponent {
    Init(ticketEntryFormGroup: TicketEntryFormGroup): void;
    IsValid(): boolean;
    SaveFormValues(totalLengthFt: number): void;
}

export class CreateMultipleTicketsData {
    constructor(
        public TicketEntryForm: TicketEntryFormGroup,
        public VerifyInfo: VerifyTicketBeforeSaveResponse,
        public DigSiteRule: DigSiteRuleValidationErrorResponse) {
    }
}

@Component({
    selector: 'iq-create-multiple-tickets',
    templateUrl: './CreateMultipleTickets.component.html',
    styleUrls: ['./CreateMultipleTickets.component.scss'],
    providers: [
        CommonService,
        TicketEntrySectionEventService.Provider,
    ]
})
export class CreateMultipleTicketsComponent implements OnInit {
    @Input()
    public Data: CreateMultipleTicketsData;

    @Output()
    public RouteListEvent = new EventEmitter<MapRouteSegment[]>();

    @ViewChild(DynamicContentDirective, { static: true })
    private _CustomContent: DynamicContentDirective;

    private _CustomComponent: ICustomCreateMultipleTicketsContentComponent;

    public TotalLengthFt: number;
    public Segments: SplitGeocodeItemResponse[] = [];

    public DirectionEnum = DirectionEnum;
    public DirectionEnumDescription = DirectionEnumDescription;

    public IsBusy: boolean = false;       //  set when re-calling VerifyTicketBeforeSave

    constructor(public CommonService: CommonService, private _TicketService: TicketService, private _GeocodeService: GeocodeService,) {
    }

    public ngOnInit(): void {
        const digsite = (this.Data.TicketEntryForm.get("DigSite") as UntypedFormGroup).getRawValue() as DigSite;
        const ticketTypeID = (this.Data.TicketEntryForm.get("TicketTypeID") as UntypedFormControl).value as string;
        const lengthFt = this.Data.DigSiteRule.MinLengthFt ?? 1320;
        this._GeocodeService.SplitDigSite(lengthFt, digsite, ticketTypeID).subscribe(response => this.OnSplitDigSiteResponseReceived(response));

        //  Check for a custom component that should be created and injected into the form
        const componentKey = this.CommonService.SettingsService.CurrentOneCallCenterCode + "-Create-Multiple-Tickets-Content";
        const customComponentClassRef = ComponentLookupRegistry.get(componentKey);
        if (customComponentClassRef) {
            this._CustomComponent = this._CustomContent.LoadComponent<ICustomCreateMultipleTicketsContentComponent>(customComponentClassRef);
            this._CustomComponent.Init(this.Data.TicketEntryForm);
        }
    }

    public CanCreateMultipleTickets(): boolean {
        return !this.IsBusy && this._CustomComponent?.IsValid();
    }

    private OnSplitDigSiteResponseReceived(response: SplitGeocodeItemResponse[]): void {
        this.Segments = response ?? [];
        this.TotalLengthFt = this.Segments.map(s => s.LengthFt).reduce((prev, curr) => prev + curr, 0);

        const routeList = this.Segments.map(s => new MapRouteSegment(s.Number, "#" + s.Number.toString(), s.UnbufferedGeometryJson));
        this.RouteListEvent.emit(routeList);

        const numSegmentsWithNoServiceAreas = this.Segments.filter(s => !s.NumAffectedServiceAreas);
        if (numSegmentsWithNoServiceAreas.length > 0) {
            //  Must show using a timeout or the dialog does not display (probably conflicts with the main dialog or displays under it).
            setTimeout(() => this.ShowNoServiceAreasWarning(numSegmentsWithNoServiceAreas.length), 200);
        }
    }

    private ShowNoServiceAreasWarning(numSegmentsWithNoServiceAreas: number): void {
        let msg = String(numSegmentsWithNoServiceAreas) + " segment";
        if (numSegmentsWithNoServiceAreas === 1)
            msg += " of this group does";
        else
            msg += "s of this group do";
        msg += " not include any member service areas.  This will cause " + String(numSegmentsWithNoServiceAreas) + " notice";
        if (numSegmentsWithNoServiceAreas > 1)
            msg += "s";
        msg += " to be created with no member service areas.";
        msg += "<p><span style='color:red'>Please verify the work area, if needed.</span>";

        this.CommonService.Dialog.open(InformationDialogComponent, {
            panelClass: 'iq-warn',
            data: new DialogModel("Segments do not affect Service Areas", msg),
            width: '50%'
        });
    }

    public OnSaveMultipleTickets(): void {
        this._CustomComponent.SaveFormValues(this.TotalLengthFt);
        this._TicketService.Ticket.value.CreateMultipleTicketsForSegments = this.Segments;
        this.IsBusy = true;
    }

    public IgnoreDigsiteRule(digsiteRule: DigSiteRuleValidationErrorResponse): boolean {
        return ((digsiteRule.DigSiteRuleAction === DigSiteRuleActionEnum.ShowError) || (digsiteRule.DigSiteRuleAction === DigSiteRuleActionEnum.ShowMessage))
            && digsiteRule.HasLengthRuleType;
    }
}
