<busy-indicator [busyKey]="busyKey"></busy-indicator>
<div *ngIf="apiError">
    <div class="paged-list-api-error">
        Error fetching list from server.
    </div>
</div>
<iq-view-list>
    <iq-list-filter-section>
        <div class="search-header">
            <div class="name-search-options">
                <mat-icon>search</mat-icon>
                <mat-form-field class="name-search-text" style="width: 200px;">
                    <mat-label>Filter by Ticket Number</mat-label>
                    <input matInput [(ngModel)]="filter.filterString" (ngModelChange)="applyFilter($event)" autocomplete="off" type="search">
                    <mat-hint *ngIf="minCharsDefaultSearch && minCharsDefaultSearch > 0 && !disableFilters">Min {{minCharsDefaultSearch}} characters required</mat-hint>
                    <mat-hint *ngIf="disableFilters">*All other filters are disabled</mat-hint>
                </mat-form-field>
            </div>
            <label *ngIf="viewAllRelated" class="link" (keydown.enter)="removeRelatedSearch()" (keydown.space)="removeRelatedSearch()" tabindex="0" (click)="removeRelatedSearch()">Viewing related tickets.  Go back to the last filter</label>
            <label *ngIf="!viewAllRelated" class="link" (click)="ClearSortAndFilters()">reset</label>
            <div *ngIf="LimitToTicketsWithinXFtOfCurrentLocation" class="gray-primary-text" style="font-style:italic;font-size:smaller; padding-left:2em">* Limiting to Tickets within {{LimitToTicketsWithinXFtOfCurrentLocation}} feet of current location</div>
            <div class="paging-controls">
                <div>
                    <pagination-controls [id]="PaginatorID" (pageChange)="pageChanged($event)"></pagination-controls>
                    <div *ngIf="resultsLength > MaxRowsForPaging" class="paging-limited iq-caption gray-primary-text">* paging limited to {{MaxRowsForPaging | number}} results</div>
                </div>
                <mat-form-field floatLabel="always" class="num-per-page-selector">
                    <mat-label>Items per Page</mat-label>
                    <mat-select [(ngModel)]="defaultPageSize" (selectionChange)="pageSizeChanged()">
                        <mat-option *ngFor="let item of pageSizeOptions" [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </iq-list-filter-section>
    <iq-list-action-section>
        <!--<mat-checkbox color="primary" [indeterminate]="!allSelected && selectedItems.length!==0" (change)="allSelectedChange($event)" [checked]="allSelected" style="padding-right: 5px;"></mat-checkbox>-->
        <!--<label class="selected-label">{{selectedCount}} Selected</label>-->

        <ng-container *ngIf="ShowColumnSelector">
            <iq-column-header-chooser [availableColumns]="AvailableColumns" [EntityType]="EntityType" [AllowSaving]="true" (change)="columnChange($event)"></iq-column-header-chooser>
        </ng-container>

        <ng-container *ngIf="ShowFilterSelector">
            <iq-list-filter-builder [availableColumns]="AvailableFilterColumns" [requiredColumnPropertyNames]="requiredFilters"
                                    [EntityType]="EntityType" [AllowSaving]="true" [disableAllFilters]="disableFilters"
                                    [NewDefaultFilter]="NewDefaultFilter" (change)="filterChange($event)"></iq-list-filter-builder>
        </ng-container>

        <button mat-icon-button matTooltip="Export to csv" (click)="exportAsCsv()">
            <fa-icon [icon]="exportImage"></fa-icon>
        </button>

        <button class="iq-display-2" mat-icon-button matTooltip="Refresh" (click)="refreshSearch(true)">
            <fa-icon [icon]="refresh"></fa-icon>
        </button>

        <ng-container *ngIf="AllowMultipleActionsOnTicketList">
            <button mat-icon-button matTooltip="Mark selected tickets as Work Complete" *ngIf="ShowMarkWorkComplete" (click)="MarkTicketsComplete(true)">
                <fa-icon [icon]="GetIcon('WorkComplete')" style="color: green;"></fa-icon>
            </button>
            <button mat-icon-button matTooltip="Mark selected tickets as Work NOT Complete" *ngIf="ShowMarkWorkNotComplete" (click)="MarkTicketsComplete(false)">
                <fa-icon [icon]="GetIcon('WorkNotComplete')" style="color: red;"></fa-icon>
            </button>
            <button mat-icon-button matTooltip="Add Response" *ngIf="ShowAddResponse" (click)="AddPositiveResponse(selectedItems)">
                <fa-icon [icon]="GetIcon('note_add')"></fa-icon>
            </button>
        </ng-container>

        <label class="results-text">Results: {{resultsLength | number}}</label>
    </iq-list-action-section>

    <div class="iq-list-items" #iqListItems>
        <div class="iq-list-header" style="padding-right: 10px;" [style.padding-left]="AllowMultipleActionsOnTicketList ? '40px' : '94px'">
            <mat-checkbox *ngIf="AllowMultipleActionsOnTicketList"
                          (change)="ToggleSelectAll($event.checked)"
                          [checked]="selectAllChecked"
                          style="padding-right: 60px;"></mat-checkbox>
            <ng-container *ngFor="let col of DisplayedColumns">
                <ng-container *ngIf="col.canSearchAndFilter; else normalHeaderTemplate">
                    <iq-column-header class="link column-fixed {{col.class}}" [Title]="col.name" [style.max-width]="col.width" style="flex: 1 1 0%;"
                                      [ClearSortAndFilters]="_clearSortAndFilter" [StoredFiltersKey]="StoredFiltersKey" [isDisabled]="disableFilters"
                                      [SearchColumn]="col" (change)="ColumnSortFilterChange(col, $event)"
                                      [requiredColumnPropertyNames]="requiredFilters">
                        {{col.name}}
                    </iq-column-header>
                </ng-container>
                <ng-template #normalHeaderTemplate>
                    <label class="link column-fixed {{col.class}}" [style.max-width]="col.width" style="flex: 1 1 0%;">
                        {{col.name}}
                    </label>
                </ng-template>
            </ng-container>
        </div>

        <!-- this was used to color the entire row -->
        <!-- [ngStyle]="{'background-color': item.TicketType_BackgroundColor, 'color': item.TicketType_FontColor}" -->
        <iq-list-item *ngFor="let item of items | async | paginate: { itemsPerPage: defaultPageSize,
                                                      currentPage: currentPage, id: PaginatorID,
                                                      totalItems: MaxRowsForPaging }; trackBy: TrackBy">
            <div style="display:flex; box-sizing:border-box; align-items:center; column-gap: 5px" class="iq-display-2">
                <button mat-icon-button [matMenuTriggerFor]="filter" (menuOpened)="MenuOpened(item, filter)" style="padding:0px; width:24px; display:flex; align-items:center" class="iq-display-2" [iqFocus]="focusActionMenuEvents.Item(item.ID)">
                    <mat-icon>more_vert</mat-icon>
                </button>

                <mat-checkbox *ngIf="AllowMultipleActionsOnTicketList" (change)="ToggleSelected($event.checked, item)" [checked]="item.Selected"></mat-checkbox>

                <div style="min-width: 25px; display:flex; align-items:center">
                    <mat-icon *ngIf="item.IsLast === 'No'" matTooltip="Ticket has been updated to a new Ticket Number or Version" class="red" style="transform: rotate(90deg); font-weight: 900;position: relative;">chevron_left</mat-icon>
                    <mat-icon *ngIf="item.IsLast === 'Yes' && item.ParentTicketID" matTooltip="Ticket is the most recent version" class="green" style="transform: rotate(90deg); font-weight: 900;position: relative;">first_page</mat-icon>
                </div>

                <div style="height: 40px; min-width: 25px;" matTooltip="{{item.TicketType_Name}}">
                    <div style="width:25px; height:100%" [ngStyle]="{'background-color': item.TicketType_BackgroundColor }"></div>
                </div>
            </div>
            <mat-menu #filter="matMenu">
                <ng-template matMenuContent>
                    <div style="display:flex; flex-direction:column; box-sizing:border-box">
                        <ng-container *ngFor="let action of ActionList">
                            <button *ngIf="action" matMenuItem mat-button (click)="ActionClicked(action, item)" [disabled]="action.Disabled">
                                <span style="display:flex; flex-direction:row; box-sizing:border-box; align-items:center;">
                                    <fa-icon *ngIf="action.iconName" [icon]="GetIcon(action.iconName)" [style.color]="action.Color" style="padding-right: 10px;"></fa-icon>
                                    <span [style.color]="action.Color" [style.margin-left]="action.iconName ? null : '26px'">{{action.Name}}</span>
                                </span>
                            </button>
                            <div *ngIf="!action" class="hr"></div>
                        </ng-container>
                    </div>
                </ng-template>
            </mat-menu>
            <div class="no-focus-outline" style="flex:1 0 0">
                <a style="display:flex; flex-grow:1; align-items:center; color:initial; text-decoration:none;" routerLink="/tickets/view/{{item.ID}}">
                    <ng-container *ngFor="let col of DisplayedColumns" [ngSwitch]="col.returnPropertyName">
                        <label *ngSwitchCase="'TicketNumber'" class="column-fixed {{col.class}}" [style.max-width]="col.width" style="flex: 1 1 0%;">
                            <ng-container *ngIf="item.HideTicketNumber === 'Yes'; else elseTicketNumber">
                                N/A
                            </ng-container>
                            <ng-template #elseTicketNumber>
                                {{item[col.returnPropertyName] | dynamicFormat : col.formatType : col.format}}
                            </ng-template>
                        </label>
                        <label *ngSwitchDefault class="column-fixed {{col.class}} {{col.DynamicCssClass(item[col.returnPropertyName], item)}}" [style.max-width]="col.width" style="flex: 1 1 0%;">
                            {{item[col.returnPropertyName] | dynamicFormat : col.formatType : col.format}}
                        </label>
                    </ng-container>
                </a>
            </div>
        </iq-list-item>
    </div>
</iq-view-list>
