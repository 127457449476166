import { TypeDecorator } from "@angular/core";

//  This came from here: https://stackoverflow.com/a/60114267
//  ** As of Angular 9, the components may be excluded from the production optimized build by the
//     tree shaking process.  The fix I found was to just add the components to the providers section
//     of the module where they are defined.
//     https://github.com/angular/angular-cli/issues/16246

//  To register a component: add this right before it's @Component() definition
//      @ComponentLookup('TestDialogComponent')
//  * Also add the component to the providers section of the module where it is defined.
//  To get the type, just call:
//      const classRef = ComponentLookupRegistry.get('TestDialogComponent');
//  Now can use classRef to open a dialog like this:
//      this._Dialog.open(classRef, { data: { item: 1 } })

export const ComponentLookupRegistry: Map<string, any> = new Map();

//  Needed to avoid an error (only in prod build) like: Function expressions are not supported in decorators in 'ComponentLookup'
//  https://github.com/angular/angular/issues/33539
export function setComponent(key: string): TypeDecorator {
    return (cls) => {
        ComponentLookupRegistry.set(key, cls);
    }
}

export const ComponentLookup = setComponent;

//  This is the same thing (that causes the prod build error) but without the exported function
//export const ComponentLookup = (key: string): TypeDecorator => {
//    return (cls) => {
//        ComponentLookupRegistry.set(key, cls);
//    };
//};
