import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { DeliveryRuleAuditFormatEnum } from 'Enums/DeliveryRules/DeliveryRuleAuditFormat.enum';
import { MapSearchTRSQGridTypeEnum } from 'Enums/MapSearchTRSQGridType.enum';
import { ServiceAreaResponseEntryTypeEnum } from 'Enums/ServiceAreaResponseEntryType.enum';
import { SelectOption } from 'Models/Configuration/SelectOption.model';
import { AppUser } from 'Models/Security/AppUser.model';
import { SiteInfoOptions } from "Models/Tickets/SiteInfoOptions.model";
import { environment } from '../../environments/environment';

//Get setting from the environment file, but put them in here so that it's easier to inject the dependency into our components etc.
//  Else if injecting the environments everywhere they may have different paths and that's bad for reusablity
@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    constructor() {
    }

    //  TODO: Need to overhaul and standardize the setttings.  See notes in OneCallCenterSettingTypeEnum.cs

    /**
     *  The base/origin URL of the SPA (including port if there is one) with no trailing /
     */
    public get UiBaseUrl(): string { return window.location.origin; }

    /**
     *  The base URL of the API.  If the environment setting is null/empty, this is computed
     *  from the UiBaseUrl as [UiBaseUrl]/api
     */
    public get ApiBaseUrl(): string {
        if (environment.apiBaseUrl)
            return environment.apiBaseUrl;

        return this.UiBaseUrl + "/api";
    }

    /**
     *  The base URL of GeoServer.  If the environment setting is null/empty, this is computed
     *  from the UiBaseUrl as [UiBaseUrl]/geoserver
     */
    public get GeoServerBaseUrl(): string {
        if (environment.geoServerBaseUrl)
            return environment.geoServerBaseUrl;

        return this.UiBaseUrl + "/geoserver";
    }

    public get DefaultColorPalette(): string {
        //  TODO: This needs to be configured in to the spa's appsettings.json file so that we can configure
        //  this in the docker/kubernetes setup.  But Matt may have changed how that works with the Cognito
        //  changes so waiting to do that until after we merge those changes.
        //  For now, just need NY to default to gray and leave everyone else alone.
        const origin = window.location.origin.toLowerCase();
        if ((origin.indexOf("digsafelynewyork") >= 0) || (origin.indexOf("udigny.org") >= 0))
            return "gray";

        return "green";
    }

    //  Set here (when the current user changes in AuthenticationService) so that we can use it to
    //  determine the settings below.  Which should probably be stored in the database and fetched from the server...
    //  Also, can't inject the AuthenticationService in here because it would cause a circular dependency error.
    public CurrentOneCallCenterCode: string;

    //  May want these settings configured per One Call and fetched from the server...
    public get DateTimeFormat(): string {
        switch (this.CurrentOneCallCenterCode) {
            default:
                return "MM/dd/yyyy hh:mm a";
        }
    }

    public get DateTimeWithSecondsFormat(): string {
        switch (this.CurrentOneCallCenterCode) {
            default:
                return "MM/dd/yyyy hh:mm:ss a";
        }
    }

    public get TimeFormat(): string {
        switch (this.CurrentOneCallCenterCode) {
            default:
                return "hh:mm a";
        }
    }

    public get UsesMilitaryTime(): boolean {
        switch (this.CurrentOneCallCenterCode) {
            default:
                return false;
        }
    }

    public get DateFormat(): string { return "MM/dd/yyyy"; }

    private _TicketEntryDisclaimerAccepted: boolean = false;

    public get TicketEntryDisclaimerAccepted(): boolean { return this._TicketEntryDisclaimerAccepted; }
    public set TicketEntryDisclaimerAccepted(accepted: boolean) {
        //  Only set to true if the One Call tracks the disclaimer acceptence once per session.  Otherwise,
        //  we don't need to remember it.  The TicketDetails tracks a flag inside itself to know if the
        //  user just clicked it.
        //  But always allow it to be set to false - this will allow it to be reset when the user logs out.
        if (accepted && this.TrackAcceptedDisclaimerOncePerSession)
            this._TicketEntryDisclaimerAccepted = true;
        else
            this._TicketEntryDisclaimerAccepted = false;
    }

    /** If the Center allows the Excavators to enter comments that only Excavtors can see. */
    public get CenterUsesExcavatorComments(): boolean {
        switch (this.CurrentOneCallCenterCode) {
            case "UDIGNY":
            case "IN811":
            case "IDDIGLINE":
                return false;
            default:
                //  Default is to alow it
                return true;
        }
    }

    public get UsesSubContractors(): boolean {
        switch (this.CurrentOneCallCenterCode) {
            case "AZ811":
            case "SC811":
            case "FL811":
                return true;
            default:
                return false;
        }
    }

    public get RequireEmailOnSubcontractor(): boolean {
        switch (this.CurrentOneCallCenterCode) {
            case "FL811":
                return true;
            default:
                return false;
        }
    }

    public get AllowApiAccessOnlyLogins(): boolean {
        switch (this.CurrentOneCallCenterCode) {
            case "SC811":
                return true;        //  atm, SC is the only one that has api methods that use a username/password
            default:
                return false;
        }
    }

    public get TrackAcceptedDisclaimerOncePerSession(): boolean {
        switch (this.CurrentOneCallCenterCode) {
            case "AZ811":
                //  AZ wants disclaimer to be accepted on every ticket
                return false;
            default:
                //  Default is to track once per session (refreshing browser or logging out/in starts new session)
                return true;
        }
    }

    public get CancelTicketReasonLimits(): { maxColumns: number, maxRows: number } {
        switch (this.CurrentOneCallCenterCode) {
            case "DigSafe":
                return { maxColumns: 63, maxRows: 2 };      //  This limit is from DigSafe's "free form" ticket (now called "In Reference")
            default:
                return { maxColumns: 0, maxRows: 0 };       //  No limits
        }
    }

    public get CancelTicketReasonInitialValue(): string {
        switch (this.CurrentOneCallCenterCode) {
            case "DigSafe":
                return "Cancel Ticket";
            default:
                return "";
        }
    }

    public get CancelTicketPromptForReplacedByTicketNumber(): boolean {
        return (this.CurrentOneCallCenterCode === "IN811");
    }

    /***
     *  Returns a (human readable) date for when an Incomplete ticket saved at the given date will be
     *  automatically voided.  This is done by the VoidIncompleteTicketsTask.
     * */
    public CalculateVoidIncompleteDate(ticketTakenDate: Date = null): string {
        //  This is not currently configured - it's hardcoded in VoidIncompleteTicketsTask.
        //  If that changes, will need to fetch that setting and use it here.
        const purgeAfterDays = 2;

        if (!ticketTakenDate)
            ticketTakenDate = new Date();

        //  Job runs at midnight and then purges tickets created < ([midnight] - [purgeAfterDays]) prior.
        //  So the date (at midnight) when the job will purge a ticket taken on ticketTakenDate is (purgeAfterDays + 1).
        ticketTakenDate.setDate(ticketTakenDate.getDate() + purgeAfterDays + 1);

        return "midnight on " + formatDate(ticketTakenDate, this.DateFormat, "en-US");
    }

    public IncompleteWarningMessage(): string {
        //  Because SC (and now IN) have to be difficult and we need this in the TicketActionsService
        const voidDate = this.CalculateVoidIncompleteDate();
        switch (this.CurrentOneCallCenterCode) {
            case "SC811":
                return "<p>This information will be held in our system until midnight on the day of creation.</p><p style='max-width:45em'><b>For this locate notice to be completed and submitted to Member Operator(s) you will need to reach out to our Help Desk for further assistance at 1-800-290-2783 Option 1.</b></p>";
            case "IN811":
                return "\
<p style='font-weight:bold'>Member Utilities will <u>not</u> be notified until all required information is obtained.</p>\
<p style='font-weight:bold'>The information will be held in our system until "+ voidDate + ".</p>\
<p style='font-weight:bold'>To resume this ticket, please call 811 and state that your ticket is Incomplete OR continue the process online (if available).</p>\
<p style='font-weight:bold'>The requested start date and time may recalculate when the ticket is resumed.</p>";
            default: {
                return "<p>This information will be held in our system until " + voidDate + ".</p><p>In order to complete and submit this ticket, you must call 811 and state that you have an Incomplete ticket.</p><p>Our Member Utilities will <strong><u>not</u></strong> be notified of your request until all necessary information is obtained.</p>";
            }
        }
    }

    public get PromptToBillMessages(): boolean {
        switch (this.CurrentOneCallCenterCode) {
            case "UDIGNY":
            case "IN811":
            case "SC811":
                return true;
            default:
                //  Default is to not prompt
                return false;
        }
    }


    //  Should probably read this from a field in the TicketFieldConfiguration...
    public get AffectedServiceAreaDialog_TicketTypeLabel(): string {
        switch (this.CurrentOneCallCenterCode) {
            case "FL811":
                return "Priority";
            case "UDIGNY":
                return "Priority/TicketType";
            default:
                return "Ticket Type";
        }
    }

    //  Should probably read this from a field in the TicketFieldConfiguration...
    public get AffectedServiceAreaDialog_RemarksLabel(): string {
        switch (this.CurrentOneCallCenterCode) {
            case "UDIGNY":
            case "DigSafe":
                return "Comments";
            case "IN811":
                return "Notes/Remarks";
            default:
                return "Remarks";
        }
    }

    //  SC needs this date to be shown.  But a couple other One Calls use it (so it's enabled in the FieldState)
    //  but have not asked for it to be shown there.  And they have different labels.  So just enabling this for
    //  SC for now.  At some point, need to make the Dates portion of the Affected Service Area dialog (or maybe
    //  the entire header) dynamic per One Call.
    public get AffectedServiceAreaDialog_ShowAncillaryRestakeDate(): boolean {
        switch (this.CurrentOneCallCenterCode) {
            case "SC811":
                return true;
            default:
                return false;
        }
    }

    public get AffectedServiceAreaDialog_TicketTypeIsEditable(): boolean {
        //  Disabled for AZ because changing this recalculates the work start and also the meeting date - which can mess up
        //  the meet scheduling (which is done before the affected service area dialog).
        //  And NY has too many rules when the ticket type changes.
        //return this.CurrentOneCallCenterCode === "FL811";
        //  This was enabled for FL (and only FL) but their ticket type field is disabled anyway so it would never be possible
        //  to change it.  They set it with a shortcut.
        return false;
    }

    public get AffectedServiceAreaDialog_WorkStartIsEditable(): boolean {
        //  FL does not want to edit this.
        //  Also disabled for AZ because of possible complications with the meeting date (although changing the work start
        //  does not recalculate the meeting date - so maybe can enable it?).  But changing ticket type definitely does
        //  so that needs to be disabled (so makes sense to disable this too) or we would need to add special handling for what that changes.
        //  And...disabled for everyone - too many complications with rules around the date changing.
        return false;
    }

    public ShowServiceAreaCodeOnServiceAreaList(user: AppUser): boolean {
        //  IN/KY do not want to show the service area code in any of the service area lists...but only for web users
        //  user is null/undefined when using the anonymous find ticket page.
        if (this.CurrentOneCallCenterCode === "IN811")
            return user && (user.IsLocalUser || user.IsSupportUser);

        return true;
    }

    public UsesServiceAreaResponseEntryType(entryType: ServiceAreaResponseEntryTypeEnum): boolean {
        switch (this.CurrentOneCallCenterCode) {
            case "SC811":
                //  SC only uses response by utility type
                return (entryType === ServiceAreaResponseEntryTypeEnum.UtilityType);
        }

        return true;
    }

    public get UsesServiceAreaTypes(): boolean {
        return this.CurrentOneCallCenterCode === "AZ811";
    }

    public get UsesServiceAreaMarkingColors(): boolean {
        return this.CurrentOneCallCenterCode === "AZ811";
    }

    public get UsesExtraordinaryCircumstances(): boolean {
        //  Note that ServiceBiz.DefaultListColumns() has this hardcoded for FL
        return this.CurrentOneCallCenterCode === "FL811";
    }

    public get UsesMeetScheduler(): boolean {
        return this.CurrentOneCallCenterCode === "AZ811" || this.CurrentOneCallCenterCode === "IDDIGLINE";
    }

    public ScheduledMeetingDurationOptions(): SelectOption[] {
        switch (this.CurrentOneCallCenterCode) {
            case "IDDIGLINE":
                return [new SelectOption(60, "1 Hour")];
            default:
                return [
                    new SelectOption(15, "15 Minutes"),
                    new SelectOption(30, "30 Minutes"),
                    new SelectOption(45, "45 Minutes"),
                    new SelectOption(60, "1 Hour"),
                    new SelectOption(90, "1.5 Hours"),
                    new SelectOption(120, "2 Hours"),
                    new SelectOption(180, "3 Hours"),
                    new SelectOption(240, "4 Hours"),
                ];
        }
    }

    /*
     *  If returns true (the default), available meeting times on the schedule meeting dialog are arranged
     *  in a grid layout.  If false, times are just shown in a single row.
     */
    public ArrangeMeetingTimesInGrid(): boolean {
        //  Idaho only has 2 available meet times so they are shown in a row.
        //  AZ can have lots of available meet times so they are arranged in a grid.
        return (this.CurrentOneCallCenterCode !== "IDDIGLINE");
    }

    //  TODO: Will be adding another Service Area Ticket Assignment Filter for SC.  When we do, may need
    //  to enhance how these options are enabled.
    public get UsesServiceAreaNearEdgeOfRoadFilter(): boolean {
        return this.CurrentOneCallCenterCode === "UDIGNY";
    }

    public get UsesIVR(): boolean {
        return this.CurrentOneCallCenterCode === "FL811" || this.CurrentOneCallCenterCode === "UDIGNY";
    }

    public get UsesPositiveResponse(): boolean {
        return this.CurrentOneCallCenterCode !== "DigSafe";
    }

    public get UsesExcavatorEmailNA(): boolean {
        return this.CurrentOneCallCenterCode === "UDIGNY";
    }

    /**
     *  If true, if a Excavator Company/Office/Contact and then changed, the user will be required to confirm
     *  that they intended to change rather than add a new record.
     */
    public get ConfirmExcavatorUpdate(): boolean {
        //  This should be turned on for everyone going forward, but it was added for DigSafe and then NY agreed they wanted it.
        //  Did not hear from AZ or FL so leaving it off for them until they say otherwise.
        return (this.CurrentOneCallCenterCode !== "AZ811") && (this.CurrentOneCallCenterCode !== "FL811");
    }

    /**
     *  If true, if an ExcavatorContact is picked and will be added to a different office, the user will be required to confirm
     *  that the contact should be added to the office.
     */
    public get ConfirmExcavatorContactAddToOffice(): boolean {
        //  This was added for IN/KY so until I hear otherwise, leaving it disabled for everyone before them and enabled by default going forward.
        switch (this.CurrentOneCallCenterCode) {
            case "AZ811":
            case "FL811":
            case "UDIGNY":
            case "DigSafe":
                return false;
            default:
                return true;
        }
    }

    //  This should probably be set in the server in OneCallCenterBiz.ConfigureTicketEntryField() by
    //  setting the DisplayName property.  But that's going to change it from the default name of "Geocode Type"
    //  in the ticket search as well.  If we do that and then use that value for this label, will need to deal
    //  with that/warn everyone of the change/???
    public get GeocodeTypeLabel(): string {
        switch (this.CurrentOneCallCenterCode) {
            case "SC811":
                return "Mapped By";
            default:
                return "Notify By";
        }
    }

    public get PlaceNameLabel(): string {
        switch (this.CurrentOneCallCenterCode) {
            case "DigSafe":
                return "Municipality";
            case "IN811":
                return "Township";
            case "IDDIGLINE":
                return "City";
            case "UDIGNY":
                return "City/Place";
            default:
                return "Place";
        }
    }

    public get PluralPlaceNameLabel(): string {
        switch (this.CurrentOneCallCenterCode) {
            case "DigSafe":
                return "Municipalities";
            case "IN811":
                return "Townships";
            case "IDDIGLINE":
                return "Cities";
            default:
                return "Places";
        }
    }

    public ShowNewTicketOnHomePage(): boolean {
        switch (this.CurrentOneCallCenterCode) {
            case "FL811":
                return false;
        }

        return true;
    }

    public GetTicketEntrySiteInfoOptions(): SiteInfoOptions {
        const options = new SiteInfoOptions(this.UsesCountyInLocations);

        switch (this.CurrentOneCallCenterCode) {
            case "AZ811":
                options.PromptForStreetBeforeStateCounty = true;
                options.UseSideOfStreet = true;
                options.UseCorner = true;
                break;
            case "DigSafe":
                options.PlaceFollowsStreet = false;
                options.ShowMainStreetOn2ndIntersection = false;
                break;
            case "FL811":
                options.UseWithinQtrMileOnNearSt = true;
                options.UseUserEnteredDistanceOnNearSt = true;
                break;
            case "IDDIGLINE":
                options.CountyFollowsPlace = true;
                options.PlaceFollowsStreet = false;
                options.MainStreetPlaceholder = "Address/Street";
                options.ShowMainStreetOn2ndIntersection = false;
                break;
            case "IN811":
                options.UseWithinQtrMileOnNearSt = true;
                options.CrossStreetLabel = "Intersecting Street";
                break;
        }

        return options;
    }

    //  Also have a property for this in ICenterBiz - need to consolidate these things so it's not duplicated...
    public get UsesCountyInLocations(): boolean {
        return SettingsService.UsesCountyInLocations(this.CurrentOneCallCenterCode);
    }

    //  This one is needed by the ExcavatorContact page (which is anonymous...)
    public static UsesCountyInLocations(occCode: string): boolean {
        //  DigSafe does not use county
        return occCode !== "DigSafe";
    }

    /**
     *  If true, when copying or creating another ticket that has a manual dig site, changing the location fields will
     *  reset/clear the manual dig site and re-geocode the location.  When false, the manual will never be cleared.
     */
    public get ResetManualDigSiteIfLocationChanged(): boolean {
        //  Don't do this for AZ.  Their users want to keep manual dig sites - they often copy/create another for tickets in the
        //  same subdivision and do not change the manual dig site!  But they do change the address #.  I don't know why AZ thinks that
        //  doesn't affect the dig site polygon or why they would not want to encourage them to re-draw it to make sure it's acccurate...
        return this.CurrentOneCallCenterCode !== "AZ811";
    }

    /**
     *  Returns the allowed DeliveryRuleAuditFormats for the center
     */
    public get DeliveryRuleAuditFormats(): DeliveryRuleAuditFormatEnum[] {
        switch (this.CurrentOneCallCenterCode) {
            case "DigSafe":
                return [DeliveryRuleAuditFormatEnum.SingleMessage];     //  Think this is backwards for DigSafe - should be Separate Message!  But this is how it's been for a long time...
        }

        //  All other centers use all values
        const values: DeliveryRuleAuditFormatEnum[] = [];
        const keys = Object.keys(DeliveryRuleAuditFormatEnum).filter((type) => isNaN((type as any)) && type !== 'values');
        for (const key of keys)
            values.push(DeliveryRuleAuditFormatEnum[key]);

        return values;
    }

    /**
     *  If true, when copying or creating another ticket that has a manual dig site, prompt the user about resetting the manual digsite.
     */
    public get PromptToResetManualDigSiteWhenCopying(): boolean {
        return this.CurrentOneCallCenterCode === "UDIGNY";
    }

    public get TicketNumberSearchRequiredChars(): number {
        switch (this.CurrentOneCallCenterCode) {
            case "UDIGNY":
                return 5;
            case "AZ811":
            case "DigSafe":
            case "IN811":
            case "SC811":
            case "IDDIGLINE":
                return 6;
            default:
                //  FL is the shortest right now, so default needing at least this many
                return 4;
        }
    }

    /**
     *  A mask to use with the [textMask] directive in an input control to prompt for a ticket number.
     *  Directive is: angular2-text-mask  https://github.com/text-mask/text-mask/tree/master/angular2
     *  which is a wrapper for this: https://github.com/text-mask/text-mask
     *  And that is apparently not being maintained any more.  May want to convert to: https://github.com/uNmAnNeR/imaskjs
     */
    public get TicketNumberMaskOptions(): { mask: (string | RegExp)[], guide: boolean } {
        return SettingsService.TicketNumberMaskOptionsForOneCall(this.CurrentOneCallCenterCode);
    }

    //  Static because this is needed during self registration - when user is not logged in
    public static TicketNumberMaskOptionsForOneCall(oneCallCenterCode: string): { mask: (string | RegExp)[], guide: boolean } {
        let mask: (string | RegExp)[];

        //  Could probably define some of the year and month digits a little more restrictively.
        switch (oneCallCenterCode) {
            case "AZ811":
                //  YYYYMMDD#####
                mask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
                break;
            case "FL811":
                //  DDDY#####
                mask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
                break;
            case "UDIGNY":
                //  YYDDD-###-###
                mask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
                break;
            case "DigSafe":
                //  YYYYWW#####
                mask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
                break;
            case "IN811":
            case "SC811":
            case "IDDIGLINE":
                //  YYMMDD####
                //  Digline is: YYYYWW####
                mask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
                break;
            default:
                throw new Error("Unhandled One Call Center Code of: " + oneCallCenterCode);
        }

        return {
            mask: mask,
            guide: false
        }
    }

    /**
     *  True if the One Call tracks Holidays and Date Calculations by State.
     */
    public get TrackHolidaysAndDatesByState(): boolean {
        return (this.CurrentOneCallCenterCode === "DigSafe");
    }

    public get HasMultipleStates(): boolean {
        return SettingsService.HasMultipleStates(this.CurrentOneCallCenterCode);
    }

    //  This one is needed by the ExcavatorContact page (which is anonymous...)
    public static HasMultipleStates(occCode: string): boolean {
        return (occCode === "DigSafe");
    }

    public get MapSearchTRSQGridType(): MapSearchTRSQGridTypeEnum {
        switch (this.CurrentOneCallCenterCode) {
            case "AZ811":
                return MapSearchTRSQGridTypeEnum.TRSQ_Long;
            case "FL811":
                return MapSearchTRSQGridTypeEnum.TRS_Short;
            default:
                return MapSearchTRSQGridTypeEnum.None;
        }
    }

    /**
     *  type: 0 = DigSite, 1 = Registrations, 2 = MapFeatures
     */
    public MapLineDrawingBuffer(type: number): number {
        switch (this.CurrentOneCallCenterCode) {
            case "DigSafe":
                return 500;
            case "SC811":
                return (type === 1) ? 100 : 250;
            case "IDDIGLINE":
                return 75;
            default:
                return 200;
        }
    }

    public get MapCircleDrawingBuffer(): number {
        switch (this.CurrentOneCallCenterCode) {
            case "SC811":
                return 250;
            case "IDDIGLINE":
                return 75;
            default:
                return 200;
        }
    }

    public get MapPolygonDigSiteDrawingBuffer(): number {
        switch (this.CurrentOneCallCenterCode) {
            case "IN811":
                return 200;
            case "IDDIGLINE":
                return 75;
            case "AZ811":
                return 100;
            default:
                //  Using 50 because that's NY & SC's default Parcel buffer.  If need something else, make sure that is covered.
                return 50;
        }
    }

    /**
     *  When true, using the same drawing tools multiple times (without hitting the Save button) will allow drawing multiple
     *  shapes at once.  i.e. Can draw a circle, click circle button and draw another one.
     *  When false, using the drawing tool again and drawing a new shape will clear the existing shape when a new one is drawn.
     */
    public get MapDigSiteDrawingAllowMultipleShapes(): boolean {
        switch (this.CurrentOneCallCenterCode) {
            case "AZ811":
            case "FL811":
            case "IN811":
                return true;
            case "DigSafe":         //  DigSafe wants this too as of 2022/05/05
            case "UDIGNY":          //  NY wants this too as of 2022/05/24
            default:
                //  SC wanted this false and decided to make this the default going forward.  If we allow multiple shapes, it just
                //  makes it that much easier for the user to "accidentally" draw multiple polygons - which is usually not allowed by any One Calls.
                return false;
        }
    }

    public get MapLabelDistancesOnlyInFeet(): boolean {
        switch (this.CurrentOneCallCenterCode) {
            case "SC811":
                //  Distance labels are always in feet.
                return true;
        }

        //  If the distance is more than 1 mile, label will show # miles.
        return false;
    }

    /**
     *  True if the Latitude (Y coord) is entered first when prompting for a lat/lon coordinate.  i.e. 42.59252, -71.14964
     *  False if the Logitude (X coord) is entered first: i.e. -71.14964, 42.59252
     *  The default is true - show coords as Lat then Lon.  This seems to be the accepted standard (possibly ISO 6709?).
     */
    public get LatLonCoordinateEnteredLatFirst(): boolean {
        //  ** If this is changed, don't forget to also change LatLonCoordinateExample()
        switch (this.CurrentOneCallCenterCode) {
            case "AZ811":
                //  This is the exception because this is how it was in irth and they have not asked to change it.
                return false;
            default:
                return true;
        }
    }

    //  ** If this is changed, don't forget to also change LatLonCoordinateExample()
    public get LatLonCoordinateMaxDecimalDigits(): number {
        switch (this.CurrentOneCallCenterCode) {
            case "DigSafe":
                return 7;
            case "IDDIGLINE":
                return 6;
            default:
                return 5;
        }
    }

    //  ** If this is changed, don't forget to also change LatLonCoordinateExample()
    public get LonCoordinateMaxWholeNumberDigits(): number {
        switch (this.CurrentOneCallCenterCode) {
            case "AZ811":
            case "IDDIGLINE":
                return 3;
            default:
                return 2;
        }
    }

    public get LatLonCoordinateExample(): string {
        //  FL office = -81.3119, 28.8753
        //  AZ office = -111.9619, 33.3438
        //  DigSafe office = 42.59252, -71.14964  (they prompt as lat/lon - reverse from other centers)
        //  Convert from decimal to degress-minutes-seconds
        //  https://www.calculatorsoup.com/calculators/conversions/convert-decimal-degrees-to-degrees-minutes-seconds.php
        switch (this.CurrentOneCallCenterCode) {
            case "AZ811":
                return "-111.9619, 33.3438 or -111 57 42.8, 33 20 37.7";
            case "FL811":
                return "28.8753, -81.3119 or 28 52 31.1, -81 18 42.8";          //  lat first
            case "UDIGNY":
                return "43.1043, -76.0577 or 43 06 15.5, -76 03 27.7";          //  lat first
            case "DigSafe":
                return "42.59252, -71.14964 or 42 35 33.0, -71 08 58.6";        //  lat first
            case "IN811":
                return "39.6212, -86.0777 or 39 37 16.3, -86 04 39.7";          //  lat first
            case "SC811":
                return "34.0340, -81.0945 or 34 02 02.4, -81 05 40.2";          //  lat first
            case "IDDIGLINE":
                //  Boise airport
                return "43.568010, -116.223475 or 43 34 04.8, -116 13 24.5";    //  lat first
            default:
                return "-83.09112, 40.088234 or -83 05 28.0, 40 05 17.6";       //  4iq office
        }
    }

    //  This is the old method of fetching settings from the server that are configured via environment
    //  variables into the docker.  Don't currently need it, but may in the future - like if we need to
    //  configure a build number or something to use to know if the app is out of date and needs to
    //  update itself.  This is going to look for a .json file in the assets folder - just create that
    //  file and change the NginxStart.sh script to set properties into it as necessary.
    //private _AppSettings: AppSettings = null;
    //private _ExecutingLoadServerSettingsPromise: Promise<void> = null;
    ///**
    // * Load and set any Server settings.
    // * Default values for these are stored in assets/appsettings.json.
    // * When hosted and served by nginx, it will return customized values for that resource based on
    // * the current environment.
    // * The initial call happens when the AuthenticationService first initializes itself - which happens very
    // * early in the app lifecycle.  So once the app is loaded (and the user is validated), it's safe to assume
    // * that this has happened.
    // * @param forceRefresh
    // */
    //public LoadAppSettings(forceRefresh?: boolean): Promise<void> {
    //    //  If already loaded and don't need to refresh, just return a promise that will immediately resolve
    //    if (this._AppSettings && !forceRefresh)
    //        return new Promise<void>((resolve, reject) => resolve());

    //    //  Need to fetch.  If we are already executing it from another request, let the caller attach to that promise so we
    //    //  don't create multiple requests at the same time.
    //    if (this._ExecutingLoadServerSettingsPromise)
    //        return this._ExecutingLoadServerSettingsPromise;

    //    this._ExecutingLoadServerSettingsPromise = new Promise<void>((resolve, reject) => {
    //        //  HttpBackend is used to construct an HttpClient that will bypass our ApiInterceptor.
    //        //  https://stackoverflow.com/questions/46469349/how-to-make-an-angular-module-to-ignore-http-interceptor-added-in-a-core-module/49013534#49013534
    //        //  Otherwise, the ApiInterceptor has a dependency on the AuthenticationService which could then trigger this method
    //        //  again - in an infinite loop.
    //        const httpClient = new HttpClient(this._HttpHandler);   //  add this to constructor: private _HttpHandler: HttpBackend
    //        httpClient.get<AppSettings>(this.UiBaseUrl + "/assets/appsettings.json")
    //            .subscribe(settings => {
    //                this._AppSettings = settings;
    //                resolve();
    //            }, err => reject());
    //    });
    //    return this._ExecutingLoadServerSettingsPromise;
    //}
}
