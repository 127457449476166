import { NgModule } from '@angular/core';

import { PhoneAddComponent } from 'Shared/AddNewForms/Phone/PhoneAdd.component';
import { PhoneListSectionComponent } from '@iqSharedComponentControls/Phone/ListSection/PhoneListSection.component';
import { PhoneNewDialog } from 'Shared/AddNewForms/Phone/NewDialog/PhoneNewDialog.component';
import { PhoneComponent } from '../Components/Controls/Phone/Phone.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from './Material.module';
import { UtilsModule } from './Utils.module';
import { IQPipesModule } from '../Pipes/Pipes.module';
import { IQSectionModule } from '../Components/Controls/Section/Modules/Section.module';
import { TextMaskModule } from 'angular2-text-mask';
import { IconButtonModule } from '../Components/Controls/IconButton/IconButton.module';




@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        UtilsModule,
        IQPipesModule,
        IQSectionModule,
        TextMaskModule,
        IconButtonModule
    ],
    declarations: [
        PhoneAddComponent,
        PhoneListSectionComponent,
        PhoneNewDialog,
        PhoneComponent
    ],
    exports: [
        PhoneAddComponent,
        PhoneListSectionComponent,
        PhoneComponent
    ]
})
export class PhoneModule { }
