export enum FieldUIControlTypeEnum {
    Unknown = 0,
    TextSingleLine = 1,
    TextMultiLine = 2,
    Integer = 3,
    Decimal = 4,
    Dropdown = 5,
    Checkbox = 6,
    Date = 7,
    Time = 8,
    DateTime = 9,
    Phone = 10,
    Email = 11,
    MultipleSelectAutocomplete = 12,    //  Multiple select/autocomplete chip list.
    TextMask = 13                       //  Put a [textMask] directive on the input to set the mask, see https://github.com/text-mask/text-mask/tree/master/angular2/#readme
}
