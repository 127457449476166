import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { DeviceDetectorService } from "../DeviceDetector.service";

/**
 *  Put this in the "canActivate" list of a route (after AuthenticationGuardService) to prevent a phone device from accessing the route.
 *  Phone users will be redirected to /home
 */
@Injectable({
    providedIn: 'root'
})
export class PreventPhoneGuardService implements CanActivate {
    constructor(private _DeviceDetectorService: DeviceDetectorService, private _Router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //  Note that there is another check for this in the DeviceDetectorService which will check this if the browser size changes to a phone.
        if (this._DeviceDetectorService.IsPhone) {
            this._Router.navigate(["./home"]);
            return false;
        }

        return true;
    }
}
