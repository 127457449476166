import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, Input } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Guid } from '@iqSharedUtils/Guid';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { SelectOption } from 'Models/Configuration/SelectOption.model';
import { IEntity } from 'Models/Interfaces/IEntity.interface';
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { SearchFilter, SearchFilterValue } from 'Models/Searching/SearchFilter.model';
import { SearchOrderBy } from 'Models/Searching/SearchOrderBy.model';
import { SearchRequest } from 'Models/Searching/SearchRequest.model';
import { ToastrService } from 'ngx-toastr';
import { AddPositiveResponseData } from 'Pages/Tickets/Responses/AddPositiveResponse/AddPositiveResponse.component';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, mergeMap, take, takeUntil } from 'rxjs/operators';
import { CommonService } from 'Services/CommonService';
import { PrintingService } from 'Services/Printing.service';
import { SettingsService } from 'Services/SettingsService';
import { BaseListDisplayPageClass, BaseListDisplayPageClassService } from 'Shared/BaseClasses/BaseListDisplayPage.class';
import { CRUDBaseService } from 'Shared/BaseServices/CRUDBase.service';
import { AddPositiveResponseDialog } from '../../Responses/AddPositiveResponse/Dialog/AddPositiveResponseDialog.component';
import { TicketActionsService } from '../../Services/TicketActions.service';

export enum TicketListListActions {
    AddPositiveResponse = 50,//Need to make sure these don't overlap the base actions on the BaseListDisplayPageClass
    ViewPositiveResponses = 51,
    ViewAllRelated = 52,
    PrintTicketText = 53
}

/**
 *  This class is used as a base class where we need a ticket list.  We have 2 cases where we need to show lists of tickets
 *  from different sources - Tickets and TicketResponses.  Each needs to call their own search api (via either TicketService
 *  or TicketResponseService).  But they both need all of the same "ticket actions" (the ... menu).  So this class handles all
 *  of that as well as the html layout.
 *  ***** A derived class should reference TicketListBase.component.html and TicketListBase.component.scss
 * */
@Directive()
export abstract class DesktopTicketListBase extends BaseListDisplayPageClass {    

    disableFilters: boolean = false;

    public ShowMarkWorkComplete: boolean = false;
    public ShowMarkWorkNotComplete: boolean = false;
    public ShowAddResponse: boolean = false;
    public selectAllChecked: boolean = false;
    public AllowMultipleActionsOnTicketList: boolean = false; 
    public LimitToTicketsWithinXFtOfCurrentLocation?: number;

    //  Set filters here that should be included when doing a search by Ticket Number.  Otherwise, no other filters are included.
    //  That is normally correct except, for example, if the list is only supposed to show the "current" record or something like that.
    //  Not including that filter would cause multiple rows which would not make sense based on the columns being used.
    //  Specifically needed on the Service Area user ticket dashboard for the very first tab (which shows the Current response only);
    public FiltersRequiredForTicketNumberSearch: SearchFilter[];

    private _AllowedTicketActionsDebouncer: Subject<string[]> = new Subject<string[]>();

    constructor(crudService: CRUDBaseService<IEntity>, protected baseServices: BaseListDisplayPageClassService, private _TicketActionsService: TicketActionsService,
        private settingService: SettingsService, private toastr: ToastrService, private _PrintingService: PrintingService, public _CommonService: CommonService)
    {
        super(crudService, baseServices);

        //  This observable is used to fetch the AllowedActions when using the toggles to multiple-select.
        //  Otherwise, the select-all will trigger an api call for every single item that is checked.
        //  This debounces it so that only a single api call is made with the final list of selected tickets.
        this._AllowedTicketActionsDebouncer
            .pipe(
                takeUntil(this.destroyed$),
                debounceTime(100),
                mergeMap((ticketIDs) => this._TicketActionsService.GetAllowedTicketActions(ticketIDs))
            )
            .subscribe(actionList => {
                this.ShowMarkWorkComplete = actionList?.CanMarkWorkCompleted;
                this.ShowMarkWorkNotComplete = actionList?.CanMarkWorkNotCompleted;
                this.ShowAddResponse = actionList?.CanAddResponses;
            });

        this._CommonService.AuthenticationService.CurrentUserObserver().pipe(take(1)).subscribe((user) => {
            this.AllowMultipleActionsOnTicketList = user.AllowMultipleActionsOnTicketList;
        });
    }

    UpdateSearchFilters() {
        this.SetDefaultOrderAndFilterItems();
        this.filter.filterChange.next(true);
    }

    ClearFilters(fireEvent: boolean = true) {
        if (this.SearchFilter) {
            this.filter.ColumnFilters = this.baseServices.listFilterService.copyFilters(this.SearchFilter);
            this.filter.filterString = null;
            this.filter.filterTextValue = null;

            if (fireEvent)
                this.filter.filterChange.next(true);
        }
        else
            super.ClearFilters(fireEvent);
    }

    ClearSort(fireEvent: boolean = true) {
        
        if (this.SearchOrderBy) {
            this.filter.OrderBy = this.baseServices.listFilterService.copyOrderBys(this.SearchOrderBy);

            if (fireEvent)
                this.filter.filterChange.next(false);
        }
        else
            super.ClearSort(fireEvent);
    }

    minCharsDefaultSearch = this.settingService.TicketNumberSearchRequiredChars;

    defaultOrderBy = [new SearchOrderBy("TakenEndDate", true)];

    //  TODO: This is terrible!  The queries created in TicketDashboardService should just always specify what is required in the
    //  RequiredFilters property of each query!  Changed them to do this for the phone filtering but left this here just in case.
    @Input() requiredFilters: string[] = ["TakenEndDate", "TakenStartDate", "WorkStartDate", "ExpiresDate", "ResponseDueDate"];
        
    protected GetMainFilterPropertiesFilters(value: string): SearchFilter {
        return new SearchFilter("TicketNumber", SearchFilterOperatorEnum.StartsWith, [new SearchFilterValue(value.trim(), value.trim())], false, true);//Trim this since it'a an equals, so a space at begin or end will return nothing
    }

    public ActionList: SelectOption[] = [];
    private _PrevMatMenu: MatMenu = null;

    public MenuOpened(item: any, menu: MatMenu): void {
        this.ActionList = [];

        //  We track the previous MatMenu so that we can force it closed when another menu is opened.  If we don't do this, the user can use
        //  the keyboard to open up multiple menus at once!
        //  1) Open a menu
        //  2) tab twice - this will cause focus to be in the "..." button of the next row
        //  3) Hit the space or enter keys - new menu is opened in addition to the previous.
        //  ** This is also the reason why we are fetching the allowed actions via the (menuOpened) event.  Previously, we were doing that as an async list
        //  on the *ngFor".  But when an additional menu was opened, it caused BOTH menus to alternate refreshing it's menu list which then spammed
        //  the api until it exhausted all available postgres database connections!
        if (this._PrevMatMenu && (this._PrevMatMenu !== menu))
            this._PrevMatMenu.closed.next("click");
        this._PrevMatMenu = menu;

        const deleteRowsForCompletedResponses = this.SearchFilter && this.SearchFilter.some(f => f.PropertyName === "HaveCompletedResponse");
        this._TicketActionsService.GetAllowedTicketActionsMenuItems([item], () => this.refreshSearch(), null, this.DisplayedColumns, (entity: IEntity) => this.DeleteItem(entity), deleteRowsForCompletedResponses, () => this.ClearSelectedItems()).pipe(take(1))
            .subscribe(actionList => {
                this.ActionList = actionList;
            });
    }

    protected SetActionList(): Observable<SelectOption[]> {
        return of([]);
    }

    ToggleSelectAll(selected: boolean): void {
        if (!selected) {
            this.ClearSelectedItems();
        } else if (this.items.value) {
            //  this.items.value can be null if there are no items in the list!
            this.items.value.forEach(item => this.ToggleSelected(selected, item));
        }

        this.AllowedTicketsDebouncer();
    }

    public override ToggleSelected(selected: boolean, item: IEntity) {
        super.ToggleSelected(selected, item);

        this.AllowedTicketsDebouncer();

        if (!selected)
            this.selectAllChecked = false;
        else if (!this.items.value.some(m => !m.Selected))
            this.selectAllChecked = true;
    }

    private AllowedTicketsDebouncer() {
        //  Must fetch the allowed ticket actions so that we know which buttons in the toolbar can be enabled for
        //  any of the selected tickets.  Can't do that just by the value in the IsWorkComplete property any more because we
        //  now prevent tickets from being "not" completed after a configurable number of minutes.
        //  This is debounced mostly for when "select all" is used - to prevent hitting the server for every single call to ToggleSelected.
        const ticketIDs = this.selectedItems.map(t => t.ID as string);
        this._AllowedTicketActionsDebouncer.next(ticketIDs);
    }

    protected override ClearSelectedItems(fetchingNewRecords: boolean = false) {
        super.ClearSelectedItems(fetchingNewRecords);
        this.ShowMarkWorkComplete = false;
        this.ShowMarkWorkNotComplete = false;
        this.selectAllChecked = false;
    }

    public viewAllRelated: string;
    protected SpecialAction(action: SelectOption, listItem: any = null) {
        if (action.Value === TicketListListActions.ViewAllRelated) {
            this.viewAllRelated = listItem.TicketLinkID;
            this.refreshSearch();
        }
        else if (action.Value.OnClick)
            action.Value.OnClick(listItem);
    }

    removeRelatedSearch() {
        this.viewAllRelated = null;
        this.refreshSearch();
    }
    ClearSortAndFilters() {
        this.viewAllRelated = null;
        super.ClearSortAndFilters();
    }
    filterChange(filters: SearchFilter[]) {
        this.viewAllRelated = null;
        
        for (let i = 0; i < filters.length; i++) {
            const f = this.MassageAgentFilter(filters[i]);
            if (f.PropertyName !== filters[i].PropertyName) {
                filters[i] = f;
            }
        }

        super.filterChange(filters);
    }

    //By default do a contains for all columns.  Override this if you have a column that needs something different
    protected GetSearchFilterObject(column: SearchColumn, filter: SearchFilter) {
        if ((column.filterColumn === "AgentPersonID") || (column.filterColumn === "LockedByPersonID")) {
            return this.MassageAgentFilter(filter);
        }

        return filter;
    }

    //  TODO: This should not need to be manually handled like this.  If we configure the column with the "usePersonSearch" flag,
    //  we should also give it the column name to use when doing a text filter.  Then *THE SEARCH COMPONENT* should handle using either the ID filter
    //  name or the text column filter name!  Otherwise, we have to add this handling in every individual list that searches by people!
    private MassageAgentFilter(filter: SearchFilter) {
        //Only do this on contains because if done for 'Myself' then the filter.value won't be a GUID because the server has to get the personID to use so they can save the filter
        if (filter.PropertyName === "AgentPersonID" && filter.Operator === SearchFilterOperatorEnum.Contains && !Guid.isGuid(filter.Values[0].FilterValue))
            return new SearchFilter("Agent.Fullname", filter.Operator, filter.Values);
        else if (filter.PropertyName === "Agent.Fullname" && (filter.Operator === SearchFilterOperatorEnum.CurrentUser || Guid.isGuid(filter.Values[0].FilterValue)))
            return new SearchFilter("AgentPersonID", filter.Operator, filter.Values);
        else if (filter.PropertyName === "LockedByPersonID" && filter.Operator === SearchFilterOperatorEnum.Contains && !Guid.isGuid(filter.Values[0].FilterValue))
            return new SearchFilter("LockedByPerson.Fullname", filter.Operator, filter.Values);
        else if (filter.PropertyName === "LockedByPerson.Fullname" && (filter.Operator === SearchFilterOperatorEnum.CurrentUser || Guid.isGuid(filter.Values[0].FilterValue)))
            return new SearchFilter("LockedByPersonID", filter.Operator, filter.Values);

        return filter;
    }

    public AddPositiveResponse(listItems: any[]): void {
        const deleteRowsForCompletedResponses = this.SearchFilter && this.SearchFilter.some(f => f.PropertyName === "HaveCompletedResponse");
        this._TicketActionsService.AddPositiveResponse(listItems, this.DisplayedColumns, (entity: IEntity) => this.DeleteItem(entity), deleteRowsForCompletedResponses, () => this.ClearSelectedItems());
    }

    protected ApplySearchFilters() {
        const request: SearchRequest = super.ApplySearchFilters();

        //If filtering on ticket number from the quick search then only use that value and disable all other filtering
        const ticketNumber = request.Filters ? request.Filters.find(f => f.QuickTextSearch) : null;
        if (this.viewAllRelated) {
            const filter = new SearchFilter("TicketLinkID", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(this.viewAllRelated, this.viewAllRelated)]);
            request.Filters = [filter];
        }
        else if (ticketNumber) {
            request.Filters = [ticketNumber];

            if (this.FiltersRequiredForTicketNumberSearch)
                request.Filters = request.Filters.concat(this.FiltersRequiredForTicketNumberSearch);
        }

        //Need to do this in a timeout so we don't get errors about it changing after being checked if it's being set from navigation page load
        setTimeout(() => this.disableFilters = coerceBooleanProperty(this.viewAllRelated || ticketNumber));

        //Always say to load these. Tickets have to have both columns and filters set, so tell the server to always try to get them (the server will only load them if they weren't passed in the call).
        //  Can't do this in the base list class because not every list uses the stored columns and filters.
        //  This is tricky in tickets because you can search by the number in the details, and it needs to keep that ticket number search when coming back to
        //  the list.  But then also allow the user to clear it out and still have a valid search (dates set, etc).  The issues are if they refresh the page on the ticket details, then do a
        //  ticket number search, we have to know what the filter should be when they come back to the list and clear out the ticket number search (highlight and delete from the quick search text field).
        request.LoadColumnsAndFilters = true;

        //  If there is a filter limiting to the current location, set this to force zooming to current location and hide the "Tickets" link on the .html page.
        const withinFeetValues = request.Filters?.find(f => f.Operator === SearchFilterOperatorEnum.WithinXFeetOfLocation)?.Values;
        if (withinFeetValues && withinFeetValues.length === 3)
            this.LimitToTicketsWithinXFtOfCurrentLocation = withinFeetValues[2].FilterValue;

        return request;
    }

    public MarkTicketsComplete(workCompleted: boolean) {
        this._TicketActionsService.MarkWorkCompleted(this.selectedItems.map(m => m.ID), workCompleted)
            .subscribe((allowedActions) => {
                //  If null, user canceled dialog or did not have permission (which should not happen)
                if (allowedActions) {
                    this.selectedItems.forEach(m => {
                        (m as any).IsWorkComplete = workCompleted === false ? "No" : "Yes";
                    });
                    this.ClearSelectedItems();
                }
        });
    }
}
