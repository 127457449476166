<div [formGroup]="group" style="height: 100%; overflow: auto; display: flex; flex-direction: column;">
    <mat-form-field style="flex: 1 1 0%;">
        <mat-label>Email Address</mat-label>
        <input matInput type="text" formControlName="email" />
        <mat-error *ngIf="emailControl.hasError('required')">required</mat-error>
        <mat-error *ngIf="emailControl.hasError('pattern')">format not valid</mat-error>
    </mat-form-field>

    <mat-form-field style="flex: 1 1 0%;" *ngIf="ShowVerifyEmail">
        <mat-label>Confirm Email Address</mat-label>
        <input matInput type="text" formControlName="confirmEmail" />
        <mat-error *ngIf="confirmEmailControl.hasError('required')">required</mat-error>
        <!--we don't care about the pattern on the confirming email, because the other one has it-->
        <mat-error *ngIf="confirmEmailControl.hasError('equalValue')">emails must match</mat-error>
    </mat-form-field>

    <div style="display:flex; flex-direction:column; padding-bottom:20px; padding-top:10px">
        <mat-checkbox style="flex: 1 1 0%;"  color="primary" [checked]="UsernameSameAsEmail" (change)="UsernameSameAsEmailChanged()" >My email will be my username</mat-checkbox>
        <mat-form-field style="flex: 1 1 0%;" *ngIf="!UsernameSameAsEmail" style="padding-left:2em">
            <mat-label>Username</mat-label>
            <input matInput type="text" formControlName="username" />
            <mat-error iq-aws-cognito-validator [control]="UsernameFormControl"></mat-error>
        </mat-form-field>
    </div>

    <mat-form-field style="flex: 1 1 0%;">
        <mat-label>First Name</mat-label>
        <input matInput type="text" formControlName="given_name" />
        <mat-error iqValidationMessage [control]="givenNameControl"></mat-error>

    </mat-form-field>
    <mat-form-field style="flex: 1 1 0%;">
        <mat-label>Last Name</mat-label>
        <input matInput type="text" formControlName="family_name" />
        <mat-error iqValidationMessage [control]="familyNameControl"></mat-error>
    </mat-form-field>

    <div class="mat-caption" *ngIf="PasswordRequirements != null">
        <p>Password requirements:</p>
        <ul>
            <li>Must be at least {{PasswordRequirements.MinCharacters}} characters</li>
            <li *ngIf="PasswordRequirements.RequireAnUppercaseCharacter">Must have an uppercase character</li>
            <li *ngIf="PasswordRequirements.RequireALowercaseCharacter">Must have a lowercase character</li>
            <li *ngIf="PasswordRequirements.RequireNumbers">Must have a number</li>
            <li *ngIf="PasswordRequirements.RequireASpecialCharacter">Must have a special character ($ * . ? - ! @ #
                %
                &)</li>
        </ul>
    </div>
    <iq-aws-cognito-password-input style="flex: 1 1 0%;"  [FormControl]="passwordControl"></iq-aws-cognito-password-input>

    <iq-aws-cognito-password-input style="flex: 1 1 0%;"  Placeholder="Confirm Password" [FormControl]="confirmPasswordControl" blockPaste></iq-aws-cognito-password-input>
</div>
