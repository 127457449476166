export enum TicketAttachmentTypeEnum {
    ExcavatorUpload = 0,
    TicketMap = 1,

    //  others?
    //LandlordReport = 2
}

export enum TicketAttachmentTypeEnumDescription {
    ExcavatorUpload = "Excavator Upload",
    TicketMap = "Ticket Map"
}
