import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router'

import { AuthenticationService } from '../AuthenticationService'
import { AppUpdateService } from '../AppUpdateService'
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuardService implements CanActivate {
    constructor(private authService: AuthenticationService, private router: Router, private _AppUpdateService: AppUpdateService)
    { }

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authService.IsUserAuthorized.pipe(map(val => {
            if (!val)
                return this.router.parseUrl('/login?returnUrl=' + state.url);
            else {
                //  If there is a software update available, this will activate it and navigate to the url (which will load it).
                //  In that case, this will return false to prevent this navigation but the page reload will result in the
                //  browser being on the request page (but with the updates applied).
                return !this._AppUpdateService.ActivateUpdateIfAvailable(state.url);
            }
        }));
    }
}
