/**
 *  This should always be placed after the AuthenticationGuard so that we can make sure that the user info is loaded.
 */

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router'
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PermissionsService } from 'Services/PermissionsService';

@Injectable({
    providedIn: 'root'
})
export class PermissionGuardService implements CanActivate {
    constructor(private router: Router, private permissionsService: PermissionsService)
    { }

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const observable = next.data && next.data.permission ? this.permissionsService.CurrentUserHasOneOfPermission(next.data.permission, null, true) : of(true);

        return observable.pipe(map(val => {
            if (!val) {
                this.router.navigateByUrl('/home');
                return false;
            }

            return true;
        }));
    }
}
