<div style="display:flex; flex-direction:column; overflow:hidden; flex-grow:1">
    <div class="page-header">
        <div class="header-content">
            <div class="header-title-xx" style="padding-bottom: unset; padding-right: 20px">
                Home
            </div>
        </div>
    </div>

    <div style="display: flex; flex-direction: column; margin: 0 20px; position: relative; overflow: hidden; flex-grow: 1">
        <busy-indicator [busyKey]="busyKey"></busy-indicator>
        <mat-card appearance="outlined" style="padding:inherit">
            <iq-section-group-container>
                <iq-section-group [HeaderText]="'Welcome to Exactix!'" [Edit]="false" [EditAllowed]="false">
                    <iq-section-item>
                        <div class="view margin-no-label" style="display: flex; padding-top:20px; flex-wrap:wrap; column-gap:5em; row-gap:20px">
                            <ng-container *iqHasPermission="TicketViewPermission hasAnywhere true">
                                <iq-icon-button *ngIf="DeviceDetectorService.IsPhone" icon="Search" routerLink="/tickets/dashboard">Ticket Dashboard</iq-icon-button>
                                <iq-icon-button *ngIf="!DeviceDetectorService.IsPhone" icon="Search" routerLink="/tickets/dashboard">Go to Ticket Dashboard</iq-icon-button>
                            </ng-container>
                            <ng-container *ngIf="ShowNewTicketButton">
                                <iq-icon-button icon="New" routerLink="/tickets/new" *iqHasPermission="TicketCreatePermission hasAnywhere true">New Ticket</iq-icon-button>
                            </ng-container>
                            <iq-icon-button *ngIf="ShowEmergencyContactsPage" icon="View" routerLink="/emergencyContacts">View Emergency Contacts</iq-icon-button>
                        </div>
                    </iq-section-item>
                </iq-section-group>
            </iq-section-group-container>
        </mat-card>
        <mat-card appearance="outlined" *ngIf="showAnnouncements" style="overflow:hidden; flex-grow:1; display:flex; flex-direction:column">
            <iq-announcements-carousel style="overflow:hidden; flex-grow:1; display:flex; flex-direction: column"></iq-announcements-carousel>
        </mat-card>
    </div>
</div>

