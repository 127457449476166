import { OLGeometryTypeEnum } from "Enums/GeometryType.enum";
import { Collection, Feature } from "ol";
import { Coordinate } from "ol/coordinate";
import { EventsKey } from "ol/events";
import { click, pointerMove, singleClick } from "ol/events/condition";
import { Circle as ol_geom_Circle, Geometry, Point } from "ol/geom";
import { Interaction, Modify, Select, Translate } from "ol/interaction";
import { ModifyEvent } from "ol/interaction/Modify";
import { Layer, Vector as ol_layer_Vector } from 'ol/layer';
import Map from 'ol/Map';
import { unByKey } from "ol/Observable";
import { Vector as ol_source_Vector } from 'ol/source';
import { Fill, Style, Text } from "ol/style";
import { takeUntil } from "rxjs";
import { UndoEditButton } from "Shared/Components/Maps/Controls/UndoEditButton";
import { GeometryUtils } from "Shared/Components/Maps/GeometryUtils";
import { VectorLayerBase } from "Shared/Components/Maps/Layers/VectorLayerBase";
import { MapConstants } from "Shared/Components/Maps/MapConstants";
import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { DrawingToolBase } from "Shared/Components/Maps/Tools/DrawingToolBase";

//  Links:
//      Modify test: https://openlayers.org/en/latest/examples/modify-test.html
//      OpenLayers Draw & Modify example: https://openlayers.org/en/latest/examples/draw-and-modify-features.html
//      Custom Interactions: https://openlayers.org/en/latest/examples/custom-interactions.html

export class EditGeometryTool extends DrawingToolBase {

    private _SelectVectorLayerInteraction: Select;
    private _HoverEditLayerInteraction: Select;
    private _SelectEditLayerInteraction: Select;
    private _ModifyInteraction: Modify;
    private _TranslateInteraction: Translate;

    private _DraggableFeatureCollection: Collection<Feature<any>>;

    private _OverlayLayer = new ol_layer_Vector;
    private _OverlayFeatures: Collection<Feature<any>>;

    private _GeometryChangedEventKey: EventsKey = null;

    /**
     * Text used at the end of the hover text when editing a feature to tell user how to end editing
     * (save or append/cut, etc).
     */
    public SaveInstructions: string = 'click Save if finished editing';

    constructor(map: Map, mapToolService: MapToolService, private _VectorLayer: VectorLayerBase,
        private _LayerNameBeingEdited: string, private _UnbufferedLayerName: string, private _UndoEditButton: UndoEditButton)
    {
        super(map, mapToolService);

        this.Init();
        this.CreateOverlayLayer();
    }

    public OnDestroy(): any {
        if (this._GeometryChangedEventKey)
            unByKey(this._GeometryChangedEventKey);

        this._OverlayFeatures.clear();
        this._OverlayFeatures = null;

        this._OverlayLayer.setMap(null);
        this._OverlayLayer.setStyle(null);
        this._OverlayLayer = null;

        this._UndoEditButton = null;
        this._VectorLayer = null;

        return super.OnDestroy();
    }

    protected Init(): void {
        super.Init();

        this.MapToolService.ActivateTool
            .pipe(takeUntil(this.Destroyed))
            .subscribe(toolName => {
                if ((toolName === MapConstants.TOOL_EDIT_GEOMETRY) && !this.Interaction.getActive())
                    this.Interaction.setActive(true);
            });
    }

    protected CreateInteraction(): Interaction {
        let modifyingFeature = false;
        //  Selects features by hovering over them.  We style the features to indicate that it's editable.
        //  And we also catch "select" notifications on it so that we can check to see if it's also draggable
        //  (by the Translate interaction).
        this._SelectVectorLayerInteraction = new Select({
            layers: [this._VectorLayer.Layer],
            //  Must do this (instead of just setting condition = "pointerMove" built-in function)
            //  or it causes issues when dragging verticies.  Especially circles.  To reproduce that,
            //  just change this method to always return true, draw a circle, then try to drag a verticie.
            //  The performance is so bad, it loses track of the vertice and stops dragging the feature.
            //  But changing this intersection condition to be ignored when dragging fixes it!
            condition: () => !modifyingFeature,
            style: (feature, resolution) => this._VectorLayer.BuildStyleForFeature(feature, true, this._VectorLayer.DetermineFeatureStyleOfFeature(feature), resolution),
            hitTolerance: 20
        });
        this.AddListener(this._SelectVectorLayerInteraction.on("select", evt => this.OnFeatureHover(evt)));

        if (this._LayerNameBeingEdited) {
            //  This allows highlighting and then selecting (via click) a feature from the layer we are editing (i.e. the actual Dig Site layer).
            //  It's only enabled when we don't have another feature loaded into _VectorLayer.
            const editLayer = this.FindLayerName(this._LayerNameBeingEdited);
            if (editLayer) {
                this._HoverEditLayerInteraction = new Select({
                    layers: [editLayer],
                    condition: pointerMove,
                    hitTolerance: 20
                });
                this.Map.addInteraction(this._HoverEditLayerInteraction);

                this._SelectEditLayerInteraction = new Select({
                    layers: [editLayer],
                    condition: click,
                    hitTolerance: 20,
                });
                this.Map.addInteraction(this._SelectEditLayerInteraction);
                this.AddListener(this._SelectEditLayerInteraction.on("select", evt => this.OnSelectEditLayerFeature(evt)));
            }
        }

        this._ModifyInteraction = new Modify({
            source: this._VectorLayer.Layer.getSource(),
            deleteCondition: evt => (singleClick(evt) && (evt.originalEvent as KeyboardEvent).shiftKey)
        });
        this.Map.addInteraction(this._ModifyInteraction);

        let geomBeingEdited: Geometry;
        this.AddListener(this._ModifyInteraction.on("modifystart", (evt: ModifyEvent) => {
            modifyingFeature = true;
            //  In order to validate the geometry as it's edited, we need to watch for changes to it.
            //  We can then validate it which will update it if necessary (to restrict to configured buffers, etc).
            //  ** Also, watch this issue: https://github.com/openlayers/openlayers/issues/5095
            //  It has been suggested to add "geometryFunction" to the Modify interaction so that it works like Draw.
            const feature = evt.features.item(0) as Feature<Geometry>;
            geomBeingEdited = feature.getGeometry();
            if (geomBeingEdited) {
                this._GeometryChangedEventKey = geomBeingEdited.on("change", e => {
                    //  Validate that geometry.  i.e. If this is a circle, will make sure the radius is >= the configured buffer.
                    //  The Modify interaction does not give any way (that I could find) to handle these changes as you're drawing...
                    if (this.ValidateDrawnGeometry(e.target as Geometry))
                        feature.changed();
                });
            }
        }));
        this.AddListener(this._ModifyInteraction.on("modifyend", (evt: ModifyEvent) => {
            //  Unregister the geometry changed event handler we created in modifystart
            if (this._GeometryChangedEventKey) {
                unByKey(this._GeometryChangedEventKey);     //  Unregisters event (no matter what it is attached to): https://gis.stackexchange.com/questions/241487/how-to-unlisten-an-event-in-openlayers-4
                this._GeometryChangedEventKey = null;
            }

            this.OnModifyFeatureEnd(evt);
            modifyingFeature = false;
        }));

        this._DraggableFeatureCollection = new Collection();
        this._TranslateInteraction = new Translate({
            features: this._DraggableFeatureCollection
        });
        this.Map.addInteraction(this._TranslateInteraction);
        if (this._UndoEditButton)
            this.AddListener(this._TranslateInteraction.on("translateend", () => this._UndoEditButton.Snapshot()));

        //  The Select Interaction is used as the "main" interaction.  The control bar will change the active
        //  state of this.  We catch those changes and then trigger changing the other interactions in response.
        return this._SelectVectorLayerInteraction;
    }

    private FindLayerName(layerName: string): Layer<any> {
        let layer: Layer<any> = null;
        this.Map.getLayers().forEach(l => {
            const name = l.get("name");
            if (name === layerName)
                layer = l as Layer<any>;
        });

        return layer;
    }

    private CreateOverlayLayer(): void {
        this._OverlayFeatures = new Collection();

        this._OverlayLayer = new ol_layer_Vector({
            source: new ol_source_Vector({
                //name: "_EditGeometryTool_Overlay",
                useSpatialIndex: false,
                features: this._OverlayFeatures
            }),
            style: new Style({
                text: new Text({
                    text: '\uf0b2',     //  fas fa-arrows-alt
                    font: '900 32px "' + MapConstants.FONT_AWESOME_FREE_NAME + '"',     //  If doesn't work, see comments on this constant - package version probably changed!
                    textBaseline: 'bottom',
                    fill: new Fill({
                        color: 'red'
                    }),
                    offsetY: 16         //  Icon height is 32 so this should position it exactly on center
                })
            }),
            updateWhileAnimating: true,
            updateWhileInteracting: true
        });

        this.Map.addLayer(this._OverlayLayer);
    }

    protected OnActiveStateChanged(isActive: boolean): void {
        super.OnActiveStateChanged(isActive);

        //  Must clear out the select interactions (and grabber features) when deactivating.  Otherwise, if something
        //  is selected in them when they are deactivated, those features remain (and they stay styled).  Can reproduce
        //  this by editing something and zooming such that the toolbar is within the geometry being edited - click
        //  on something that causes this tool to be deactivated.  Feature is now stuck and styled.
        if (!isActive) {
            this._SelectVectorLayerInteraction.getFeatures().clear();
            this._OverlayFeatures.clear();
        }

        if (this._HoverEditLayerInteraction) {
            if (!isActive)
                this._HoverEditLayerInteraction.getFeatures().clear();

            //  If the _VectorLayer layer (the layer containing the geometry we are currently editing) is empty,
            //  we can enable picking from the layer we are editing (i.e. the actual dig site layer).
            const noFeaturesToEdit: boolean = (this._VectorLayer.Layer.getSource().getFeatures().length === 0);

            this._HoverEditLayerInteraction.setActive(isActive && noFeaturesToEdit);
            this._SelectEditLayerInteraction.setActive(isActive && noFeaturesToEdit);
        }

        this._ModifyInteraction.setActive(isActive);
        this._TranslateInteraction.setActive(isActive);
    }

    private OnFeatureHover(selectEvent: any /*SelectEvent*/): void {
        this._OverlayFeatures.clear();

        if (!selectEvent?.selected)
            return;

        selectEvent.selected.forEach(feature => {
            this.AddGrabberFeatureForFeature(feature);
        });
    }

    private OnSelectEditLayerFeature(selectEvent: any /*SelectEvent*/): void {
        if (!selectEvent?.selected || (selectEvent.selected.length === 0))
            return;

        const t = selectEvent.selected.pop();
        const selectedFeature = t.clone();
        selectedFeature.setId(t.getId());       //  Clone does not clone the id!  This is used to find the original feature if needed (i.e. to delete it after it's been selected into the edit layer)

        let addedFeatures: boolean = false;

        if (this._UnbufferedLayerName) {
            //  Directly add the unbuffered feature (b/c CleanAndAddFeature() will buffer line segments!)
            //  Find unbuffered features that fall inside the feature(s) in the selectEvent.
            const unbufferedLayer = this.FindLayerName(this._UnbufferedLayerName);
            if (unbufferedLayer) {
                const geomType = GeometryUtils.GeometryTypeOfFeatures(unbufferedLayer.getSource().getFeatures());

                switch (geomType) {
                    case OLGeometryTypeEnum.LineString:
                    case OLGeometryTypeEnum.MultiLineString:
                    case OLGeometryTypeEnum.Polygon:
                    case OLGeometryTypeEnum.MultiPolygon: {
                        const selectedGeom = GeometryUtils.OpenLayersGeometryToJstsGeometry(selectedFeature.getGeometry(), null);     //  no MapToolService so that it doesn't buffer

                        unbufferedLayer.getSource().getFeatures().forEach(feature => {
                            const geom = GeometryUtils.OpenLayersGeometryToJstsGeometry(feature.getGeometry(), null);     //  no MapToolService so that it doesn't buffer
                            if (selectedGeom.contains(geom)) {
                                this._VectorLayer.CleanAndAddFeature(feature, false);
                                addedFeatures = true;
                            }
                        });
                        break;
                    }
                }
            }
        }

        if (!addedFeatures)
            this._VectorLayer.CleanAndAddFeature(selectedFeature, true);

        this._HoverEditLayerInteraction.getFeatures().clear();
        this._SelectEditLayerInteraction.getFeatures().clear();
        this._HoverEditLayerInteraction.setActive(false);
        this._SelectEditLayerInteraction.setActive(false);
    }

    private AddGrabberFeatureForFeature(feature: Feature<any>): void {
        if (!feature)
            return;

        let coord: Coordinate;

        const olGeom = feature.getGeometry();
        if (olGeom.getType() === "Circle") {
            const circle = olGeom as ol_geom_Circle;
            const center = circle.getCenter();
            coord = [center[0], center[1]];
        }
        else {
            const geom = GeometryUtils.OpenLayersGeometryToJstsGeometry(olGeom, this.MapToolService);
            if (!geom)
                return;     //  Not sure how, but have gotten javascript errors about this being undefined...

            let point = geom.getCentroid();
            if (!point.within(geom))
                point = geom.getInteriorPoint();        //  centroid is not necessarily within the polygon
            coord = [point.getX(), point.getY()];
        }

        const grabberFeature = new Feature<any>();
        grabberFeature.setGeometry(new Point(coord));
        grabberFeature.set("linkedFeature", feature);       //  So that we can tell which feature the grabber is for when we mouse over it

        this._OverlayFeatures.push(grabberFeature);
    }

    private OnModifyFeatureEnd(modifyEvent: ModifyEvent): void {
        if (!modifyEvent?.features)
            return;

        //  After a feature has been modified, recalculate the grabber point - it may not be valid any more.
        modifyEvent.features.forEach(feature => {
            let grabberFeature: Feature<any> = null;
            this._OverlayFeatures.forEach(f => {
                if (f.get("linkedFeature") === feature)
                    grabberFeature = f;
            });

            if (grabberFeature) {
                this._OverlayFeatures.remove(grabberFeature);
                this.AddGrabberFeatureForFeature(feature as Feature<Geometry>);
            }
        });

        if (this._UndoEditButton)
            this._UndoEditButton.Snapshot();
    }

    protected OnPointerMove(evt: any): void {
        if (evt.dragging) {
            this.MoveHelpMessage(evt.coordinate);
            return;
        }

        this._DraggableFeatureCollection.clear();
        this.Map.forEachFeatureAtPixel(evt.pixel,
            (feature) => {
                const linkedFeature = feature.get("linkedFeature");
                if (linkedFeature) {
                    this._DraggableFeatureCollection.push(linkedFeature as Feature<any>);
                    this._DraggableFeatureCollection.push(feature as Feature<any>);
                }
            }, {
                layerFilter: layer => { return layer === this._OverlayLayer },
                hitTolerance: 20
            });

        let helpMsg: string;
        if (this._DraggableFeatureCollection.getLength() > 0)
            helpMsg = "Click and drag to reposition";
        else {
            const selectedFeatures = this._SelectVectorLayerInteraction.getFeatures();

            if (selectedFeatures.getLength() === 0) {
                if (this._VectorLayer.Layer.getSource().getFeatures().length > 0)
                    helpMsg = "Move mouse to feature to edit</br>or " + this.SaveInstructions;
                else {
                    if (this._HoverEditLayerInteraction.getFeatures().getLength() > 0)
                        helpMsg = "Click to edit this feature";
                    else
                        helpMsg = "Click on the feature you wish to edit";
                }
            }
            else {
                //  Mouse is in a feature and not on the grabber.  Check to see if it's within the tolerance of a vertex or a linesegment.
                //  HACK: This is accessing internal properties of the ol.interaction.Modify class.
                //  It does all of the calculations for the vertex snapping and line intersection tests but does not expose the results.
                //  Accessing them anyway to avoid having to repeat all of that work.  This will break if those internal properties
                //  change but the worst thing that will happen is that we don't show the detailed help messages.
                //  If we need to do this ourself, we can use jsts to do it(with much less code than OpenLayers).
                //  Code for the Modify interaction is here: https://github.com/openlayers/openlayers/blob/master/src/ol/interaction/Modify.js
                const i: any = this._ModifyInteraction;
                if (i.snappedToVertex_)
                    helpMsg = "Click and drag to move vertex,</br>shift click to delete";
                else if (i.vertexFeature_)
                    helpMsg = "Click and drag to add new vertex";
                else
                    helpMsg = "Move mouse to edge of feature to</br>move, add, or delete verticies";
            }
        }

        this.SetHelpMessage(helpMsg, evt.coordinate);
    }
}
