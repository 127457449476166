import { Component, OnDestroy } from '@angular/core';
import { RoleTypeEnum } from 'Enums/RolesAndPermissions/RoleType.enum';
import { TicketMapViewerService } from 'Pages/Tickets/TicketMapViewer/Services/TicketMapViewer.service';
import { CommonService } from 'Services/CommonService';
import { BaseTicketMapViewerComponent } from './BaseTicketMapViewer.component';

/**
 *  Ticket Map used by an Excavator user ticket dashboard
 */
@Component({
    templateUrl: './BaseTicketMapViewer.component.html',
    styleUrls: ['./BaseTicketMapViewer.component.scss'],
    providers: [
        CommonService,
        { provide: "IMapViewerService", useClass: TicketMapViewerService }  //  So can be injected in the TicketMapItemPager.component
    ]
})
export class ExcavatorTicketMapViewerComponent extends BaseTicketMapViewerComponent implements OnDestroy {

    protected override get RoleType(): RoleTypeEnum { return RoleTypeEnum.Excavator; }

    constructor(commonService: CommonService, ticketMapViewerService: TicketMapViewerService) {
        super(commonService, ticketMapViewerService);
    }
}
