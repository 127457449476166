import {
    animate,
    keyframes,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

/**
 *  Custom Toastr component.  Started with the PinkToast found here: https://github.com/scttcper/ngx-toastr/blob/master/src/app/pink.toast.ts
 * */
@Component({
    selector: '[iq-custom-toast-component]',     //  Even though this is not referenced, it's required - will not display correctly without it!
    styles: [`
    :host {
      position: relative;
      overflow: hidden;
      margin: 0 0 6px;
      padding: 10px 10px 10px 10px;
      width: 400px !important;
      border-radius: 3px 3px 3px 3px;
      pointer-events: all;
      cursor: pointer;
    }
    .row {
        display: flex;
    }
  `],
    template: `
  <div class="row" [style.display]="state.value === 'inactive' ? 'none' : ''">
    <div style="flex-grow:1">
      <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
        {{ title }}
      </div>
      <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
        [class]="options.messageClass" [innerHTML]="message">
      </div>
      <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
        [class]="options.messageClass" [attr.aria-label]="message">
        {{ message }}
      </div>
    </div>
    <div style="align-self:center">
        <iq-icon-button button-class="Button" (click)="remove()">Close</iq-icon-button>
        <!--<iq-icon-button button-class="Button-Small" icon="Close" (click)="remove()">Close</iq-icon-button>-->
    </div>
  </div>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
  <div *ngIf="options.disableTimeOut" style="text-align:end; font-size:small">
    (click to dismiss)
  </div>
  `,
    animations: [
        trigger('flyInOut', [
            state('inactive', style({
                opacity: 0,
            })),
            transition('inactive => active', animate('400ms ease-out', keyframes([
                style({
                    transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
                    opacity: 0,
                }),
                style({
                    transform: 'skewX(20deg)',
                    opacity: 1,
                }),
                style({
                    transform: 'skewX(-5deg)',
                    opacity: 1,
                }),
                style({
                    transform: 'none',
                    opacity: 1,
                }),
            ]))),
            transition('active => removed', animate('400ms ease-out', keyframes([
                style({
                    opacity: 1,
                }),
                style({
                    transform: 'translate3d(100%, 0, 0) skewX(30deg)',
                    opacity: 0,
                }),
            ]))),
        ]),
    ],
    preserveWhitespaces: false,
})
export class FourIQCustomToast extends Toast {
    // constructor is only necessary when not using AoT
    constructor(protected toastrService: ToastrService,public toastPackage: ToastPackage) {
        super(toastrService, toastPackage);
    }
}
