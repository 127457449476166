<div style="height: 100%; display: flex; flex-direction: column;" fullscreen [class.example-is-mobile]="hideSideNav">
    <!-- when hiding the top menu, also need to hide this busy indicator - it has the primary color for a background... -->
    <general-busy-indicator [hidden]="HideTopMenuBar"></general-busy-indicator>
    <mat-toolbar *ngIf="!HideTopMenuBar" color="primary" class="iq-display-1">
        <mat-toolbar-row [style.padding]="isMobileSize ? 0 : null">
            <!--  Show a close/open button for the nav menu on mobile devices  *ngIf="hideSideNav" -->
            <button mat-icon-button *ngIf="!forceHideSideNav" (click)="toggleSideNav()" matTooltip="Main Menu">
                <mat-icon>menu</mat-icon>
            </button>
            <!-- width:180px -->
            <!-- width:110px -->
            <img *ngIf="!isMobileSize && OccLogoImage" src="./assets/Images/Logos/{{OccLogoImage}}" [ngStyle]="{'height': OccLogoHeight}" style="padding: 5px 0 0 5px; z-index: 10" alt="{{OccLogoImage}}" class="ng-star-inserted">
            <img *ngIf="isMobileSize && OccLogoImage" src="./assets/Images/Logos/{{OccLogoImage}}" [ngStyle]="{'height': OccMobileLogoHeight}" style="padding: 5px 0 0 5px; z-index: 10" alt="{{OccLogoImage}}">

            <ng-container *ngIf="CanSwitchServer">
                <ng-container *ngIf="LinkedServers.length > 2; else twoServers">
                    <button mat-button [matMenuTriggerFor]="serverMenu" style="color:white">
                        Switch to...
                        <mat-icon iconPositionEnd>expand_more</mat-icon>  <!-- icon list: https://material.io/resources/icons/?style=baseline -->
                    </button>
                    <mat-menu #serverMenu="matMenu">
                        <button mat-menu-item *ngFor="let server of LinkedServers" [disabled]="CurrentServerCode === server.Code" (click)="SwitchServer(server)" style="display:flex">
                            <span *ngIf="CurrentServerCode === server.Code" style="flex: 0 1 auto">
                                <mat-icon style="margin-right: 10px; vertical-align: middle;">check</mat-icon>
                            </span>
                            <span style="flex:0 0 auto">
                                {{server.Name}}
                            </span>
                        </button>
                    </mat-menu>
                </ng-container>
                <ng-template #twoServers>
                    <button mat-button (click)="SwitchServer(OtherServer)">
                        Switch to {{OtherServer.Name}}
                    </button>
                </ng-template>
            </ng-container>

            <div *ngIf="IsUnsupportedEdgeBrowser" style="color:red; flex-grow:10; display:flex; flex-direction:column; align-items:center">
                <div>
                    You are using an <a target="_blank" href="https://techcommunity.microsoft.com/t5/microsoft-365-blog/new-microsoft-edge-to-replace-microsoft-edge-legacy-with-april-s/ba-p/2114224">unsupported</a> version of MS Edge.
                </div>
                <div>
                    Please <a target="_blank" href="https://www.microsoft.com/en-us/edge">upgrade</a> or use <a target="_blank" href="https://www.google.com/chrome/">Google Chrome</a> for the best experience.
                </div>
            </div>

            <div style="display: flex; flex-grow: 1; justify-content: flex-end;">
                <div *ngIf="(SignedIn | async)">
                    <span *ngIf="!!name">{{ name }}</span>
                    <button mat-icon-button toggleFullscreen title="Toggle fullscreen">
                        <mat-icon *ngIf="!isFullScreen">fullscreen</mat-icon>
                        <mat-icon *ngIf="isFullScreen">fullscreen_exit</mat-icon>
                    </button>

                    <button mat-icon-button [matMenuTriggerFor]="userMenu" aria-describedby="User Actions" title="User Actions">
                        <mat-icon>person</mat-icon>
                    </button>
                    <mat-menu #userMenu="matMenu" class="iq-user-menu">
                        <ng-template matMenuContent>
                            <button mat-menu-item (click)="SignOut()">
                                Sign out
                            </button>
                            <button *ngIf="personID" mat-menu-item routerLink="/people/details/{{personID}}">
                                My Profile
                            </button>
                            <button *ngIf="canChangePassword" mat-menu-item (click)="ChangePassword()">
                                Change Password
                            </button>
                        </ng-template>
                    </mat-menu>
                </div>

                <button mat-icon-button [matMenuTriggerFor]="colorMenu" aria-describedby="Change Color" title="Change Color">
                    <mat-icon>format_color_fill</mat-icon>
                </button>
                <mat-menu #colorMenu="matMenu" class="iq-palette-picker">
                    <mat-grid-list cols="2">
                        <mat-grid-tile class="color-palette-green" (click)="SetColorPalette('green')">
                            <div class="primary-background" style="width:100%; height:100%; border-radius: 30px"></div>
                        </mat-grid-tile>
                        <mat-grid-tile class="color-palette-blue" (click)="SetColorPalette('blue')">
                            <div class="primary-background" style="width:100%; height:100%; border-radius: 30px"></div>
                        </mat-grid-tile>
                        <mat-grid-tile class="color-palette-gray" (click)="SetColorPalette('gray')">
                            <div class="primary-background" style="width:100%; height:100%; border-radius: 30px"></div>
                        </mat-grid-tile>
                        <mat-grid-tile class="color-palette-irth" (click)="SetColorPalette('irth')">
                            <div class="primary-background" style="width:100%; height:100%; border-radius: 30px"></div>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-menu>

                <button mat-icon-button [matMenuTriggerFor]="fontSizeMenu" aria-describedby="Change Font Size" title="Change Font Size">
                    <mat-icon>format_size</mat-icon>
                </button>
                <mat-menu #fontSizeMenu="matMenu" class="iq-typography-picker">
                    <button mat-menu-item (click)="SetFontSize('small')" class="iq-typography-small">
                        <div [ngClass]="{'iq-display-1': FontSize!=='small', 'iq-subheading-2':FontSize==='small'}">
                            <mat-icon>format_size</mat-icon>
                            <span>Small</span>
                            <mat-icon *ngIf="FontSize==='small'">check</mat-icon>
                        </div>
                    </button>
                    <button mat-menu-item (click)="SetFontSize(null)" class="iq-typography-default">
                        <div [ngClass]="{'iq-display-1': FontSize!=null, 'iq-subheading-2':FontSize==null}">
                            <mat-icon>format_size</mat-icon>
                            <span>Medium</span>
                            <mat-icon *ngIf="FontSize==null">check</mat-icon>
                        </div>
                    </button>
                    <button mat-menu-item (click)="SetFontSize('large')" class="iq-typography-large">
                        <div [ngClass]="{'iq-display-1': FontSize!=='large', 'iq-subheading-2':FontSize==='large'}">
                            <mat-icon>format_size</mat-icon>
                            <span>Large</span>
                            <mat-icon *ngIf="FontSize==='large'">check</mat-icon>
                        </div>
                    </button>
                </mat-menu>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-sidenav-container [hasBackdrop]="hideSideNav">
        <mat-sidenav #sideNavMenu  [@menuResize]="showMenuText || menuHasMouseOver ? 'enter' : 'exit'" [mode]="sideNavMode"
                     [fixedInViewport]="true" fixedTopGap="50" (mouseover)="toggleMouseOver()" (mouseout)="toggleMouseOut()">
            <div style="display: flex; flex-direction: column; justify-content: space-between; height:100%; overflow-x:hidden; overflow-y:auto">
                <mat-nav-list style="padding-top:0px">
                    <a routerLinkActive="active-link" mat-list-item routerLink="/home" *ngIf="SignedIn | async" (click)="menuItemClicked()">
                        <fa-icon [icon]="HomeIcon" class="menu-icon"></fa-icon>
                        <span *ngIf="showMenuText || menuHasMouseOver">Home</span>
                    </a>
                    <a routerLinkActive="active-link" mat-list-item routerLink="/tickets" *iqHasPermission="TicketViewPermission hasAnywhere true" (click)="menuItemClicked()">
                        <fa-icon [icon]="TicketIcon" class="menu-icon"></fa-icon>
                        <span *ngIf="showMenuText || menuHasMouseOver">Tickets</span>
                    </a>
                    <ng-container *ngIf="ProjectsName">
                        <a routerLinkActive="active-link" mat-list-item routerLink="/projects" *iqHasPermission="ProjectViewPermission hasAnywhere true" (click)="menuItemClicked()">
                            <fa-icon [icon]="ProjectIcon" class="fas menu-icon"></fa-icon>
                            <span *ngIf="showMenuText || menuHasMouseOver">{{ProjectsName}}</span>
                        </a>
                    </ng-container>
                    <a routerLinkActive="active-link" mat-list-item routerLink="/excavator" *iqHasPermission="ExcavatorsViewPermission hasAnywhere true" (click)="menuItemClicked()">
                        <fa-icon [icon]="excavtorIcon" class="menu-icon"></fa-icon>
                        <span *ngIf="showMenuText || menuHasMouseOver">Excavators</span>
                    </a>
                    <a routerLinkActive="active-link" mat-list-item routerLink="/member" *iqHasPermission="MembersViewPermission hasAnywhere true" (click)="menuItemClicked()">
                        <fa-icon [icon]="memberIcon" class="menu-icon"></fa-icon>
                        <span *ngIf="showMenuText || menuHasMouseOver">Members</span>
                    </a>
                    <a routerLinkActive="active-link" mat-list-item routerLink="/servicearea" *iqHasPermission="ServiceAreaViewPermission hasAnywhere true" (click)="menuItemClicked()">
                        <fa-icon [icon]="serviceAreaIcon" class="menu-icon"></fa-icon>
                        <span *ngIf="showMenuText || menuHasMouseOver">Service Areas</span>
                    </a>
                    <a routerLinkActive="active-link" mat-list-item routerLink="/serviceprovider" *iqHasPermission="ServiceProviderViewPermission hasAnywhere true" (click)="menuItemClicked()">
                        <fa-icon [icon]="serviceProviderIcon" class="menu-icon"></fa-icon>
                        <span *ngIf="showMenuText || menuHasMouseOver">Service Providers</span>
                    </a>
                    <a routerLinkActive="active-link" mat-list-item routerLink="/destination" *iqHasPermission="DestinationViewPermission hasAnywhere true" (click)="menuItemClicked()">
                        <fa-icon [icon]="destinationIcon" class="menu-icon"></fa-icon>
                        <span *ngIf="showMenuText || menuHasMouseOver">Destinations</span>
                    </a>
                    <ng-container *ngIf="personID || IsSupportUser">
                        <a routerLinkActive="active-link" mat-list-item routerLink="/people" *iqHasPermission="PeopleViewPermission not [personID] hasAnywhere true" (click)="menuItemClicked()">
                            <fa-icon [icon]="peopleIcon" class="menu-icon"></fa-icon>
                            <span *ngIf="showMenuText || menuHasMouseOver">People</span>
                        </a>
                    </ng-container>
                    <a routerLinkActive="active-link" mat-list-item routerLink="/administration" *iqHasPermission="AdminViewPermission" (click)="menuItemClicked()">
                        <fa-icon [icon]="adminIcon" class="menu-icon"></fa-icon>
                        <span *ngIf="showMenuText || menuHasMouseOver">Administration</span>
                    </a>
                    <a routerLinkActive="active-link" mat-list-item routerLink="/role" *iqHasPermission="RoleViewPermission" (click)="menuItemClicked()">
                        <fa-icon [icon]="roleIcon" class="menu-icon"></fa-icon>
                        <span *ngIf="showMenuText || menuHasMouseOver">Roles</span>
                    </a>
                    <a routerLinkActive="active-link" mat-list-item routerLink="/communications" *iqHasPermission="CommunicationsViewPermission" (click)="menuItemClicked()">
                        <fa-icon [icon]="commIcon" class="menu-icon"></fa-icon>
                        <span *ngIf="showMenuText || menuHasMouseOver">Communications</span>
                    </a>
                    <a routerLinkActive="active-link" mat-list-item routerLink="/reports" *ngIf="HaveReports" (click)="menuItemClicked()">
                        <fa-icon [icon]="reportIcon" class="menu-icon"></fa-icon>
                        <span *ngIf="showMenuText || menuHasMouseOver">Reports</span>
                    </a>
                    <a routerLinkActive="active-link" mat-list-item routerLink="/system" *iqHasPermission="SystemStatusViewPermission or [SystemConfigurationViewPermission]" (click)="menuItemClicked()">
                        <fa-icon [icon]="systemAdminIcon" class="menu-icon"></fa-icon>
                        <span *ngIf="showMenuText || menuHasMouseOver">System</span>
                    </a>

                    <a routerLinkActive="active-link" mat-list-item routerLink="/alerts" *iqHasPermission="AdminViewPermission" (click)="menuItemClicked()">
                        <fa-icon [icon]="alertsIcon" class="menu-icon"></fa-icon>
                        <span *ngIf="showMenuText || menuHasMouseOver">Alerts</span>
                    </a>
                </mat-nav-list>
                <div style="padding-bottom:10px; padding-left:10px; font-size:small">
                    <div>Exactix v{{AppUpdateService.CurrentVersion}}</div>
                    <div style="padding-left:2em; font-size:x-small">by Irth Solutions</div>
                    <div *ngIf="AppUpdateService.UpdateIsAvailable" style="color:red" class="link" (click)="AppUpdateService.ActivateUpdateNow()">Update Available!</div>
                </div>
            </div>
        </mat-sidenav>

        <mat-sidenav-content [@mainContentResize]="forceHideSideNav || isMobileSize ? 'void' : (showMenuText ? 'enter' : 'exit')"
                             style="height:100%; display:flex; flex-direction:column; overflow:hidden">
            <div class="app-content" style="display:flex; flex-direction:column;">
                <router-outlet></router-outlet>
                <!-- Changed dashboard viewer to not use this because it leaves the iframe active and never cleans it up - so endlessly refreshes -->
                <!--<iframe id="DashboardIFrame" style="width:100%; height:100%; display:none" frameborder="0"></iframe>-->
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

<!--Used as the outlet for things we build print pages for-->
<!--<router-outlet name='print'></router-outlet>-->
