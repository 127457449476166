<!-- using button element so that it maintains browser accessibility features -->
<!-- a link should use an 'a' element. -->
<!-- must set type="button" or when used inside a <form>, it will be assumed to be a submit button and will
    be triggered if ENTER is pressed! -->
<button #button type="button" tabindex="0" class="icon-button" [ngClass]="ComponentClasses" [title]="Title"
        iqPreventDoubleClick [DebounceTime]="DebounceTime" (DebounceClick)="OnDebouncedClick($event)" (keydown)="OnKeydown($event)">
    <!-- Use stacked icons like this to make the circle border: https://markheath.net/post/font-awesome-circle-background-->
    <fa-stack *ngIf="Icon" [ngStyle]="StackStyles">
        <fa-icon [icon]="CircleIcon" stackItemSize="2x" style="font-size: 0.5em" [styles]="{'color': 'white'}"></fa-icon>
        <fa-icon [icon]="Icon" stackItemSize="1x" [classes]="IconClasses" [styles]="IconStyles"></fa-icon>
    </fa-stack>

    <div class="content">
        <ng-content></ng-content>
    </div>
</button>
