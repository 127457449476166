import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { Ticket } from 'Models/Tickets/Ticket.model';
import { TicketEntryAllowedTicketActions } from 'Models/Tickets/TicketEntryAllowedTicketActions';
import { TicketResponseHistoryItem } from 'Models/Tickets/TicketResponseHistoryItem.model';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { CommonService } from 'Services/CommonService';
import { PermissionsService } from 'Services/PermissionsService';
import { TicketResponseService } from '../../../Services/TicketResponse.service';
import { AddPositiveResponseData } from '../../AddPositiveResponse/AddPositiveResponse.component';
import { AddPositiveResponseDialog } from '../../AddPositiveResponse/Dialog/AddPositiveResponseDialog.component';
import { TicketResponseDiscussionListComponent } from '../TicketResponseDiscussionList/TicketResponseDiscussionList.component';
import { DownloadService } from 'Services/Download.service';
import { EnumHelpers } from 'Shared/Utils/Enums';
import { ResponseEntryMethodEnum, ResponseEntryMethodEnumDescriptions } from 'Enums/ResponseEntryMethod.enum';
import { formatDate } from '@angular/common';

@Component({
    selector: 'ticket-response-history-list',
    templateUrl: './TicketResponseHistoryList.component.html',
    styleUrls: ['./TicketResponseHistoryList.component.scss']
})
export class TicketResponseHistoryListComponent implements OnDestroy, OnInit {

    //  If this is null, entering responses and changing/viewing the Work Complete will be disabled
    @Input()
    public AllowedActions: TicketEntryAllowedTicketActions;

    private _Ticket: Ticket | any;       //  Ticket or a ticket list item
    @Input()
    get Ticket(): Ticket | any { return this._Ticket; }
    set Ticket(value: Ticket | any) {
        this._Ticket = value;
        if (value)
            this.Refresh();
    }

    private _ServiceAreaID: string = null;
    @Input()
    get ServiceAreaID(): string { return this._ServiceAreaID; }
    set ServiceAreaID(value: string) {
        this._ServiceAreaID = value;
        this.Refresh();
    }

    /**
     *  If set to true, when this component initializes, it will automatically prompt the user for any ticket discussions
     *  that are pending their response.
     *  Requires that the Ticket is set to a valid ticket.
     */
    @Input()
    public CheckForRequiredDiscussionResponses: boolean = false;

    private _FetchResponseHistoryEvent: Subject<void> = new Subject();

    public ResponseHistory: TicketResponseHistoryItem[];
    public FilteredResponseHistory: TicketResponseHistoryItem[];

    public IsLoading: boolean = true;
    public FilterOption: number = 2;        //  1 = all, 2 = current, 3 = no response

    public NumResponses: number = 0;
    public NumCurrent: number = 0;
    public NumNoResponse: number = 0;
    public HaveUtilityTypes: boolean = false;

    public NumEvents: number = 0;
    public ShowEvents: boolean = true;

    public CanEnterResponse: boolean = false;

    private _Destroyed: Subject<void> = new Subject();

    constructor(public CommonService: CommonService, private _TicketResponseService: TicketResponseService,
        private _PermissionsService: PermissionsService, private _DownloadService: DownloadService)
    {
        this.ResponseHistory = null;
        this.FilteredResponseHistory = null;
        this.IsLoading = true;

        //  Use an observable to trigger this because we have 2 properties that could both change
        //  at (roughly) the same time.  That would trigger 2 api calls.  And one will not have the 2nd property.
        this._FetchResponseHistoryEvent
            .pipe(takeUntil(this._Destroyed), debounceTime(10))
            .subscribe(key => this.FetchResponseHistory());

        //  Default this to false on the phone - there can be a lot of them so it's difficult to see the responses.
        this.ShowEvents = !this.CommonService.DeviceDetectorService.IsPhone;
    }

    ngOnDestroy() {
        this._Destroyed.next();
        this._Destroyed.complete();
    }

    public ngOnInit(): void {
        if (this.CheckForRequiredDiscussionResponses && this._Ticket.ServiceAreas) {
            const serviceAreas = (this._Ticket as Ticket).ServiceAreas;
            TicketResponseDiscussionListComponent.PromptForRequiredDiscussionResponses(serviceAreas, this.CommonService.Dialog).subscribe(somethingEntered => {
                if (somethingEntered)
                    this.FetchResponseHistory();
            });
        }
    }

    private FetchResponseHistory(): void {
        if (!this.Ticket?.TicketNumber)
            return;

        this.IsLoading = true;
        this._TicketResponseService.GetTicketResponseHistory(this.Ticket.TicketNumber, this.ServiceAreaID)
            .subscribe(response => this.ResponseHistoryReceived(response), null, () => this.IsLoading = false);
    }

    public Refresh(): void {
        this._FetchResponseHistoryEvent.next();
    }

    public ExportToCsv(): void {
        const rows = this.FilteredResponseHistory.map((historyItem: TicketResponseHistoryItem) => {
            const item = [];
            item.push(historyItem.Status.replace(",", ":"));

            if (historyItem.Event) {
                item.push(formatDate(historyItem.Event.EventDate, this.CommonService.SettingsService.DateTimeFormat, "en-US"));
                item.push(" ");
                item.push(" ");
                item.push(" ");
                item.push(" ");
                item.push("Version: " + historyItem.Event.TicketVersion + " - " + historyItem.Event.Message);
            }
            else {
                item.push(formatDate(historyItem.Response.EnteredDate, this.CommonService.SettingsService.DateTimeFormat, "en-US"));
                if (historyItem.Response?.ServiceAreaName)
                    item.push(historyItem.Response?.ServiceAreaName + ": " + historyItem.Response?.ServiceAreaCode);
                else
                    item.push(" ");

                item.push(historyItem.Response?.UtilityTypeName);

                if (historyItem.Response?.ResponseCode)
                    item.push(historyItem.Response.ResponseCode + ": " + historyItem.Response.ResponseDescription);
                else if (historyItem.Response?.SuppressUntilDate)
                    item.push(formatDate(historyItem.Response.SuppressUntilDate, this.CommonService.SettingsService.DateTimeFormat, "en-US"));
                else if (historyItem.Response?.DiscussionStatus)
                    item.push(historyItem.Response.DiscussionStatus.Status);
                else
                    item.push(" ");

                if (historyItem.Response?.EntryMethod)
                    item.push(EnumHelpers.MapDescriptionFromKey(historyItem.Response?.EntryMethod, ResponseEntryMethodEnum, ResponseEntryMethodEnumDescriptions));
                else
                    item.push(" ");

                item.push(historyItem.Response?.Comment);
            }

            for (let i = 0; i < item.length; i++) {
                const val = item[i];
                if (val) {
                    let stringVal = val.toString();
                    if (stringVal.indexOf('"') >= 0)
                        stringVal = stringVal.replace('"', '""');       //  Must quote a " by doubling it.
                    item[i] = '"' + stringVal + '"';
                }
            }

            return item;
        });

        rows.unshift(["Status", "Date", "Service Area", "Utility Type", "Response", "Entry Method", "Comments"]);

        //  Use the Download service - it creates a "blob" and handles weird characters much better (previous method did not work
        //  if a field contained a # character).
        const csvContent = rows.map(e => e.join(",")).join("\n");
        const fileName = "TicketNumber_" +  this.Ticket.TicketNumber + "_ResponseHistory";
        this._DownloadService.DownloadCsv(csvContent, fileName);
    }

    private ResponseHistoryReceived(response: TicketResponseHistoryItem[]): void {
        this.NumResponses = 0;
        this.NumCurrent = 0;
        this.NumNoResponse = 0;
        this.HaveUtilityTypes = false;

        this.NumEvents = 0;

        if (response) {
            const serviceAreaIDs = [];
            response.forEach(r => {
                r.Status = this._TicketResponseService.GetHistoryStatus(r);

                if (r.Response) {
                    this.NumResponses++;
                    if (r.Response.UtilityTypeID)
                        this.HaveUtilityTypes = true;
                    if (!r.Response.ResponseID && r.Response.ResponseRequired) {
                        //  "No Response" does not also imply "Current".  If the response was not entered when the late
                        //  ticket was created, the record is kept with no response, current = false, and OnLateTicket flag
                        //  is set to true.  This allows us to "lock" the response state at the time the late ticket was sent.
                        this.NumNoResponse++;
                    }
                    if (r.Response.Current) {
                        this.NumCurrent++;
                        serviceAreaIDs.push(r.Response.ServiceAreaID);
                    }
                } else {
                    this.NumEvents++;
                }
            });

            if (this.AllowedActions && this.Ticket?.TicketTypeID && this.CommonService.SettingsService.UsesPositiveResponse) {
                this._PermissionsService.CurrentUserHasPermission(PermissionsEnum.TicketResponse_Create, serviceAreaIDs).pipe(take(1))
                    .subscribe(val => this.CanEnterResponse = coerceBooleanProperty(val));
            }
        }

        this.ResponseHistory = response;
        this.FilterHistoryItems();
    }

    public FilterChanged(event: MatRadioChange): void {
        this.FilterHistoryItems();
    }

    public ShowEventsChanged(event: any): void {
        this.FilterHistoryItems();
    }

    private FilterHistoryItems(): void {
        if ((this.FilterOption === 1) && this.ShowEvents) {
            //  No filtering
            this.FilteredResponseHistory = this.ResponseHistory;
            return;
        }

        this.FilteredResponseHistory = this.ResponseHistory.filter(r => {
            if (r.Response) {
                switch (this.FilterOption) {
                    case 2:
                        return r.Response.Current;
                    case 3:
                        return !r.Response.ResponseID && r.Response.ResponseRequired;
                    default:
                        return true;
                }
            } else
                return this.ShowEvents;
        });
    }

    public AddNewResponse(): void {
        this.CommonService.Dialog.open(AddPositiveResponseDialog, {
            data: new AddPositiveResponseData([this.Ticket.TicketNumber], this.Ticket.TicketTypeID, true, null),
            minWidth: '45%',
            width: '550px',
            maxWidth: '550px'
        }).afterClosed().subscribe(savedResponse => {
            if (savedResponse)
                this.Refresh();
        });
    }
}
