import { Directive, inject, OnDestroy } from '@angular/core';
import { FeatureItemResponse } from '@iqModels/Maps/FeatureItemResponse.model';
import { RoleTypeEnum } from 'Enums/RolesAndPermissions/RoleType.enum';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import * as _ from 'lodash';
import { MapTileSearchRequest } from 'Models/Searching/MapTileSearchRequest.model';
import { SearchRequest } from 'Models/Searching/SearchRequest.model';
import { TicketMapItem } from 'Models/Tickets/TicketMapItem.model';
import * as ol from 'ol';
import { MapBrowserEvent, Overlay } from 'ol';
import { Control } from 'ol/control';
import { Coordinate } from 'ol/coordinate';
import { EventsKey } from 'ol/events';
import { Extent } from 'ol/extent';
import { unByKey } from 'ol/Observable';
import { Pixel } from 'ol/pixel';
import { TicketsLayer } from 'Pages/Tickets/TicketMapViewer/Layers/TicketsLayer';
import { map, Observable, of } from 'rxjs';
import { CommonService } from 'Services/CommonService';
import { MapSearchService } from 'Services/MapSearchService';
import { MapBaseComponent } from 'Shared/Components/Maps/MapBase.component';
import { TicketSearchQueryConfiguration } from '../Search/Models/TicketSearchQueryConfiguration';
import { IMapViewerService } from './Services/IMapViewerService.interface';

/**
 *  Base class for a ticket map used on a dashboard tab.
 */
@Directive()
export abstract class BaseTicketMapViewerComponent extends MapBaseComponent implements OnDestroy {

    private _Config: TicketSearchQueryConfiguration;

    public get CurrentStateAbbreviation(): string { return null; }
    public get CurrentCountyName(): string { return null; }
    public get MapSearchFilterBounds(): Extent { return null; }

    private _TicketsLayer: TicketsLayer;

    //  ID is either a Ticket.ID or a TicketResponse.ID depending on the type of map we are displaying.
    //  The name of the property used is specified by MapCachKeyPropertyName - this is what is returned in the Tile request features.
    private _CachedMapItems: { [id: string]: TicketMapItem } = {};

    //  Unique ID used for the MapItemCache.  Normally, this is "ID" (which holds a Ticket.ID).  But for the TicketResponse map,
    //  this needs to be TicketResponseID.
    protected get MapCachKeyPropertyName(): string { return "ID"; }

    private _PointerMoveEventsKey: EventsKey;
    private _ClickEventsKey: EventsKey;

    private _MapTileSearchRequest: MapTileSearchRequest;

    public LimitToTicketsWithinXFtOfCurrentLocation?: number;

    private _PopupOverlay: Overlay;
    private _TicketDetailsOverlayControl: Control;

    public SelectedTicketList: TicketMapItem[] = [];

    //  Override this in derived class to specify a RoleType when fetching the MapItems information.
    protected get RoleType(): RoleTypeEnum { return undefined; }

    constructor(commonService: CommonService, private _MapViewerService: IMapViewerService) {
        super(commonService, inject(MapSearchService));

        //  Set the RoleType on the MapViewerService so it knows what to use when fetching MapItems.
        //  Must be set as a property like this because the MapItemsPager also needs to refresh it.
        this._MapViewerService.RoleType = this.RoleType;
    }

    public ngOnDestroy(): void {
        if (this._PopupOverlay) {
            this.Map.removeOverlay(this._PopupOverlay);
            this._PopupOverlay = null;
        }

        if (this._TicketDetailsOverlayControl) {
            this.Map.removeControl(this._TicketDetailsOverlayControl);
            this._TicketDetailsOverlayControl = null;
        }

        if (this._PointerMoveEventsKey) {
            unByKey(this._PointerMoveEventsKey);
            this._PointerMoveEventsKey = null;
        }
        if (this._ClickEventsKey) {
            unByKey(this._ClickEventsKey);
            this._ClickEventsKey = null;
        }

        super.ngOnDestroy();
    }

    private InitConfig(): void {
        const searchRequest = new SearchRequest();

        searchRequest.Filters = [];
        if (this._Config.ViewFilters)
            searchRequest.Filters = this._Config.ViewFilters;
        if (this._Config.DefaultFilters)
            searchRequest.Filters = searchRequest.Filters.concat(this._Config.DefaultFilters);

        this._MapTileSearchRequest = new MapTileSearchRequest(searchRequest);

        if (this._TicketsLayer)
            this._TicketsLayer.MapTileSearchRequest = this._MapTileSearchRequest;

        //  If there is a filter limiting to the current location, set this to force zooming to current location and hide the "Tickets" link on the .html page.
        const withinFeetValues = this._Config.DefaultFilters?.find(f => f.Operator === SearchFilterOperatorEnum.WithinXFeetOfLocation)?.Values;
        if (withinFeetValues && withinFeetValues.length === 3)
            this.LimitToTicketsWithinXFtOfCurrentLocation = withinFeetValues[2].FilterValue;
    }

    public ShowTicketsNearMe(config: TicketSearchQueryConfiguration): void {
        this._Config = config;
        this.InitConfig();

        this.Clear();

        const lastZoomMethod = localStorage.getItem("ticketDashboardZoomMethod");
        if (lastZoomMethod === "tickets" && !this.LimitToTicketsWithinXFtOfCurrentLocation)
            this.ZoomToTicketExtents();
        else
            this.PositionToCurrentLocation();       //  Defaults to location if not set
    }

    public PositionToCurrentLocation(): void {
        this.SetLastZoomMethod("location");
        super.PositionToCurrentLocation();
    }

    protected HandleCurrentPositionError(error: GeolocationPositionError): void {
        this.ZoomToTicketExtents();
    }

    protected HandleCurrentPositionNotInMapBounds(): void {
        //  Default is to zoom to full extents - try to zoom to tickets first
        this.ZoomToTicketExtents();
    }

    public ZoomToTicketExtents(): void {
        //  Don't set this if we zoom to ticket extents - that will happen if the bounds are outside the One Call.  Do not want to change the default in that case.
        if (!this.LimitToTicketsWithinXFtOfCurrentLocation)
            this.SetLastZoomMethod("tickets");

        this._MapViewerService.Extents(this._MapTileSearchRequest).subscribe({
            next: extents => this.ZoomToLatLonBounds(extents),
            error: () => this.ZoomToFullMapExtents()
        });
    }

    private SetLastZoomMethod(method: string): void {
        try {
            localStorage.setItem("ticketDashboardZoomMethod", method);
        } catch { /* empty */ }
    }

    public Clear(): void {
        this._CachedMapItems = {};

        const source = this._TicketsLayer?.Layer?.getSource();
        if (source)
            source.clear();     //  This will also refresh the map

        this.HideTicketDetails();
    }

    protected OnMapInitialized(map: ol.Map): boolean {
        this._TicketsLayer = new TicketsLayer(map, this._MapViewerService.TileUrl(), this.CommonService.AuthenticationService, this._MapTileSearchRequest, this.MapCachKeyPropertyName);

        this._PointerMoveEventsKey = map.on('pointermove', evt => this.OnPointerMove(evt));
        this._ClickEventsKey = map.on('click', evt => this.OnClick(evt));

        //  Create a custom Component overlay to show the ticket details after clicking on a ticket.
        //  Custom OpenLayers Overlay: https://openlayers.org/en/latest/examples/popup.html
        //  The content comes from the "popup" element in the .html - which is then dynamic since it's managed by Angular.
        const container = document.getElementById('popup');
        const closer = document.getElementById('popup-closer');
        this._TicketDetailsOverlayControl = new Control({
            element: container,
        });
        closer.onclick = () => {
            this.HideTicketDetails();
            closer.blur();
            return false;
        };
        this.Map.addControl(this._TicketDetailsOverlayControl);
        this.HideTicketDetails();

        return true;            //  Tells base to not position to default extents
    }

    private HideTicketDetails(): void {
        const popupElement = document.getElementById('popup');
        if (popupElement?.classList)
            popupElement.classList.add("hidden");
        if (this._TicketsLayer)
            this._TicketsLayer.MapItemPagerVisibleTicketID = null;
    }

    private ShowTicketDetails(): void {
        const popupElement = document.getElementById('popup');
        if (popupElement?.classList)
            popupElement.classList.remove("hidden");
    }

    protected GetBestFitExtents(): Extent {
        return null;
    }

    protected OnPointerMove(evt: MapBrowserEvent<any>): void {
        //  Only do this on desktop.  On mobile, the "onpointermoved" event does not apply since it's not possible to hover over a feature.
        //  But the event is triggered by clicking and dragging.
        if (this.CommonService.DeviceDetectorService.IsDesktop)
            this._TicketsLayer.HoveringOverTicketID = this.FindTicketIDAtPixel(evt.pixel);
    }

    protected OnClick(evt: MapBrowserEvent<any>): void {
        this.GetAllMapItemsAtPixel(evt.pixel).subscribe(mapItems => {
            this.SelectedTicketList = mapItems ?? [];

            if (this.SelectedTicketList.length === 0)
                this.HideTicketDetails();
            else
                this.ShowTicketDetails();
        });
    }

    //  This is called when the context menu is displayed if IsContextMenuDirty is set to true.
    //  So can rebuild the menu by setting that property.
    protected override BuildContextMenuItems(event: { type: string, pixel: Pixel, coordinate: Coordinate }): any[] {
        const contextMenuItems = super.BuildContextMenuItems(event);

        //  Find all of the tickets at the location of the event.  If we have any, add context menu items to view them.
        //  If we do not have TicketMapItems for one or more tickets, we have to fetch that asynchronously.  So in that case, we will re-trigger the
        //  context menu build when that api request completes.
        const cachedItems = this.GetCachedMapItemsAtPixel(event.pixel);

        if (cachedItems.missingIDs.length > 0) {
            //  Need to fetch the info for the missing tickets
            this.FetchMapItems(cachedItems.missingIDs, cachedItems.mapItems).subscribe(() => this.RebuildContextMenu(event));
        }

        if (cachedItems.mapItems.length > 0) {
            if (contextMenuItems.length > 0)
                contextMenuItems.push("-");     //  Separator

            let count = 0;
            cachedItems.mapItems
                .sort((a, b) => (a.TicketNumber + "-" + a.Version).toLocaleLowerCase().localeCompare((b.TicketNumber + "-" + b.Version).toLocaleLowerCase()))
                .forEach(mi => {
                    count++;
                    if (count <= 5) {
                        contextMenuItems.push({
                            text: "<i class='fas fa-up-right-from-square' ></i>View Ticket " + mi.TicketNumber + "-" + mi.Version,
                            classname: "iq-image-item",
                            callback: () => window.open("/tickets/view/" + (mi.TicketID ?? mi.ID), '_blank').focus()
                        });
                    }
                });
        }

        return contextMenuItems;
    }

    private FindTicketIDAtPixel(pixel: Coordinate): string {
        //  Find the ID (or one of them) that is being hovered over.  Set that ID into the TicketsLayer.
        //  If it changes, it will trigger a change on the layer to redraw it.  Which will cause any features
        //  with the same ID to be highlighted.  This works better for a MVT tile layer because the features may
        //  be clipped randomly.  So this will re-style ALL of the features for that ID no matter how they were clipped.
        let hoveringOverTicketID: string = null;
        let keepCurrentID: boolean = false;

        this.Map.forEachFeatureAtPixel(pixel, (feature/*, layer*/) => {
            const id = feature.getProperties()[this.MapCachKeyPropertyName];

            //  If we find the currently selected ID, keep it.  This keeps things from flashing when moving the mouse
            //  slightly when the features are chopped and overlapping with another dig site.
            if (id === this._TicketsLayer.HoveringOverTicketID) {
                keepCurrentID = true;
                return true;        //  This tells forEachFeatureAtPixel to stop iterating
            }
            else
                hoveringOverTicketID = id;
        }, { hitTolerance: 5 });

        return keepCurrentID ? this._TicketsLayer.HoveringOverTicketID : hoveringOverTicketID;
    }

    public GetAdditionalMapFeaturesForPopup(pixel: Coordinate, selectionBox: Extent): Observable<{ Features: FeatureItemResponse[], Exclusive: boolean }> {
        return this.GetAllMapItemsAtPixel(pixel).pipe(map(mapItems => {
            if (!mapItems || (mapItems.length === 0))
                return { Features: [], Exclusive: false };
            else {
                const totalItems = mapItems.length;

                mapItems = _.sortBy(mapItems, m => m.SortByDate);
                mapItems = _.take(mapItems, 5);
                const featureItems = mapItems
                    .map(m => {
                        let featureName = m.TicketNumber + ' v' + m.Version;
                        if (m.Tooltip)
                            featureName += ", " + m.Tooltip;
                        return new FeatureItemResponse("Ticket", featureName, null);
                    });

                if (totalItems > 5)
                    featureItems.push(new FeatureItemResponse("Ticket", "...plus " + (totalItems - 5) + " more tickets", null));

                return { Features: featureItems, Exclusive: false };
            }
        }));
    }

    /**
     * Returns all TicketMapItems for the tickets at the specified pixel.  If any are not cached, they will be fetched.
     */
    private GetAllMapItemsAtPixel(pixel: Coordinate): Observable<TicketMapItem[]> {
        const cachedItems = this.GetCachedMapItemsAtPixel(pixel);

        if (cachedItems.missingIDs.length === 0)
            return of(cachedItems.mapItems);        //  All tickets are already cached (or there aren't any at all)

        //  Need to fetch the info for the missing tickets.  This returns the new items added to the cachedItems we already found.
        return this.FetchMapItems(cachedItems.missingIDs, cachedItems.mapItems);
    }

    /**
     *  Returns the already cached TicketMapItems for the tickets at the specified pixel.  Also returns the IDs of any tickets that are not cached.
     */
    private GetCachedMapItemsAtPixel(pixel: Coordinate): { mapItems: TicketMapItem[], missingIDs: string[] } {
        const mapItems: TicketMapItem[] = [];
        const missingIDs: string[] = [];

        this.Map.forEachFeatureAtPixel(pixel, (feature/*, layer*/) => {
            const id = feature.getProperties()[this.MapCachKeyPropertyName];       
            if (id) {
                const item = this._CachedMapItems[id];
                if (item && (mapItems.indexOf(item) === -1))
                    mapItems.push(item);
                else if (!item && (missingIDs.indexOf(id) === -1))
                    missingIDs.push(id);
            }
        }, {
            hitTolerance: 5,
            layerFilter: (layerCandidate) => layerCandidate === this._TicketsLayer.Layer
        });

        return { mapItems, missingIDs };
    }

    private FetchMapItems(idList: string[], mapItems: TicketMapItem[]): Observable<TicketMapItem[]> {
        return this._MapViewerService.MapItems(idList).pipe(
            map(fetchedItems => {
                if (fetchedItems) {
                    fetchedItems.forEach(mi => {
                        this._CachedMapItems[mi.ID] = mi;
                        mapItems.push(mi);
                    });
                }
                return mapItems;
            }));
    }

    public MapItemPagerVisibleTicket(ticketID: string): void {
        this._TicketsLayer.MapItemPagerVisibleTicketID = ticketID;
    }
}
