import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule, MAT_SELECT_CONFIG } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

//  The @pebula/ngrid package has not been upgraded to support Angular 15 yet so it's paginator is using the legacy css classes.
//  Until that gets upgraded, we need to import the Legacy Paginator and initialize the legacy styles in App-theme.scss
//  We don't use the MatPaginator at all so this does not affect anything in Exactix.
//import { MatPaginatorModule } from '@angular/material/paginator';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';

//import { MatTableModule,  MatTableDataSource } from '@angular/material/table';

import { CdkTableModule } from '@angular/cdk/table';

import { OverlayModule } from '@angular/cdk/overlay';

//import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

const materialModules: any[] = [
    MatSidenavModule, MatToolbarModule, MatCardModule, MatListModule, MatInputModule, MatSelectModule, MatOptionModule,
    MatButtonModule, MatIconModule, MatTableModule, MatExpansionModule, MatSlideToggleModule, MatCheckboxModule, MatRadioModule,
    MatPaginatorModule, MatSortModule, MatProgressSpinnerModule, MatProgressBarModule,
    MatDialogModule, MatGridListModule, MatAutocompleteModule, MatTabsModule, MatMenuModule, MatTooltipModule, MatChipsModule, MatRippleModule, MatBadgeModule, MatStepperModule,
    CdkTableModule,

    //ExperimentalScrollingModule,

    OverlayModule,

    ScrollingModule,
    DragDropModule
];

@NgModule({
    imports: materialModules,
    exports: materialModules,
    providers: [
        {
            //  Note: Angular 15 seems to have broken some handling for mat - selects and a debounce time(which defaults to 200ms).
            //  Reported as a bug here and they said it was intended: https://github.com/angular/components/issues/28352
            //  Workaround is to set typeaheadDebounceInterval to 0.  Can be done on the individual mat-select or globally like we are doing here.
            //  Issue happens if you use keypress to select an item and (quickly) tab - causes item to not be selected!
            //  https://material.angular.io/components/select/api#MatSelectConfig
            provide: MAT_SELECT_CONFIG,
            useValue: { typeaheadDebounceInterval: 0 }
        }
    ]
})
export class MaterialModule {}


import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';
@Injectable()       //  Included in the providers section of AppModule
export class MatPaginatorIntl4IQ extends MatPaginatorIntl {
    //Don't want to change these, so don't need to fill them out
    //itemsPerPageLabel = 'Items per page';
    //nextPageLabel = 'Next page';
    //previousPageLabel = 'Previous page';

    getRangeLabel = function (page: number, pageSize, length) {
        if (length === 0 || pageSize === 0) {
            return 'Page 1 of 1';
        }

        return "Page " + String(page + 1) + " of " + String(Math.ceil(length / pageSize));
    };
}
