import { VectorLayerBase } from "Shared/Components/Maps/Layers/VectorLayerBase";
import { MeasureDistanceTool } from "Shared/Components/Maps/Tools/MeasureDistanceTool";
import { MapConstants } from "Shared/Components/Maps/MapConstants";
import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { GeometryUtils } from "../GeometryUtils";
import { MapFeatureStyleEnum } from "Enums/MapFeatureStyle.enum";
import * as ol from 'ol';
import { Style, Stroke, Fill } from "ol/style";
import { LineString, Polygon } from "ol/geom";
import ol_style_FillPattern from "ol-ext/style/FillPattern";

export class DigSiteEditorVectorLayer extends VectorLayerBase {

    constructor(mapToolService: MapToolService, layerName: string = MapConstants.LAYERNAME_DIGSITE_EDITOR) {
        super(mapToolService, layerName, .5);
    }

    public BuildStyleForFeature(feature: ol.Feature<any>, isDrawing: boolean, featureStyle: MapFeatureStyleEnum, resolution: number): Style | Style[] {
        const styles: Style[] = [];

        const style = new Style({
            //  https://viglino.github.io/ol-ext/examples/style/map.style.pattern.html
            //  https://github.com/Viglino/ol-ext/blob/master/src/style/FillPattern.js
            stroke: new Stroke({
                color: "#0951A7",
                width: 2
            })
        });

        if (featureStyle === MapFeatureStyleEnum.Circle) {
            //  Only need the fill when drawing a circle.  Drawing polygons now just shows the outline (using the stroke)
            //  and the buffered area (added below by AddStyleForBufferedPolygon) will show the entire area
            const fillPattern = new ol_style_FillPattern({
                color: "#0D72EF",
                fill: new Fill({
                    color: "rgba(0,0,0,0)",      //  background: transparent
                }),
                pattern: 'cross',
                size: 1,
                spacing: 10,
                angle: true
            });
            style.setFill(fillPattern as unknown as Fill);
        }

        styles.push(style);

        if (isDrawing)
            MeasureDistanceTool.AddStylesForMeasurements(feature, styles, (featureStyle === MapFeatureStyleEnum.Line), this.MapToolService.LabelDistancesOnlyInFeet);

        if (featureStyle === MapFeatureStyleEnum.Line)
            this.AddStyleForBufferedLineFeature(styles, feature);
        else if (featureStyle === MapFeatureStyleEnum.Polygon)
            this.AddStyleForBufferedPolygon(styles, feature);

        return styles;
    }

    private AddStyleForBufferedLineFeature(styles: Style[], feature: ol.Feature<any>): void {
        //  This builds a style for the buffered line so you can see that while you draw.
        const geom = feature.getGeometry();
        const geomType = geom.getType();
        if (geomType === "LineString") {
            const fillPattern = new ol_style_FillPattern({
                color: "#0D72EF",
                fill: new Fill({
                    color: "rgba(0,0,0,0)",      //  background: transparent
                }),
                pattern: 'cross',
                size: 1,
                spacing: 8,
                angle: true
            });
            styles.push(new Style({
                geometry: GeometryUtils.BufferLineString(geom as LineString, this.MapToolService.LineBufferFt),
                fill: fillPattern as unknown as Fill,
                stroke: new Stroke({
                    color: "#0951A7",
                    width: 1
                })
            }));
        }
    }

    private AddStyleForBufferedPolygon(styles: Style[], feature: ol.Feature<any>): void {
        const geom = feature.getGeometry();
        if (geom.getType() !== "Polygon")
            return;

        const bufferedGeom = GeometryUtils.BufferPolygon(geom as Polygon, this.MapToolService.PolygonBufferFt);
        if (!bufferedGeom)
            return;

        const fillPattern = new ol_style_FillPattern({
            color: "#0D72EF",
            fill: new Fill({
                color: "rgba(0,0,0,0)",      //  background: transparent
            }),
            pattern: 'cross',
            size: 1,
            spacing: 8,
            angle: true
        });

        styles.push(new Style({
            geometry: bufferedGeom,
            fill: fillPattern as unknown as Fill,
            stroke: new Stroke({
                color: "#0951A7",
                width: 1
            })
        }));
    }
}
