<div style="flex-direction:column; padding:10px; overflow: auto">
    <div *ngIf="Ticket.Attachments && Ticket.Attachments.length > 0" style="padding:10px">
        <div class="primary-color">Ticket has {{Ticket.Attachments.length}} Attachment{{Ticket.Attachments.length > 1 ? 's' : ''}}:</div>
        <div style="padding-left:20px; padding-bottom:20px; display:flex">
            <div>
                <div *ngFor="let attachment of Ticket.Attachments; let i = index;" style="display:flex; padding-top:10px">
                    <div style="flex-grow:1">{{attachment.FileName}}</div>
                    <div class="link" style="padding-left:1em" (click)="OnOpenAttachment(attachment)">open</div>
                    <div class="link" style="padding-left:1em" (click)="OnDownloadAttachment(attachment)">download</div>
                </div>
            </div>
            <div class="flex-grow:1"></div>
        </div>
        <mat-divider></mat-divider>
    </div>
    <ticket-details-printing-text-and-service-areas *ngIf="Ticket" [TicketMinimal]="Ticket" style="overflow: auto"></ticket-details-printing-text-and-service-areas>
</div>
