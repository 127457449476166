import { Component, OnDestroy } from '@angular/core';
import { TicketResponseMapViewerService } from './Services/TicketResponseMapViewer.service';
import { CommonService } from 'Services/CommonService';
import { BaseTicketMapViewerComponent } from './BaseTicketMapViewer.component';
import { IMapViewerService } from './Services/IMapViewerService.interface';

/**
 *  Ticket Map used by a Service Area user ticket dashboard
 */
@Component({
    templateUrl: './BaseTicketMapViewer.component.html',
    styleUrls: ['./BaseTicketMapViewer.component.scss'],
    providers: [
        CommonService,
        { provide: "IMapViewerService", useClass: TicketResponseMapViewerService }  //  So can be injected in the TicketMapItemPager.component
    ]
})
export class ServiceAreaTicketMapViewerComponent extends BaseTicketMapViewerComponent implements OnDestroy {

    //  Unique ID used for the MapItemCache.  Normally, this is "ID" (which holds a Ticket.ID).  But for the TicketResponse map,
    //  this needs to be TicketResponseID.
    protected override get MapCachKeyPropertyName(): string { return "TicketResponseID"; }

    constructor(commonService: CommonService, mapViewerService: TicketResponseMapViewerService) {
        super(commonService, mapViewerService);
    }
}
