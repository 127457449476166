<mat-form-field>
    <mat-label>Search Companies</mat-label>
    <input type="text" matInput [formControl]="CompanyAutoCompleteFormControl" [matAutocomplete]="companyAuto">
    <mat-autocomplete #companyAuto="matAutocomplete" [displayWith]="CompanyAutoCompleteDisplayFn"
                      [class]="'excavator-contact-autocomplete-option iq-ticket-entry'" (optionSelected)="CompanyAutoCompleteSelected()">
        <mat-option *ngFor="let option of CompanyAutoCompleteFormControl.Results | async" [value]="option">
            <iq-excavator-company-autocomplete-option [model]="option"></iq-excavator-company-autocomplete-option>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
