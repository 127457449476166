import { MapLayer } from "Models/Configuration/Maps/MapLayer.model";
import { Feature } from "ol";
import { ol_coordinate_offsetCoords } from "ol-ext/geom/GeomUtils";
import { MVT } from "ol/format";
import { Geometry, LinearRing, Polygon } from "ol/geom";
import { inflateCoordinatesArray } from "ol/geom/flat/inflate";
import { VectorTile as ol_layer_VectorTile } from "ol/layer";
import Map from 'ol/Map';
import RenderFeature from "ol/render/Feature";
import { VectorTile as ol_source_VectorTile } from "ol/source";
import { Fill, Icon, Stroke, Style, Text } from "ol/style";

export class MapFeaturesTileLayer {

    private _Source: ol_source_VectorTile;
    private _Layer: ol_layer_VectorTile;

    constructor(private _Map: Map, apiBaseUrl: string, oneCallCenterCode, public MapLayer: MapLayer) {
        const mapFeatureTileURL = apiBaseUrl + "/Maps/Tiles/MapFeatures/" + oneCallCenterCode + "/" + MapLayer.ID + "/{z}/{x}/{y}";

        this.CreateLayer(mapFeatureTileURL);
    }

    public OnDestroy(): void {
        this._Map = null;
    }

    private CreateLayer(mapFeatureTileURL: string): void {

        //  ** Note that the VectorTile source has a grid size of 512 pixels.  Where the image based XYZ source
        //  is 256.  This results in the vector tiles being 1 zoom level less than the zoom level of the image tiles.
        //  The resolutions we calculate are from the "map" - not the source - which seems to match up with the
        //  image source resolution.  So when relating vector tile zoom levels to resolutions, we need to add 1.

        const minZoom = this.MapLayer.MapFeatureTypes.reduce((prev, cur) => prev.DisplayZoom < cur.DisplayZoom ? prev : cur).DisplayZoom;

        this._Source = new ol_source_VectorTile({
            format: new MVT(),
            url: mapFeatureTileURL,
        });
        this._Layer = new ol_layer_VectorTile({
            maxResolution: this._Map.getView().getResolutionForZoom(minZoom),
            minResolution: this._Map.getView().getResolutionForZoom(20 + 1),
            declutter: true,
            source: this._Source,
            style: (feature, resolution) => this.BuildStyleForFeature(feature, resolution)
        });

        if (!this.MapLayer.IsInitiallyVisible)
            this._Layer.setVisible(false);

        //  For LayerSwitcher - and only if allowed to toggle visibility
        if (this.MapLayer.CanToggleVisibility) {
            this._Layer.set("title", this.MapLayer.Name);
            this._Layer.set("displayInLayerSwitcher", true);
        }

        this._Map.addLayer(this._Layer);
    }

    public Refresh(): void {
        if (this._Source)
            this._Source.refresh();
    }

    public BuildStyleForFeature(feature: Feature<any> | RenderFeature, resolution: number): Style | Style[] {
        const cachedStyle = this.GetStyle(feature.get("MapFeatureTypeID"), resolution);
        if (!cachedStyle)
            return null;

        if (resolution > cachedStyle.DisplayResolution)
            return null;                                //  Not visible;

        let labelStyle = cachedStyle.LabelStyle;
        if (labelStyle) {
            if (resolution > cachedStyle.LabelResolution)
                labelStyle = null;
            else
                labelStyle.getText().setText(feature.get('Name'));
        }

        if (!labelStyle)
            return cachedStyle.FeatureStyle;

        //  If including labels, need to duplicate the FeatureStyle array so that we can append the label style
        //  (without affecting the underlying FeatureStyle array - otherwise, it would continually append to the same array!)
        return [...cachedStyle.FeatureStyle, labelStyle];
    }

    private _CachedStyles: { [mapFeatureTypeID: string]: CachedStyle } = {};

    private GetStyle(mapFeatureTypeID: string, resolution: number): CachedStyle {
        let cachedStyle = this._CachedStyles[mapFeatureTypeID];
        if (cachedStyle)
            return cachedStyle;

        const mapStyle = this.MapLayer.MapFeatureTypes.find(f => f.ID === mapFeatureTypeID);
        if (!mapStyle || !mapStyle.DisplayStyle || mapStyle.DisplayStyle === '') {
            this._CachedStyles[mapFeatureTypeID] = null;
            return null;
        }

        const displayStyleOptions = JSON.parse(mapStyle.DisplayStyle);
        const labelStyleOptions = (mapStyle.LabelStyle && mapStyle.LabelStyle.length > 0) ? JSON.parse(mapStyle.LabelStyle) : null;

        let featureStyle: Style[];
        let canCache = true;
        if (displayStyleOptions.hashLineStyle) {
            featureStyle = this.CreateHashLineStyle(displayStyleOptions.hashLineStyle, resolution);
            canCache = false;       //  Must re-evaluate or the hash marks/size/position will not be updated when we zoom
        }
        else
            featureStyle = [this.CreateOpenLayersStyle(displayStyleOptions)];

        let labelStyle: Style = null;
        if (labelStyleOptions && (mapStyle.LabelZoom > 0)) {
            const text = new Text(labelStyleOptions);
            if (labelStyleOptions.fill)
                text.setFill(new Fill(labelStyleOptions.fill));
            if (labelStyleOptions.stroke)
                text.setStroke(new Stroke(labelStyleOptions.stroke));
            labelStyle = new Style();
            labelStyle.setText(text);
        }

        const displayResolution = this._Map.getView().getResolutionForZoom(mapStyle.DisplayZoom);
        const labelResolution = labelStyle ? this._Map.getView().getResolutionForZoom(mapStyle.LabelZoom) : null;

        cachedStyle = new CachedStyle(featureStyle, labelStyle, displayResolution, labelResolution);
        if (canCache)
            this._CachedStyles[mapFeatureTypeID] = cachedStyle;
        return cachedStyle;
    }

    /**
     * Custom style based on an ol-ext example: https://viglino.github.io/ol-ext/examples/style/map.style.hashlines.html
     * Shows "teeth" on the inside of a polygon.  Currently used by Idaho on their Subdivision & Mobile Home/Trailer Park layers.
     * @param hashLineStyleOptions
     */
    private CreateHashLineStyle(hashLineStyleOptions: any, resolution: number): Style[] {
        var offset = hashLineStyleOptions.offset ?? 5;
        return [
            new Style({
                stroke: new Stroke({
                    width: hashLineStyleOptions.width ?? 1,
                    color: hashLineStyleOptions.color ?? "green"
                })
            }),
            new Style({
                stroke: new Stroke({
                    color: hashLineStyleOptions.color ?? "green",
                    width: offset > 0 ? 2 * offset : -2 * offset,
                    lineDash: [hashLineStyleOptions.width ?? 1, hashLineStyleOptions.distance ?? 10],
                    lineCap: 'butt',
                    lineJoin: 'bevel'
                }),
                geometry: (feature) => MapFeaturesTileLayer.OffsetGeometry(feature, offset * resolution)
            })
        ];
    }

    /**
     * Builds a geometry that is slightly offset so that the edges of the lineDash style will terminate at the
     * edges of the feature geometry.  i.e. draw the hash marks inside the polygon.
     * @param feature
     * @param size
     */
    private static OffsetGeometry(feature: Feature<Geometry> | RenderFeature, size) {
        const geom = feature.getGeometry() as RenderFeature;
        switch (geom.getType()) {
            case 'Polygon':
                //  This returns a coordinate list for each segment (multi-polygon/hole) so must loop for each
                const origRingCoordList = MapFeaturesTileLayer.InflateCoordinates(geom);
                const offsetRingCoordList: number[][][] = [];
                for (let i = 0; i < origRingCoordList.length; i++) {
                    const ring = new LinearRing(origRingCoordList[i]);
                    const sign = ring.getArea() < 0 ? -1 : 1;
                    offsetRingCoordList.push(ol_coordinate_offsetCoords(origRingCoordList[i], sign * size));
                }
                return new Polygon(offsetRingCoordList);
            default:
                return feature.getGeometry();
        }
    }

    //  Converts the coordinates in the RenderFeature to an array of normal coordinates.
    //  https://stackoverflow.com/a/67226267/916949
    private static InflateCoordinates(feature: RenderFeature): number[][][] {
        return inflateCoordinatesArray(
            feature.getFlatCoordinates(), // flat coordinates
            0, // offset
            feature.getEnds() as number[], // geometry end indices
            2, // stride
        );
    }

    /**
     * Read the properties from displayStyleOptions and construct an OpenLayers Style instance.
     * @param displayStyleOptions
     */
    private CreateOpenLayersStyle(displayStyleOptions: any): Style {
        const featureStyle = new Style();

        if (displayStyleOptions.fill)
            featureStyle.setFill(new Fill(displayStyleOptions.fill));
        if (displayStyleOptions.stroke)
            featureStyle.setStroke(new Stroke(displayStyleOptions.stroke));
        if (displayStyleOptions.text) {
            const text = new Text(displayStyleOptions.text);
            if (displayStyleOptions.text.fill)
                text.setFill(new Fill(displayStyleOptions.text.fill));
            if (displayStyleOptions.text.stroke)
                text.setStroke(new Stroke(displayStyleOptions.text.stroke));
            featureStyle.setText(text);
        }
        if (displayStyleOptions.icon)
            featureStyle.setImage(new Icon(displayStyleOptions.icon));

        return featureStyle;
    }
}

class CachedStyle {
    constructor(public FeatureStyle: Style[], public LabelStyle: Style, public DisplayResolution: number, public LabelResolution: number) { }
}
