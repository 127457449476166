import { Injectable } from '@angular/core';
import { CRUDBaseService, CRUDServices } from 'Shared/BaseServices/CRUDBase.service';
import { ExcavatorContact } from 'Models/Excavators/ExcavatorContact.model';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { ExcavatorAutocompleteResponse } from '@iqModels/Excavators/ExcavatorAutocompleteResponse.model';
import { Observable, of } from 'rxjs';
import { ExcavatorAutocompleteRequest } from '@iqModels/Excavators/ExcavatorAutocompleteRequest.model';
import { ExcavatorContactToPerson } from 'Models/Excavators/ExcavatorContactToPerson.model';
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { UIDateTimeFormat } from 'Shared/Utils/MaskFormats.model';
import { SelectOption } from 'Models/Configuration/SelectOption.model';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { FindContactForSelfRegistrationRequest } from "Models/Excavators/FindContactForSelfRegistrationRequest.model";

export class ExcavatorMoveOfficeRequest {
    ExcavatorID: string;
    OfficeTranslations: { [x: string]: string };
}

export class ExcavatorMergeRequest {
    FromExcavatorID: string;
    ToExcavatorID: string;

    DeleteFromExcavator: boolean;

    OfficeTranslations: { [x: string]: string };
}

export class ExcavatorMoveTicketsRequest {
    FromExcavatorID: string;
    ToExcavatorID: string;
    MoveHomeownerTickets: boolean;
    OfficeTranslations: { [x: string]: string };
}

@Injectable({
    providedIn: 'root'//I don't think this is ever needed as a new instance anywhere, so we can just set this to be in the root and shared everywhere
})
export class ExcavatorContactService extends CRUDBaseService<ExcavatorContact> {

    protected apiPath: string = "Administration/ExcavatorContact";

    ViewPermission: PermissionsEnum = PermissionsEnum.ExcavatorContact_View;//Needs to be overriden and set to the proper permission
    EditPermission: PermissionsEnum = PermissionsEnum.ExcavatorContact_Edit;//Needs to be overriden and set to the proper permission
    CreatePermission: PermissionsEnum = PermissionsEnum.ExcavatorContact_Create;//Needs to be overriden and set to the proper permission
    DeletePermission: PermissionsEnum = PermissionsEnum.ExcavatorContact_Delete;//Needs to be overriden and set to the proper permission
    //CopyPermission: PermissionsEnum = PermissionsEnum.ExcavatorContact_Copy;//Needs to be overriden and set to the proper permission

    constructor(protected services: CRUDServices) {
        super(services);
    }

    public CanPerformAction(action: 'View' | 'Create' | 'Edit' | 'Delete', itemID: string = null): Observable<boolean> {
        //Just let the server do the checks. If we want to do it here we can for the Create, Edit, and Delete by getting the whole item and checking
        //  the ExcavatorCompanyID.  But these should only be used for checking to make the call to the server, so it doens't hurt to let it call and
        //  figure it out there for this object
        switch (action) {
            case 'View':
                //Always need to look for it anywhere.  This is because we need to check permissions after it's fetched for
                //  the case like a MemberAdmin. We don't know if they can view this until it's been fetched and we know the service area's Member.
                //Also, for searching the server needs to figure out permissions
                return this.services.permissionService.CurrentUserHasPermission(this.ViewPermission, null, true);
            case 'Create':
                return this.services.permissionService.CurrentUserHasPermission(this.CreatePermission, null, true);
            case 'Edit':
                return this.services.permissionService.CurrentUserHasPermission(this.EditPermission, null, true);
            case 'Delete':
                return this.services.permissionService.CurrentUserHasPermission(this.DeletePermission, null, true);
        }
    }

    public RemovePerson(contactID: string) {
        this.EntityID = contactID;
        super.SaveProperty("PersonID", null, null);
    }

    public AddPerson(insertValue: ExcavatorContactToPerson): Observable<ExcavatorContact> {
        return this.services.http.post<ExcavatorContact>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/AddPersonToContact", insertValue);
    }

    public TicketAutoComplete(value: string, column: string, allowHomeowners: boolean = null): Observable<ExcavatorAutocompleteResponse[]> {
        const searchRequest = new ExcavatorAutocompleteRequest(column, value);
        if (allowHomeowners !== null)
            searchRequest.AllowHomeowners = allowHomeowners;

        return this.services.http.post<ExcavatorAutocompleteResponse[]>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/TicketAutoComplete", searchRequest);
    }

    public FindForSelfRegistration(occCode: string, email: string): Observable<ExcavatorContact> {
        const request = new FindContactForSelfRegistrationRequest(occCode, email);
        return this.services.http.post<ExcavatorContact>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/FindForSelfRegistration", request);
    }

    public GetExcavatorInfoWithTicketOfficeCounts(excavatorContactIDs: string[]) {
        return this.services.http.post<ExcavatorContact[]>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/GetExcavatorInfoWithTicketOfficeCounts", excavatorContactIDs);
    }

    public MoveExcavatorOffices(request: ExcavatorMoveOfficeRequest) {
        return this.services.http.post<any>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/MoveExcavatorOffices", request);
    }

    public MergeExcavatorContacts(request: ExcavatorMergeRequest) {
        return this.services.http.post<number>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/MergeExcavatorContacts", request);
    }

    public MoveTicketsExcavatorContacts(request: ExcavatorMoveTicketsRequest) {
        return this.services.http.post<any>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/MoveTicketsExcavatorContacts", request);
    }

    public CanDelete(excavatorContactIDs: string[]) {
        return this.services.http.post(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/CanDelete", excavatorContactIDs);
    }

    public GetAvailableSearchColumnsAndFilters(): Observable<{ columns: SearchColumn[], filters: SearchColumn[] }> {

        let columns = [new SearchColumn("Name", "Name", "Name", "Name")];

        const excavatorID = new SearchColumn("ExcavatorID", "Excavator ID", "ExcavatorID", "ExcavatorID");
        excavatorID.width = "6rem";
        columns.push(excavatorID);

        const phone = new SearchColumn("PrimaryPhoneNumber", "Phone Number", "PrimaryPhoneNumber", "PrimaryPhoneNumber");
        phone.width = "8rem";
        phone.formatType = "phone";
        columns.push(phone);

        columns.push(new SearchColumn("EmailAddress", "Email Address", "EmailAddress", "EmailAddress"));

        const isActive = new SearchColumn("IsActive", "Active", "IsActive", "IsActive");
        isActive.filterOptions = of([new SelectOption(true, "Yes"), new SelectOption(false, "No")]);
        isActive.DynamicCssClass = SearchColumn.BoolToRedGreen;
        isActive.width = "3rem";
        columns.push(isActive);
        
        const lastVerifiedDate: SearchColumn = new SearchColumn("LastVerifiedDate", "Last Verified Date", "LastVerifiedDate", "LastVerifiedDate");
        lastVerifiedDate.useDateSearch = true;
        lastVerifiedDate.ShowFutureDateOptions = false;
        lastVerifiedDate.formatType = 'date';
        lastVerifiedDate.format = UIDateTimeFormat;
        columns.push(lastVerifiedDate);

        const lastTicketCreatedDateColumn: SearchColumn = new SearchColumn("LastTicketCreateDate", "Last Ticket Created", "LastTicketCreateDate", "LastTicketCreateDate");
        lastTicketCreatedDateColumn.useDateSearch = true;
        lastTicketCreatedDateColumn.ShowFutureDateOptions = false;
        lastTicketCreatedDateColumn.formatType = 'date';
        lastTicketCreatedDateColumn.format = UIDateTimeFormat;
        columns.push(lastTicketCreatedDateColumn);

        const modifyDate = new SearchColumn("LastModifyDateTime", "Last Modified", "LastModifyDateTime", "LastModifyDateTime");
        modifyDate.useDateSearch = true;
        modifyDate.ShowFutureDateOptions = false;
        modifyDate.formatType = 'date';
        //modifyDate.width = "15%";
        modifyDate.format = UIDateTimeFormat;
        columns.push(modifyDate);

        const hasLoginColumn: SearchColumn = new SearchColumn("HasLogin", "Has Login", "HasLogin", "HasLogin");
        hasLoginColumn.width = "3rem";
        hasLoginColumn.filterOptions = of([new SelectOption(true, "Yes"), new SelectOption(false, "No")])
        columns.push(hasLoginColumn);

        const username = new SearchColumn("Login", "Login", "Login", "Login");
        username.filterOperator = SearchFilterOperatorEnum.CustomOr;
        columns.push(username);

        const companyNamesCol = new SearchColumn("CompanyNames", "Company", "CompanyNames", "CompanyNames");
        companyNamesCol.filterOperator = SearchFilterOperatorEnum.CustomOr;
        columns.push(companyNamesCol);

        const companyID = new SearchColumn("CompanyIDs", "Company ID", "CompanyIDs", "CompanyIDs");
        companyID.filterOperator = SearchFilterOperatorEnum.CustomOr;
        companyID.width = "6rem";
        columns.push(companyID);

        const officeNamesCol = new SearchColumn("OfficeNames", "Office", "OfficeNames", "OfficeNames");
        officeNamesCol.filterOperator = SearchFilterOperatorEnum.CustomOr;
        columns.push(officeNamesCol);

        const officeID = new SearchColumn("OfficeIDs", "Office ID", "OfficeIDs", "OfficeIDs");
        officeID.filterOperator = SearchFilterOperatorEnum.CustomOr;
        officeID.width = "6rem";
        columns.push(officeID);

        columns = columns.sort((a, b) => a.name.localeCompare(b.name));
        return of({ columns: columns, filters: columns });
    }
}
