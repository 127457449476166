import { Address } from "Models/Common/Address.model";
import { IDisplayEntity } from "Models/Interfaces/IDisplayEntity.interface";
import { ExcavatorCompany } from "Models/Excavators/ExcavatorCompany.model";
import { IActive } from "Models/Interfaces/IActive.interface";
import { Email } from "Models/Common/Email.model";
import { Phone } from "Models/Common/Phone.model";
import { EntityBase } from "Models/Base/EntityBase.model";
import { ExcavatorContactToExcavatorOffice } from "Models/Excavators/ExcavatorContactToExcavatorOffice.model";

export class ExcavatorOffice extends EntityBase implements IDisplayEntity, IActive {

    public OfficeID: number;

    public IsActive: boolean;

    public ExcavatorCompanyID: string;
    public ExcavatorCompany: ExcavatorCompany;

    //  An excavator office can have 1 and only 1 Address
    public AddressID: string;
    public Address: Address;

    public ExcavatorContacts: ExcavatorContactToExcavatorOffice[];

    public Emails: Email[];
    public Phones: Phone[];

    public TicketCount: number;

    //  Just used internally by ticket entry on ExcavatorSectionBase
    public SelectedPhoneNumber?: string;

    constructor(public ID: string = null, public Name: string = null) {
        super(ID);
        this.Address = new Address();
        this.IsActive = true;
    }
}
