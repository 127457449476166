<div style="display: flex; align-items: center" *ngIf="!CanMultiSelect">
    <mat-icon class="iq-display-2" style="flex-grow: 0; width: 20px; height: 20px;">search</mat-icon>
    <mat-form-field style="flex: 1 1 auto;">
        <mat-label>Filter by Ticket Number</mat-label>
        <input matInput autocomplete="off" type="search" [formControl]="ticketNumberControl" />
        <mat-hint *ngIf="minCharsDefaultSearch && minCharsDefaultSearch > 0">Min {{minCharsDefaultSearch}} characters required</mat-hint>
    </mat-form-field>

    <div style="min-width: 10px; display: flex; flex-direction: column; align-items:center; padding: 0 12px;">
        <label class="iq-caption gray-primary-text">Results</label>
        <label>{{totalResults}}</label>
    </div>
</div>

<div *ngIf="CanMultiSelect && AllowMultipleActionsOnTicketList" style="display: flex; margin: 10px;">
    <div style="display: flex; justify-content: flex-start; flex: 1 1 auto;">{{selectedItems.length}} Selected</div>
    <div style="display: flex; justify-content: flex-end; padding-left: 10px; padding-right: 10px;" (click)="ToggleMultiSelect(false)" class="link">Clear</div>
</div>

<iq-virtual-scroll [Items]="Items" [LoadingItems]="loading" (LoadMoreItems)="FetchNextPage()">
    <ng-template let-item let-last="last">
        <ticket-list-item [View]="Config.ListItemView"
                          [Item]="item"
                          [SearchColumns]="AvailableSearchColumns"
                          [MultiSelectMode]="CanMultiSelect"
                          (ToggleMultiSelectMode)="ToggleMultiSelect(true)"
                          (ToggleSelectedItem)="ToggleSelected(item)"></ticket-list-item>
    </ng-template>
</iq-virtual-scroll>
