import { VectorLayerBase } from "Shared/Components/Maps/Layers/VectorLayerBase";
import { MapConstants } from "Shared/Components/Maps/MapConstants";
import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { MapFeatureStyleEnum } from "Enums/MapFeatureStyle.enum";
import * as ol from 'ol';
import { Style, Stroke, Fill } from "ol/style";
import ol_style_FillPattern from "ol-ext/style/FillPattern";

export class NearStreetVectorLayer extends VectorLayerBase {

    constructor(mapToolService: MapToolService) {
        super(mapToolService, MapConstants.LAYERNAME_NEARSTREET, .2);
    }

    public BuildStyleForFeature(feature: ol.Feature<any>, isDrawing: boolean, featureStyle: MapFeatureStyleEnum, resolution: number): Style | Style[] {
        const fillPattern = new ol_style_FillPattern({
            color: "red",
            fill: new Fill({
                color: "rgba(0,0,0,0)",      //  background: transparent
            }),
            pattern: 'cross',
            size: 2,
            spacing: 5,
            angle: true
        });

        return new Style({
            fill: fillPattern as unknown as Fill,
            stroke: new Stroke({
                color: "red",
                width: 2
            })
        });
    }
}
