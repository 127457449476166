import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VirtualScrollComponent } from './VirtualScroll.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

@NgModule({
  declarations: [
    VirtualScrollComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    VirtualScrollerModule
  ],
  exports:[
    VirtualScrollComponent,
  ]
})
export class VirtualScrollModule { }
