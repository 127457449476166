import { OLGeometryTypeEnum } from 'Enums/GeometryType.enum';
import { MapToolsEnum } from 'Enums/MapTools.enum';
import * as ol from 'ol';
import ol_control_Bar from 'ol-ext/control/Bar';
import ol_control_Toggle from 'ol-ext/control/Toggle';
import { Control } from 'ol/control';
import { EventsKey } from 'ol/events';
import { Circle } from 'ol/geom';
import { unByKey } from 'ol/Observable';
import { Observable, of } from 'rxjs';
import { ConfirmationDialogComponent } from 'Shared/Components/Controls/Dialog/Confirmation/ConfirmationDialog.component';
import { DialogModel } from 'Shared/Components/Controls/Dialog/Models/Dialog.model';
import { DigSiteSizeControl } from './Controls/DigSiteSizeControl';
import { HidableButton } from "./Controls/HidableButton";
import { UndoEditButton } from "./Controls/UndoEditButton";
import { GeometryUtils } from "./GeometryUtils";
import { DigSiteEditorVectorLayer } from "./Layers/DigSiteEditorVectorLayer";
import { MapConstants } from "./MapConstants";
import { MapToolService } from "./MapToolService";
import { BufferFeatureTool } from "./Tools/BufferFeatureTool";
import { DrawCircleTool } from "./Tools/DrawCircleTool";
import { DrawLineTool } from "./Tools/DrawLineTool";
import { DrawPolygonTool } from "./Tools/DrawPolygonTool";
import { DrawRectangleTool } from "./Tools/DrawRectangleTool";
import { EditGeometryTool } from "./Tools/EditGeometryTool";

export class DigsiteEditor {

    private _EditorLayer: DigSiteEditorVectorLayer;

    private _EditGeometryTool: EditGeometryTool;
    private _DrawPolygonTool: DrawPolygonTool;
    private _DrawRectangleTool: DrawRectangleTool;
    private _DrawCircleTool: DrawCircleTool;
    private _DrawLineTool: DrawLineTool;
    private _BufferFeatureTool: BufferFeatureTool;

    private _ListenerEventKeys: EventsKey[];

    public OnSaveManualDigsiteJson: (geoJson: object, bufferFt: number, unbufferedGeoJson: object) => Observable<boolean>;

    constructor(private _Map: ol.Map, private _MapToolService: MapToolService) {
        this._EditorLayer = new DigSiteEditorVectorLayer(this._MapToolService);
        this._Map.addLayer(this._EditorLayer.Layer);
    }

    public OnDestroy(): any {
        if (this._EditGeometryTool)
            this._EditGeometryTool = this._EditGeometryTool.OnDestroy();
        if (this._DrawPolygonTool)
            this._DrawPolygonTool = this._DrawPolygonTool.OnDestroy();
        if (this._DrawRectangleTool)
            this._DrawRectangleTool = this._DrawRectangleTool.OnDestroy();
        if (this._DrawCircleTool)
            this._DrawCircleTool = this._DrawCircleTool.OnDestroy();
        if (this._DrawLineTool)
            this._DrawLineTool = this._DrawLineTool.OnDestroy();
        if (this._BufferFeatureTool)
            this._BufferFeatureTool = this._BufferFeatureTool.OnDestroy();

        if (this._EditorLayer)
            this._EditorLayer = this._EditorLayer.OnDestroy();

        if (this._ListenerEventKeys)
            this._ListenerEventKeys.forEach(eventKey => unByKey(eventKey));

        this._Map = null;

        return null;
    }

    //  type of the control bars = ol.control.Bar - but there are no typescript mappings currently available for this
    public AddControlBar(mainControlBar: any, allowedMapTools: MapToolsEnum[]): any {
        const editDigSiteBar = new ol_control_Bar({ toggleOne: true }); 
        mainControlBar.addControl(editDigSiteBar);           //  Sub-bars do not work right unless the Bar is added to the map BEFORE adding the rest of the controls in the bar!

        const undoButton = new UndoEditButton(this._MapToolService, this._EditorLayer);
        this._EditGeometryTool = new EditGeometryTool(this._Map, this._MapToolService, this._EditorLayer, MapConstants.LAYERNAME_DIGSITE, MapConstants.LAYERNAME_UNBUFFERED_DIGSITE, undoButton);

        //  Edit: Load current dig site (including system - unless it's place/county) for editing.  Allows dragging verticies, etc.
        editDigSiteBar.addControl(new ol_control_Toggle({
            html: '<i class="fas fa-edit"></i>',
            title: 'Edit Dig Site',
            interaction: this._EditGeometryTool.Interaction,
            active: false,      //  Must initialize to false or there is a bug inside the Toggle button that screws up the initial state (because it looks for a class that is only set when it changes!)
        }) as unknown as Control);

        if (allowedMapTools.indexOf(MapToolsEnum.Buffer) >= 0) {
            //  Select: Click on a feature (street, grid, map note/subdivision) to create buffered polygon.
            this._BufferFeatureTool = new BufferFeatureTool(this._Map, this._MapToolService, this._EditorLayer);
            editDigSiteBar.addControl(new ol_control_Toggle({
                html: '<i class="fas fa-code-branch"></i>',
                title: 'Buffer Street or Map Feature',
                interaction: this._BufferFeatureTool.Interaction,
                active: false,      //  Must initialize to false or there is a bug inside the Toggle button that screws up the initial state (because it looks for a class that is only set when it changes!)
            }) as unknown as Control);
        }

        if (allowedMapTools.indexOf(MapToolsEnum.DrawPolygons) >= 0) {
            //  Polygon: Draw polygon and begin editing it.  May need to apply buffer as you draw?  Needs to meet minimum size requirement.
            this._DrawPolygonTool = new DrawPolygonTool(this._Map, this._MapToolService, this._EditorLayer);
            editDigSiteBar.addControl(new ol_control_Toggle({
                html: '<i class="fas fa-draw-polygon"></i>',              //  Can reference Material icon like this: <i class="material-icons">timeline</i>
                title: 'Draw Polygon',
                interaction: this._DrawPolygonTool.Interaction,
                active: false,      //  Must initialize to false or there is a bug inside the Toggle button that screws up the initial state (because it looks for a class that is only set when it changes!)
            }) as unknown as Control);
        }

        if (allowedMapTools.indexOf(MapToolsEnum.DrawRectangles) >= 0) {
            //  Rectangle: Draw rectangle then begin editing it.  May need to apply buffer as you draw?  Needs to meet minimum size requirement.
            this._DrawRectangleTool = new DrawRectangleTool(this._Map, this._MapToolService, this._EditorLayer);
            editDigSiteBar.addControl(new ol_control_Toggle({
                html: '<i class="fas fa-vector-square"></i>',
                title: 'Draw Rectangle',
                interaction: this._DrawRectangleTool.Interaction,
                active: false,      //  Must initialize to false or there is a bug inside the Toggle button that screws up the initial state (because it looks for a class that is only set when it changes!)
            }) as unknown as Control);
        }

        if (allowedMapTools.indexOf(MapToolsEnum.DrawCircles) >= 0) {
            //  Circle: Draw circle then begin editing it.  Limits to configured buffer as drawn.
            this._DrawCircleTool = new DrawCircleTool(this._Map, this._MapToolService, this._EditorLayer);
            editDigSiteBar.addControl(new ol_control_Toggle({
                html: '<i class="far fa-circle"></i>',
                title: 'Draw Circle',
                interaction: this._DrawCircleTool.Interaction,
                active: false,      //  Must initialize to false or there is a bug inside the Toggle button that screws up the initial state (because it looks for a class that is only set when it changes!)
            }) as unknown as Control);
        }

        if (allowedMapTools.indexOf(MapToolsEnum.DrawLines) >= 0) {
            //  Line: Draw line, apply a buffer to it (as you draw?), then begin editing it
            this._DrawLineTool = new DrawLineTool(this._Map, this._MapToolService, this._EditorLayer);
            editDigSiteBar.addControl(new ol_control_Toggle({
                html: '<i class="material-icons">timeline</i>',
                title: 'Draw Line',
                interaction: this._DrawLineTool.Interaction,
                active: false,      //  Must initialize to false or there is a bug inside the Toggle button that screws up the initial state (because it looks for a class that is only set when it changes!)
            }) as unknown as Control);
        }

        //   Save: Should replace the current dig site with the polygon being edited.
        const saveButton = new HidableButton({
            html: '<i class="far fa-save"></i>',
            title: 'Save',
            handleClick: () => {
                this.Save().subscribe();
                this._MapToolService.CloseMapToolsExcept.next(null);
            }
        });
        editDigSiteBar.addControl(saveButton as unknown as Control);

        //  Cancel/Discard: End editing, discard current polygon, keeps existing dig site.
        const cancelButton = new HidableButton({
            html: '<i class="fas fa-times"></i>',
            title: 'Cancel/Discard',
            handleClick: () => this.ConfirmClear()
        });
        editDigSiteBar.addControl(cancelButton as unknown as Control);

        //  Undo: Rollback current manual dig site to previous dig site (which could be manual or system).  Do not allow rolling back if current is system generated.
        editDigSiteBar.addControl(undoButton as unknown as Control);

        this._ListenerEventKeys = this._EditorLayer.Layer.getSource().on(["addfeature", "removefeature", "clear"], () => {
            const haveFeatures = this._EditorLayer.Layer.getSource().getFeatures().length > 0;
            saveButton.SetHidden(!haveFeatures);
            cancelButton.SetHidden(!haveFeatures);
        });
    }

    public IsActive(): boolean {
        if (this._EditGeometryTool && this._EditGeometryTool.IsActive())
            return true;
        if (this._DrawPolygonTool && this._DrawPolygonTool.IsActive())
            return true;
        if (this._DrawRectangleTool && this._DrawRectangleTool.IsActive())
            return true;
        if (this._DrawCircleTool && this._DrawCircleTool.IsActive())
            return true;
        if (this._DrawLineTool && this._DrawLineTool.IsActive())
            return true;
        if (this._BufferFeatureTool && this._BufferFeatureTool.IsActive())
            return true;
        return false;
    }

    public HaveUnsavedFeaturesInEditor(): boolean {
        return this._EditorLayer.Layer.getSource().getFeatures().length > 0;
    }

    private ConfirmClear(): void {
        this._MapToolService.Dialog
            .open(ConfirmationDialogComponent, {
                data: new DialogModel("Cancel/Discard", "Are you sure you want to discard the dig site?"),
            }).afterClosed().subscribe((val) => {
                if (val)
                    this.Clear();
            });
    }

    public Clear(): void {
        this._EditorLayer.Layer.getSource().clear();
        this._MapToolService.CloseMapToolsExcept.next(null);

        const sizeControl = DigSiteSizeControl.FindSelfInMap(this._Map);
        if (sizeControl)
            sizeControl.ResetSizeOfFeatures();
    }

    public Save(): Observable<boolean> {
        const geoJson = this._EditorLayer.GetGeoJson(true);
        if (!geoJson)
            return of(true);     //  empty

        let bufferFt: number = null;
        let unbufferedGeoJson: object = null;

        //  Find unbuffered features (which can be lines or polygons).  Saving these allows us to edit
        //  them just as if we had just drawn them (by editing the unbuffered and seeing the buffers applied
        //  as we edit).
        const unbufferedLineFeaturesList: ol.Feature<any>[] = [];
        const unbufferedPolygonFeaturesList: ol.Feature<any>[] = [];
        this._EditorLayer.VectorSource.forEachFeature(f => {
            const geomType = GeometryUtils.GeometryTypeOfFeature(f);
            switch (geomType) {
                case OLGeometryTypeEnum.LineString:
                case OLGeometryTypeEnum.MultiLineString:
                    unbufferedLineFeaturesList.push(f);
                    break;
                case OLGeometryTypeEnum.Polygon:
                case OLGeometryTypeEnum.MultiPolygon:
                    unbufferedPolygonFeaturesList.push(f);
                    break;
                case OLGeometryTypeEnum.Circle: {
                    //  Store the point & buffer used to draw the point when we have a circle.
                    //  Note that this will union multiple geometries together in to a MultiPoint and will return the
                    //  largest buffer of all features.
                    //  And if for some reason there are also lines or polygons, these points will be replaced by those - see comments below about GeometryCollections.
                    const a = GeometryUtils.CircleToPointGeojsonAndRadiusFt(f.getGeometry() as Circle, unbufferedGeoJson, bufferFt);
                    unbufferedGeoJson = a.geoJson;
                    bufferFt = a.radiusFt;
                    break;
                }
            }
        });

        //  If we have a mix of geometry types, we can't store the unbuffered geometry at all.
        //  There was an issue in EF with geometry collections which has been fixed in EF 5: https://github.com/aspnet/EntityFrameworkCore/issues/18921
        //  But also much of our client & server code does not support geometry collections.  It probably could be fixed, but
        //  there could be lots of places it could break.
        //  And we cannot decide to store only 1 geometry type here (as we were previously doing prior to 2023/03/20) or that will cause
        //  issues with a Length dig site rule since it would not cover the entire area of the main/buffered geometry.
        if ((((unbufferedPolygonFeaturesList.length > 0) ? 1 : 0) + ((unbufferedLineFeaturesList.length > 0) ? 1 : 0) + (unbufferedGeoJson ? 1 : 0)) > 1) {
            unbufferedGeoJson = null;
            bufferFt = null;
        } else if (unbufferedPolygonFeaturesList.length > 0) {
            unbufferedGeoJson = this._EditorLayer.GetGeoJsonForFeatures(unbufferedPolygonFeaturesList, false);
            bufferFt = this._MapToolService.PolygonBufferFt;        //  May need different (or no) buffer for Parcels vs other polygons?
        } else if (unbufferedLineFeaturesList.length > 0) {
            unbufferedGeoJson = this._EditorLayer.GetGeoJsonForFeatures(unbufferedLineFeaturesList, false);
            bufferFt = this._MapToolService.LineBufferFt;
        }

        return new Observable<boolean>(observer => {
            this.OnSaveManualDigsiteJson(geoJson, bufferFt, unbufferedGeoJson)
                .subscribe(success => {
                    if (success)
                        this.Clear();
                    observer.next(success)
                }, () => observer.next(false), () => observer.complete());
        });
    }
}
