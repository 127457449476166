import { Directive, ElementRef, AfterViewInit } from '@angular/core';

//  If used on a button in a dialog, you can prevent the focus from flickering by setting this option
//  when opening the dialog: autoFocus: false

/**
 *  Use this directive to automatically focus the element when the page loads.
 */
@Directive({
    selector: '[iqAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {
    public constructor(private el: ElementRef) { }

    //  Use AfterViewInit (*NOT* OnInit) or get weird errors with the autocomplete column header filters on lists
    //  (like Message list -> Destination column header).
    public ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.el?.nativeElement?.focus)
                this.el.nativeElement.focus();
        });
        setTimeout(() => {
            if (this.el?.nativeElement?.focus)
                this.el.nativeElement.focus();
        }, 500);
    }
}
