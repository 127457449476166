import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from './SettingsService';
import { FileUpload } from 'Models/Misc/FileUpload.model';
import { AuthenticationService } from './AuthenticationService';

@Injectable({
    providedIn: 'root'
})
export class DownloadService {

    constructor(private _HttpClient: HttpClient, private _SettingsService: SettingsService,
        private _AuthenticationService: AuthenticationService) {
    }

    public OpenFileInNewWindow(fileUploadID: string, oneCallCenterCode: string = null): void {
        if (!oneCallCenterCode)
            oneCallCenterCode = this._AuthenticationService.CurrentUser.CurrentOneCallCenterCode;

        //  Anonymous method needed any time we are opening the attachment in a new tab/window.  Because we can't pass authentication
        //  when we do that.
        const url = this._SettingsService.ApiBaseUrl + "/Files/" + oneCallCenterCode + "/" + fileUploadID;

        //  More params to window.open to control size and location: https://www.lifewire.com/open-link-new-window-javascript-3468859
        const height = window.screen.availHeight - 150;
        window.open(url, "_blank", "toolbar=yes,top=50,left=100,width=1150,height=" + height);
    }

    public ViewLandlordNotification(ticketID: string, ticketNumber: string, version: number): void {
        const filename = "LandlordReport-" + ticketNumber + "-" + version + ".pdf";
        this.DownloadPdf("/Reports/Export/LandlordNotification/" + ticketID, filename);
    }

    /**
     * Downloads a Pdf and gives the user a "save as" dialog.
     * @param controllerUrl
     * @param files
     */
    public DownloadPdf(controllerUrl: string, filename: string): void {
        this.DownloadFile(controllerUrl, filename, "application/pdf");
    }

    public DownloadFileUpload(fileUpload: FileUpload): void {
        this.DownloadFileUploadID(fileUpload.ID, fileUpload.FileName, fileUpload.ContentType);
    }

    public DownloadFileUploadID(fileUploadID: string, filename: string, contentType: string): void {
        this.DownloadFile("/files/" + fileUploadID, filename, contentType);
    }

    //  Only use this if user is definitely anonymous...
    public DownloadFileUploadIDAnonymously(oneCallCenterCode: string, fileUploadID: string, filename: string, contentType: string): void {
        this.DownloadFile("/files/" + oneCallCenterCode + "/" + fileUploadID, filename, contentType);
    }

    private DownloadFile(controllerUrl: string, filename: string, contentType: string): void {
        //  https://stackoverflow.com/a/35206404

        //  canRedirect is necessary because if the Files api call returns a Redirect/302 to redirect
        //  the call to Google Storage, it will fail the CORS checks.  It happens because our initial call
        //  must pass credentials.  But if credentials are passed to Google, it fails the CORS check.
        //  There is no way we can intercept how the Redirect is handled - it's done by the browser.
        //  So when canRedirect=false, the api will return a 200 response with a json object that contains
        //  a RedirectLocation property.  We use that to manually do the redirect to the Google url.
        const url = this._SettingsService.ApiBaseUrl + controllerUrl + "?canRedirect=false";

        this.DownloadFromUrl(url, true, filename, contentType);
    }

    private DownloadFromUrl(url: string, lookForRedirect: boolean, filename: string, contentType: string): void {
        this._HttpClient.get(url, { responseType: "blob" })
            .subscribe(data => {
                if (!data)
                    return;

                //  Check for a json object that contains a "RedirectLocation" property to see if we need to fetch the
                //  content from another url (because a normal Redirect/302 response does not work - see above).
                if (lookForRedirect && (data.type === "application/json")) {
                    //  Need to use FileReader to read the json document from the blob.
                    const fr = new FileReader();
                    const me = this;
                    fr.onloadend = function () {
                        const doc = JSON.parse(this.result as string);
                        if (doc.RedirectLocation)
                            me.DownloadFromUrl(doc.RedirectLocation, false, filename, contentType);
                    };
                    fr.readAsText(data);
                    return;
                }

                this.DownloadData(data, filename);
            });
    }

    //  Reads the filename from the content-disposition header.
    public DownloadFileFromUrl(url: string): void {
        this._HttpClient.get(url, { observe: "response", responseType: "blob" })
            .subscribe(data => {
                const filenameHeader = data.headers.get("content-disposition")
                    .split(";")
                    .filter(p => p.trim().startsWith("filename="));
                if (filenameHeader && (filenameHeader.length > 0)) {
                    const filename = filenameHeader[0].replace("filename=", "").trim();
                    this.DownloadData(data.body, filename);
                }
            });
    }

    public DownloadCsv(csvContent: string, filename: string): void {
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        this.DownloadData(blob, filename);
    }

    private DownloadData(file: Blob, filename: string): void {
        //  This works to open up the file in another tab (if the browser can view it) but is blocked by ad blockers...
        //const file = new Blob([(data)], { type: 'application/pdf' });
        //const fileURL = URL.createObjectURL(file);
        //window.open(fileURL, "_blank");

        const a = document.createElement("a");
        document.body.appendChild(a);

        const fileURL = window.URL.createObjectURL(file);
        a.href = fileURL;
        a.download = filename;
        a.click();

        //  cleanup
        a.remove();
        URL.revokeObjectURL(fileURL);
    }
}
