<virtual-scroller #scroll class="scroll-list" [items]="Items" 
                    [bufferAmount]="bufferAmount" [checkResizeInterval]="checkResizeInterval"
                     [enableUnequalChildrenSizes]="enableUnequalChildrenSizes" 
                    [executeRefreshOutsideAngularZone]="executeRefreshOutsideAngularZone" [horizontal]="horizontal"
                    [modifyOverflowStyleOfParentScroll]="modifyOverflowStyleOfParentScroll" [parentScroll]="parentScroll"
                    
                    [resizeBypassRefreshThreshold]="resizeBypassRefreshThreshold"
                    [scrollAnimationTime]="scrollAnimationTime" [scrollDebounceTime]="scrollDebounceTime" 
                    [scrollThrottlingTime]="scrollThrottlingTime" [scrollbarHeight]="scrollbarHeight"
                    [scrollbarWidth]="scrollbarWidth" [ssrChildHeight]="ssrChildHeight"
                    [ssrChildWidth]="ssrChildWidth" [ssrViewportHeight]="ssrViewportHeight"
                    [ssrViewportWidth]="ssrViewportWidth" [stripedTable]="stripedTable"
                    [useMarginInsteadOfTranslate]="useMarginInsteadOfTranslate"
                    (vsEnd)="fetchNextPage($event)">
                    <!--
                        [RTL]="RTL" 
                        [compareItems]="compareItems"
                    -->
    <ng-template ngFor [ngForOf]="scroll.viewPortItems" [ngForTemplate]="itemTemplate" [ngForTrackBy]="TrackByFn">
    </ng-template>
    <div *ngIf="LoadingItems" style="display: flex; justify-content: center; align-items: center; background: rgba(0, 0, 0, 0.15);">
        <mat-spinner [diameter]="25"></mat-spinner>
    </div>
</virtual-scroller>