import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RoleTypeEnum } from 'Enums/RolesAndPermissions/RoleType.enum';
import { LatLonBounds } from 'Models/Maps/LatLonBounds.model';
import { EntityMapItemsRequest } from 'Models/Searching/EntityMapItemsRequest.model';
import { MapTileSearchRequest } from 'Models/Searching/MapTileSearchRequest.model';
import { TicketMapItem } from 'Models/Tickets/TicketMapItem.model';
import { Observable } from 'rxjs';
import { SettingsService } from 'Services/SettingsService';
import { IMapViewerService } from './IMapViewerService.interface';

/**
 *  Service used by a map that shows Tickets (for Excavators, Regulators, Local users)
 */
@Injectable({
    providedIn: 'root'
})
export class TicketMapViewerService implements IMapViewerService {
    public RoleType: RoleTypeEnum;

    constructor(private _HttpClient: HttpClient, private _SettingsService: SettingsService) {
    }

    public MapItems(ticketIDs: string[]): Observable<TicketMapItem[]> {
        const request = new EntityMapItemsRequest(ticketIDs, this.RoleType);
        return this._HttpClient.post<TicketMapItem[]>(this._SettingsService.ApiBaseUrl + "/Tickets/MapItems", request);
    }

    public Extents(request: MapTileSearchRequest): Observable<LatLonBounds> {
        return this._HttpClient.post<LatLonBounds>(this._SettingsService.ApiBaseUrl + "/Tickets/MapExtents", request);
    }

    public TileUrl(): string {
        return this._SettingsService.ApiBaseUrl + "/Tickets/MapTile/{z}/{x}/{y}";
    }
}
