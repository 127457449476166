import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DiscussionTypeEnum } from 'Enums/DiscussionType.enum';
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { Ticket } from 'Models/Tickets/Ticket.model';
import { TicketMinimal } from 'Models/Tickets/TicketMinimal.model';
import { TicketServiceArea } from 'Models/Tickets/TicketServiceArea.model';
import { TicketEntryOptionsService } from 'Pages/Tickets/Services/TicketEntryOptions.service';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { PrintingService } from 'Services/Printing.service';
import { AuthenticationService } from 'Services/AuthenticationService';
import { SettingsService } from 'Services/SettingsService';
import { TicketAttachmentTypeEnum } from 'Enums/TicketAttachmentType.enum';
import { TicketAttachment } from 'Models/Tickets/TicketAttachment.model';

/**
 *  This component can be used in a "print" route or as a view component to display the information
 *  (and also allow it to be printed).
 */
@Component({
    selector: 'ticket-details-printing-text-and-service-areas',
    templateUrl: './PrintTicketTextAndServiceAreas.component.html',
    styleUrls: ['./PrintTicketTextAndServiceAreas.component.scss'],
})
export class PrintTicketTextAndServiceAreasComponent implements OnInit {
    private _TicketID: string = null;

    //  Set by TicketSimpleView which is used for the anonymous ticket view page.
    @Input()
    public TicketMinimal: TicketMinimal;

    public TicketText: string;
    public ServiceAreas: TicketServiceArea[];
    public IsExcavatorOfTicket: boolean;
    public NumAttachments: number = 0;
    public ViewURL: string = null;
    public Disclaimer: string = null;

    public DisplayedColumns: SearchColumn[];

    public ShowExcavatorComments: boolean = false;

    public DiscussionTypeEnum = DiscussionTypeEnum;
    public Now: Date = new Date();

    public ShowServiceAreaCode: boolean = true;
    public UsesPositiveResponse: boolean;

    constructor(private _TicketService: TicketService, route: ActivatedRoute, private _PrintingService: PrintingService,
        public SettingsService: SettingsService, private _AuthService: AuthenticationService)
    {
        //  This can also be set when using the anonymous view ticket url (shown on the ticket text when there are attachments)!
        this._TicketID = route.snapshot.params["id"];

        this.UsesPositiveResponse = this.SettingsService.UsesPositiveResponse;
    }

    public ngOnInit() {
        //  There are 3 ways this component can get activated:
        //  1) Via a route with a TicketID.  We fetch the ticket (if we don't already have it) and start the print dialog.
        //  2) Embedded as a view in another component - i.e. the anonymous ticket search.  TicketMinimal is set in this case.
        //     We display the view which includes a print button (inside THIS view).  If clicked...
        //  3) Via an anonymous "printticket" route that contains the TicketMinimal data.  This happens during #2 when the print
        //     button is clicked.  The data is provided in _PrintingService.Data.  Initialize the form and start the print dialog.

        //  First check to see if we have TicketMinimal specified somewhere.  Because _TicketID comes from the route which
        //  could be the from the anonymous view ticket url that we put on the ticket text when there are attachments.
        //  In that case, the ticket is already fetched by the ViewTicketID component and we have been given the TicketMinimal object.
        let print = false;
        if (this._PrintingService.Data && !this.TicketMinimal) {
            this.TicketMinimal = this._PrintingService.Data["TicketMinimal"];
            print = true;
        }
        if (this.TicketMinimal) {
            const ticketEntryOptionsService = new TicketEntryOptionsService();
            ticketEntryOptionsService.TicketType = this.TicketMinimal.TicketTypeOptions;

            //  isExcavatorOfTicket is always false when anonymous
            //  ViewURL is not shown unless we're printing - because it's on the page above where we show the text...
            this.BindValues(this.TicketMinimal.TicketText, this.TicketMinimal.ServiceAreas, false, ticketEntryOptionsService,
                print ? this.TicketMinimal.ViewURL : null, this.TicketMinimal.Attachments, this.TicketMinimal.PrintTicketDisclaimer);

            if (print)
                this._PrintingService.OnDataReady();
        }
        else if (this._TicketID) {
            //  This is set when used in a "print" route.
            //  If matches the ticket we already have loaded, use it.
            if (this._TicketService.Ticket.value?.ID === this._TicketID)
                this.BindTicketAndPrint(this._TicketService.Ticket.value, this._TicketService.ViewURL, this._TicketService.TicketConfiguration.PrintTicketDisclaimer);
            else {
                this._TicketService.ViewTicket(this._TicketID).subscribe(ticketEntryResponse => {
                    //  TODO: Change this to call FindByTicketID?
                    this._TicketService.OnTicketEntryResponseReceived(ticketEntryResponse);
                    this.BindTicketAndPrint(ticketEntryResponse.Ticket, ticketEntryResponse.ViewURL, ticketEntryResponse.Configuration.PrintTicketDisclaimer);
                });
            }
        }
    }

    private BindTicketAndPrint(ticket: Ticket, viewURL: string, printTicketDisclaimer: string): void {
        this.BindValues(ticket.Ancillary.TicketText, ticket.ServiceAreas, this._TicketService.TicketConfiguration.IsExcavatorOfTicket,
            this._TicketService.TicketEntryOptionsService, viewURL, ticket.Attachments, printTicketDisclaimer);

        this._PrintingService.OnDataReady();
    }

    private BindValues(ticketText: string, serviceAreas: TicketServiceArea[], isExcavatorOfTicket: boolean,
        ticketEntryOptionsService: TicketEntryOptionsService, viewURL: string, attachments: TicketAttachment[],
        printTicketDisclaimer: string): void
    {
        this.TicketText = ticketText;
        this.ServiceAreas = serviceAreas;
        this.ViewURL = viewURL;
        this.Disclaimer = printTicketDisclaimer;

        if (viewURL && attachments)
            this.NumAttachments = attachments.filter(ta => ta.Public && ta.AttachmentType === TicketAttachmentTypeEnum.ExcavatorUpload).length;
        else
            this.NumAttachments = 0;

        this.BuildColumns(isExcavatorOfTicket, ticketEntryOptionsService);
    }

    private BuildColumns(isExcavatorOfTicket: boolean, ticketEntryOptionsService: TicketEntryOptionsService) {
        this.ShowServiceAreaCode = this.SettingsService.ShowServiceAreaCodeOnServiceAreaList(this._AuthService.CurrentUser);

        this.DisplayedColumns = [];

        //  Only show this column if a service area is suppressed or allows suppression to be toggled.
        //  Late tickets are suppressed and this varies by the ticket type & ticket function configuration.  So this check
        //  keeps the column visibility completely dynamic based on what we're looking at.
        const ShowSuppressionColumn = this.ServiceAreas && this.ServiceAreas.some(sa => sa.Suppressed || sa.ServiceAreaInfo?.CanToggleSuppression);
        if (ShowSuppressionColumn) {
            const suppressedCol = new SearchColumn("Suppressed", "Suppressed", null, null);
            suppressedCol.width = "50px";
            suppressedCol.class = "break-text";
            suppressedCol.canSearchAndFilter = false;
            suppressedCol.formatType = 'yesNo';
            this.DisplayedColumns.push(suppressedCol);
        }

        if (this.SettingsService.UsesExtraordinaryCircumstances) {
            const exCircumCol = new SearchColumn("ExtraordinaryCircumstances", "Ex. Circum", null, null);
            //exCircumCol.width = "80px";
            exCircumCol.canSearchAndFilter = false;
            exCircumCol.formatType = 'yesNo';
            this.DisplayedColumns.push(exCircumCol);
        }

        const saNameCol = new SearchColumn("ServiceArea", "Service Area", null, null);
        //saNameCol.width = '1 1 0';
        saNameCol.canSearchAndFilter = false;
        this.DisplayedColumns.push(saNameCol);

        const utilityTypeCol = new SearchColumn("UtilityTypes", "Utility Type(s)", null, null);
        //utilityTypeCol.width = '1 1 0';
        utilityTypeCol.canSearchAndFilter = false;
        this.DisplayedColumns.push(utilityTypeCol);

        if (this.SettingsService.UsesServiceAreaMarkingColors) {
            const markingColorCol = new SearchColumn("MarkingColor", "Marking Color(s)", null, null);
            //markingColorCol.width = '1 1 0';
            markingColorCol.canSearchAndFilter = false;
            this.DisplayedColumns.push(markingColorCol);
        }

        if (ticketEntryOptionsService.MainContactTypeName) {
            const contactCol = new SearchColumn("PrimaryContact", ticketEntryOptionsService.MainContactTypeName, null, null);
            //contactCol.width = '1 1 0';
            contactCol.canSearchAndFilter = false;
            this.DisplayedColumns.push(contactCol);
        }

        if (ticketEntryOptionsService.AltContactTypeName) {
            const altCol = new SearchColumn("AlternateContact", ticketEntryOptionsService.AltContactTypeName, null, null);
            //contactCol.width = '1 1 0';
            altCol.canSearchAndFilter = false;
            this.DisplayedColumns.push(altCol);
        }

        if (ticketEntryOptionsService.EmergContactTypeName) {
            const emergencyCol = new SearchColumn("EmergencyContact", ticketEntryOptionsService.EmergContactTypeName, null, null);
            //contactCol.width = '1 1 0';
            emergencyCol.canSearchAndFilter = false;
            this.DisplayedColumns.push(emergencyCol);
        }

        //  DigSafe does not use positive response!
        if (this.UsesPositiveResponse) {
            const posResponseCol = new SearchColumn("PositiveResponse", "Positive Response", null, null);
            //posResponseCol.width = '1 1 0';
            posResponseCol.canSearchAndFilter = false;
            this.DisplayedColumns.push(posResponseCol);

            this.ShowExcavatorComments = isExcavatorOfTicket
                && this.SettingsService.CenterUsesExcavatorComments && this.ServiceAreas.some(val => val.ServiceAreaInfo?.ExcavatorComments && val.ServiceAreaInfo.ExcavatorComments.length > 0);
            if (this.ShowExcavatorComments) {
                const excCommentsCol = new SearchColumn("ExcavatorComments", "Excavator Comments", null, null);
                //excCommentsCol.width = '1 1 0';
                excCommentsCol.canSearchAndFilter = false;
                this.DisplayedColumns.push(excCommentsCol);
            }

            if (this.ServiceAreas && this.ServiceAreas.some(val => val.ServiceAreaInfo?.Discussions && val.ServiceAreaInfo.Discussions.length > 0)) {
                const discussionCol = new SearchColumn("Discussions", "Discussions", null, null);
                discussionCol.canSearchAndFilter = false;
                //discussionCol.width = '1 1 0';
                this.DisplayedColumns.push(discussionCol);
            }
        }
    }

    //  Called when we are viewing the component - by the html template.  And this is only when the data was set via the TicketMinimal property.
    public OnPrint(): void {
        //  This is an anonymous route defined in app-routing.  Not ideal but don't have an "anonymous ticket" router at the moment...
        this._PrintingService.PrintDocument("Ticket", null, ["printticket", "text"], {
            TicketMinimal: this.TicketMinimal
        });
    }
}
